import React from 'react';
import styled from 'styled-components';

interface PopupProps {
  onClose: () => void;
  children: React.ReactNode;
}

const PopupContainer = styled.div<{left?: string; top?: string}>`
  position: absolute;
  top: 0;
  left: -130px;
  transform: translate(-50%, -50%);
  width: 200px;
  padding: 30px;
  border-radius: 15px;
  background-color: #191919;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
`;

export const Popup: React.FC<PopupProps> = ({ onClose, children }) => {
  return (
    <PopupContainer>
      <CloseButton onClick={onClose}>X</CloseButton>
      {children}
    </PopupContainer>
  );
};
