import styled from "styled-components";
import { color } from "styles/colors";

export interface StackContainerProps {
  sizeValue: number;
  sizeType: string;
  length: number;
  percentage?: number;
}

const setZindex = (length: number): string => {
  let result: string = "";
  for (let i: number = length; i > 0; i--) {
    result += `
			>div:nth-child(${i}){
				z-index: ${-i};
		  	};
		`;
  }
  return result;
};

export const StackContainer: any = styled.div`
  z-index: 1;
  width: ${({
    sizeValue,
    sizeType,
    length,
    percentage = 0,
  }: StackContainerProps): string =>
    `${sizeValue + (sizeValue * (length - 1) * percentage) / 100}${sizeType}`};
  display: grid;
  
  grid-template-columns:${({
    sizeValue,
    sizeType,
    length,
    percentage = 0,
  }: StackContainerProps): string =>
    `repeat(${length},${(sizeValue * percentage) / 100}${sizeType}`});
	${({ length }: StackContainerProps): string => setZindex(length)}
`;

export const BrandAvatar: any = styled.div`
  width: ${({ size }: any): any => size || "60px"};
  aspect-ratio: 1;
  border: ${({ img }: any): any => !img && "1px solid white"};
  background-color: ${({ img }: any): any =>
    !img && color.get("neutral-300")};
  background-image: ${({ img }: any): string => `url('${img}')`};
  background-size: cover;
  background-position: center;
  object-fit: cover;
  border-radius: 50%;
`;
