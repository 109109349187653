import styled, {StyledProps} from 'styled-components';

export interface MessageRenderProps {
	className?:string
	message:string
	color:string
}

const StyledSMessage:any= styled.p`
	color: ${({color}:StyledProps<any>):string=>color||'red'};
`;

export const Message=({message,color,...props}:MessageRenderProps):JSX.Element=>{
	return (
		<StyledSMessage color={color} {...props}>
			{message}
		</StyledSMessage>
	);
};
