import React, {useState} from 'react';
import CloseIcon from '../../assets/Icons/Interface/CloseIcon';
import {useMutation} from '@apollo/client';
import {tenantCreateOne} from '../../services/queries/mutations';
import {buttonStyle} from '../../styles/classes';

interface TenantFormI {
	setForm: (f:boolean) => void
	setLoading: (f:boolean) => void
}

interface TenantI {
	label?:string
	identifier?:string
}

const slugify = (str:string) => {
	str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
	str = str.toLowerCase(); // convert string to lowercase
	str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
		.replace(/\s+/g, '-') // replace spaces with hyphens
		.replace(/-+/g, '-'); // remove consecutive hyphens
	return str;
}

export default function TenantsForm({setForm, setLoading}:TenantFormI) {

	const [entry, setEntry] = useState<TenantI>();
	const [error, setError] = useState<string>("");

	const [createtenant, { data, loading, error:createError }] = useMutation(tenantCreateOne);

	const saveUser = () => {
		setError("");

		createtenant({
			variables : {
				record : {...entry}
			},
			onCompleted: (data:any):void => finishSave()
		});
	}

	const finishSave = () => {
		setLoading(true);
		setForm(false);
	}

	return (
		<div className="max-w-500px w-500px">
			<div className="flex align-center mb-2">
				<h4 className="f-urbanist grows">
					Add Tenant
				</h4>
				<a onClick={()=>{
					if (setForm) {
						setForm(false)
					}
				}}>
					<CloseIcon />
				</a>
			</div>

			<p className="mb">
				<label>Title</label>
				<input
					type="text"
					name="label"
					placeholder="Title"
					value={entry?.label || ""}
					onChange={(e)=>setEntry({
						label:e.target.value || "",
						identifier: slugify(e.target.value)
					})}
				/>
			</p>
			<p className="mb">
				<label>Slug</label>
				{entry?.identifier || <span>&nbsp;</span>}
			</p>

			<button className={buttonStyle} onClick={saveUser}>
				Save
			</button>

		</div>
	)
}