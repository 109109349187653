
import {Item} from '../interfaces';

export interface listHandlerProps {
	name:any
	value:string[]
	options:Item[]
	onChange:any
}

export interface listHandler {
	handleSelected:(item:Item)=>void
	handleUnselected:(item:Item)=>void
	getSelectedItems:()=>Item[]
}


const stringToItem=(value:string,items:Item[]):Item=>items.filter((i:Item):boolean=>i.id===value)[0];


export const useListHandler=({name,value=[],options=[],onChange}:listHandlerProps):listHandler=>{

	const handleSelected=(item:Item):void=>{
		if(!value.includes(item.id)){
			onChange({name, value:[...value, item.id]});
		}
	};
	const handleUnselected=(item:Item):void=>{
		let values:string[]=value.filter((v:string):boolean=>v!==item.id);
		onChange({name, value:values});
	};

	const getSelectedItems=():Item[]=> {
		if(options.length>1){
			return value.map((v: string): Item => stringToItem(v, options));
		}
		return [];
	};

	return {handleSelected,handleUnselected,getSelectedItems};
};

