import React, {useEffect, useState} from 'react';
import CloseIcon from '../../../assets/Icons/Interface/CloseIcon';
import {useParams} from 'react-router-dom';
import {TalentMediaI} from '../../../interfaces/general';
import {useLazyQuery, useMutation} from '@apollo/client';
import {query} from '../../../services/queries/queries';
import FileUpload from '../../../components/Form/FileUpload';
import {buttonStyle} from '../../../styles/classes';
import {
	talentMediaCreateOne,
	talentMediaUpdateOne
} from '../../../services/queries/mutations';

interface MediaFormI {
	tId?: string
	setTId?: (t:string) => void
	setForm?: (f:boolean) => void
	setLoading?: (f:boolean) => void
}

export default function MediaForm({tId, setForm, setTId, setLoading}:MediaFormI) {

	const {id} = useParams();
	const [entry, setEntry] = useState<TalentMediaI>();
	const [loadMedia, setLoadMedia] = useState<boolean>(true);

	const [getData, { data }] = useLazyQuery(query('talentMediaFindMany'));
	const [entryUpdate, { data:updateData, loading, error }] = useMutation(talentMediaUpdateOne);
	const [entryCreate, { data:createData, loading:createLoading, error:createError }] = useMutation(talentMediaCreateOne);

	const saveMedia = () => {
		const record = {
			title :entry?.title,
			link: entry?.link,
			user: id
		}
		if(tId){
			entryUpdate({
				variables : {
					record,
					id: tId
				},
				onCompleted: (data:any):void => finishSave()
			});
		}
		else {
			entryCreate({
				variables : {
					record
				},
				onCompleted: (data:any):void => finishSave()
			});
		}
	}

	const finishSave = () => {
		if(setForm){
		  setForm(false);
		}
		if(setTId){
			setTId("");
		}
		if(setLoading){
			setLoading(true);
		}
	}


	useEffect(():void=>{
		if(loadMedia && tId){
			getData({
				variables: {
					filter: {
						user: id,
						_id: tId
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					const m = data.talentMediaFindMany[0];
					setEntry({
						title : m?.title || "",
						link: m?.link || "",
						user: m?.user || "",
						files: m.files || []
					});
					setLoadMedia(false);
				}
			});
		}
	},[loadMedia]);



	return (
		<div className="max-w-500px w-500px">
			<div className="flex align-center mb-2">
				<h4 className="f-urbanist grows">
					{tId ? "Edit" : "Create"} Media
				</h4>
				<a onClick={()=>{
					if (setForm) {
						setForm(false)
					}
					if (setTId) {
						setTId('')
					}
				}}>
					<CloseIcon />
				</a>
			</div>

			<p className="mb">
				<label>Title</label>
				<input
					type="text"
					name="title"
					placeholder="Title"
					value={entry?.title || ""}
					onChange={(e)=>setEntry({...entry, title:e.target.value})}
				/>
			</p>

			<p className="mb">
				<label>Link</label>
				<input
					type="text"
					name="link"
					placeholder="Link"
					value={entry?.link || ""}
					onChange={(e)=>setEntry({...entry, link:e.target.value})}

				/>
			</p>

			{tId ?
				<div className="mb">
					<label>
						File
					</label>
					<FileUpload
						fileUrl={entry?.files && entry?.files.length > 0 && entry?.files[entry?.files?.length - 1 || 0]?.url || ""}
						endpoint={"common/upload-talentMedia-file"}
						id={tId}
						format="none"
						user={id}
					/>
				</div>
				:
				<p className="f-12px t-300">Please save this entry before uploading images </p>
			}

			<div className="pver">
				<button className={buttonStyle} onClick={saveMedia}>
					Save
				</button>
			</div>

		</div>
	)
}