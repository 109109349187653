import React from 'react';

interface FormSectionI {
	title?:string
	children?:React.ReactNode[]
}

export default function FormSection({title, children}:FormSectionI) {
	return (
		<section className="pver mt-2 b-top b-neutral-600 mb">
			<h3 className="f-18px f-urbanist mb">{title}</h3>
			{children}
		</section>
	)
}