import React from 'react';
import styled from 'styled-components';

interface DividerProps {
  color?: string;
  thickness?: string;
}

const DividerLine = styled.div<DividerProps>`
  width: 100%;
  border-bottom: ${(props) =>
    `${props.thickness || '1px'} solid ${props.color || '#fff'}`};
`;

const Divider: React.FC<DividerProps> = ({ color, thickness }) => {
  return <DividerLine color={color} thickness={thickness} />;
};

export default Divider;
