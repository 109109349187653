import React, {useEffect, useState} from 'react';
import {getEntry, saveEntry, updateEntry} from "../../../services/hotlists/hotlists.service";
import CloseIcon from '../../../assets/Icons/Interface/CloseIcon';
import {buttonStyle} from '../../../styles/classes';
import FileUpload from './fields/FileUpload';

interface TagFormI {
	setLoading?: (load:boolean)=>void
	setForm?: (f:boolean)=>void
	tagId? : number|string
	setTagId:(t:string | number)=>void
}

interface CardI {
	title?: string
	titleColor?: string
	cover?: string
}

interface TagObjectI {
	title?:string
	slug?:string
	titleColor?:string
	cover?:string
	coverId?:string|number
}

export default function TagForm({setLoading, setForm, tagId, setTagId}:TagFormI){

	const [tag, setTag] = useState<TagObjectI>();

	useEffect(()=>{
		console.log("TagID", tagId);
		if(tagId){
			loadTag();
		}
	},[tagId]);

	const loadTag = async () => {
		const t = await getEntry({
			endpoint: "tags",
			params: {
				"populate[0]" : "cover"
			},
			id: tagId
		});
		const d:TagObjectI = {
			title: t?.data?.attributes?.title || "",
			slug: t?.data?.attributes?.slug || "",
			titleColor: t?.data?.attributes?.titleColor,
			cover: t?.data?.attributes?.cover?.data?.attributes?.url,
			coverId: t?.data?.attributes?.cover?.data?.id
		}
		setTag(d);
		console.log("Tag data", t.data);
	}

	const onSubmit = (e:any) => {
		e.preventDefault();

		const d = {
			title: tag?.title,
			slug: slugify(tag?.title || ""),
			titleColor: tag?.titleColor,
			cover: tag?.coverId
			//description
		}
		console.log(d);
		save(d);
	}

	const slugify = (str:string) => {
		str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
		str = str.toLowerCase(); // convert string to lowercase
		str = str.replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
			.replace(/\s+/g, '-') // replace spaces with hyphens
			.replace(/-+/g, '-'); // remove consecutive hyphens
		return str;
	}

	const save = async (data:any) => {
		if(tagId){
			await updateEntry({
				endpoint : "tags",
				data,
				id: tagId
			});
		}
		else {
			await saveEntry({
				endpoint : "tags",
				data
			});
		}
		if(setLoading) {
			setLoading(true);
		}
		if(setForm){
			setForm(false);
		}
		if(setTagId){
			setTagId("");
		}
		//return setLoading ? setLoading(true) : null;
	}

	return (
		<div>
			<div className="flex align-start">
				<h4 className="mb-2 f-urbanist grows">
					{tagId ? "Edit" : "Create"} Tag {tagId}
				</h4>
				<a onClick={()=>setForm ? setForm(false) : null}>
					<CloseIcon />
				</a>
			</div>

			<form
				onSubmit={onSubmit}
				onKeyPress={(e) => {
					if (e.key === "Enter") {
						e.preventDefault();
					}
				}}
			>
				<p className="mb">
					<label>Title</label>
					<input
						type="text"
						name="title"
						placeholder="Title"
						value={tag?.title || ""}
						onChange={(e)=>setTag({...tag, title:e.target.value})}
					/>
				</p>
				{/*<p className="mb">*/}
				{/*	<label>Slug</label>*/}
				{/*	<input type="text" name="slug" placeholder="Slug" onChange={(e)=>setCard({...card, [e.target.name]: e.target.value})} />*/}
				{/*</p>*/}
				<p className="mb">
					<label>Title Color</label>
					<select
						name="titleColor"
						value={tag?.titleColor || ""}
						onChange={(e)=>setTag({...tag, titleColor:e.target.value})}
					>
						<option value="white">
							White
						</option>
						<option value="black">
							Black
						</option>
					</select>
				</p>
				<div className="mb-half">
					<label>Cover Picture</label>
					{/*<input type="file" name="cover" placeholder="Cover" onChange={fileChange} />*/}

					<FileUpload
						hideBox={true}
						fileUrl={tag?.cover || ""}
						setFile={(url:string, id:number|string)=>setTag({...tag, coverId:id, cover: url})}
					/>
				</div>

				{/*<div style={{color: "black"}}>*/}
				{/*	<Editor setDescription={setDescription} />*/}
				{/*</div>*/}

				<div className="mb">
					<label>Preview Tag Card</label>
					<div className={`relative f-700 f-urbanist w-300px aspect-16-9 ${tag?.titleColor === "black" ? "neutral-600" : "neutral-900"} p br flex align-end justify-start border-box overflow-hidden`}>
						{tag?.cover &&
							<img src={tag?.cover} className="edges absolute obj-cover w-100 aspect-16-9" />
						}
						<span className="relative" style={{color: tag?.titleColor}}>
							{tag?.title}
						</span>
					</div>
				</div>

				<p className="pb-2">
					<button className={buttonStyle}>
						Save
					</button>
				</p>


			</form>



		</div>
	)
}
