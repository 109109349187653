import { Suspense } from "react";
import { ApolloProvider } from "@apollo/client";
import {RecoilRoot} from 'recoil';

import AdminRoutes from './routes/AdminRoutes';

import { theme } from "config/OpusTheme";
import { client } from "config/apolloClient";
import { GlobalStyle } from "config/GlobalStyle";
import { ThemeProvider } from "styled-components";
import UserContextProvider from "context/userContext/userContext";
import { FilterProvider } from "context/useFilterContext/useFilterContext";
import ToastProvider from "context/toastContext/toastContext";
import "./styles/index.css"


const clientApollo = client();

function App() {
  return (
      <RecoilRoot>
        <ToastProvider>
          <UserContextProvider>
            <FilterProvider>
              <ApolloProvider client={clientApollo}>
                <ThemeProvider theme={theme}>
                  <GlobalStyle />
                  <Suspense fallback={<p>Loading...</p>}>
                    <AdminRoutes />
                  </Suspense>
                </ThemeProvider>
              </ApolloProvider>
            </FilterProvider>
          </UserContextProvider>
        </ToastProvider>
      </RecoilRoot>
  );
}

export default App;
