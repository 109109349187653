import React, {useEffect, useState} from 'react';
import AlertForm from '../forms/AlertForm';
import {Link, useParams} from 'react-router-dom';
import {AlertsI} from '../../../interfaces/general';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../../services/queries/queries';
import ArrowLeft from '../../../assets/Icons/Arrows/ArrowLeft';

export default function AlertsWork() {
	const {id} = useParams();

	const [loading, setLoading] = useState<boolean>(true);
	const [alert, setAlert] = useState<AlertsI>();

	const [queryAlerts, { data }] = useLazyQuery(query('culturalAlertFindOne'));

	useEffect(()=>{
		if(loading && id){
			queryAlerts({
				variables: {
					filter: {
						_id: id
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					setLoading(false);
					setAlert({...data?.culturalAlertFindOne});
				}
			});
		}
		if(!id){
			setLoading(false);
		}
	},[loading]);

	return (
		<div>
			<div className="grows">
				<Link to="/opportunities/alerts" className="t-white flex align-center mb-2">
					<span className="mr"><ArrowLeft /></span> Back to Opportunities
				</Link>
			</div>
			{!loading &&
				<AlertForm alert={alert} setAlert={setAlert} />
			}
		</div>
	)
}
