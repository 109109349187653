import { useForm } from "hooks/useForm/useForm";
import { Input } from "components/Atoms/Input/Input";
import { FormField, FormHook } from "hooks/useForm/interfaces";
import { Button } from "components/Atoms/Button/Button";
import { Message } from "components/Atoms/Message/Message";
import { Modal } from "components/Organisms/Modal/Modal";
import { FormStyle, Title, WrapperForm } from "../styles";

export const ModalFilterOptions = ({
  handleFilter,
  showModalFilterOptions,
  setShowModalFilterOptions,
}: any) => {
  const formFields: FormField[] = [
    { name: "name", required: false, default: "" },
    { name: "tenant", required: false, default: "" },
    { name: "instagramName", required: false, default: "" },
    { name: "industry", required: false, default: "" },
    { name: "segment", required: false, default: "" },
  ];

  const filterAction = async (): Promise<void> => {
    const newFilters = {
      name: data.name,
      tenant: data.tenant,
      instagramName: data.instagramName,
      industry: data.industry,
      segment: data.segment,
    };

    handleFilter(newFilters);
    setShowModalFilterOptions(false);
  };

  const { data, errors, loading, ...rest }: FormHook = useForm(
    formFields,
    filterAction
  );
  const { registerInput } = rest;
  return (
    <Modal
      isOpen={showModalFilterOptions}
      setShowModal={setShowModalFilterOptions}
    >
      <FormStyle onSubmit={rest.handleSubmitForm} autoComplete="off">
        <Title>Filter Options</Title>
        <WrapperForm>
          <Input
            label={"NAME"}
            placeholder={"Name"}
            {...registerInput("name")}
          />
          <Input
            label={"TENANT"}
            placeholder={"Tenant"}
            {...registerInput("tenant")}
          />
          <Input
            label={"INSTAGRAM"}
            placeholder={"Instagram"}
            {...registerInput("instagramName")}
          />
          <Input
            label={"INDUSTRY"}
            placeholder={"Industry"}
            {...registerInput("industry")}
          />
          <Input
            label={"INDUSTRY SEGMENT"}
            placeholder={"Industry Segment"}
            {...registerInput("segment")}
          />
        </WrapperForm>
        {!loading ? (
          <Button label="Search" />
        ) : (
          <div>
            <p>Loading</p>
          </div>
        )}
        {errors ? (
          <div className={"mt center"}>
            {errors.get("submitError") && (
              <Message
                message={`${errors.get("submitError")}`}
                color={"#fff"}
              />
            )}
          </div>
        ) : null}
      </FormStyle>
    </Modal>
  );
};
