import React, {useEffect} from 'react'
import {useQuery} from '@apollo/client';
import {query} from '../../../../services/queries/queries';
import CloseIcon from '../../../../assets/Icons/Interface/CloseIcon';
import {tagErrorStyle, tagSuccessStyle} from '../../../../styles/classes';

interface TalentPreviewI {
	id: string
}

export default function TalentPreview({id}:TalentPreviewI) {

	const {data, loading, error} = useQuery(
		query("talentFindOneAdmin"),
		{
			variables: {
				filter : {
					user: id
				}
			},
			fetchPolicy: 'cache-first'
		}
	);

	return (
		<div className="flex p neutral-900 b-neutral-600 br align-center cursor-pointer">
			<img src={data?.talentFindOneAdmin?.userObj?.profilePicture?.url} className="w-50px obj-cover aspect-1-1 br-50 mr-half" />
			<div>
				<p>{data?.talentFindOneAdmin?.userObj?.firstName} {data?.talentFindOneAdmin?.userObj?.lastName}</p>
				{data?.talentFindOneAdmin?.userObj?.sync ?
					<div className={`${tagSuccessStyle} f-12px mt-half`}>Succesful</div>
					:
					<div className={`${tagErrorStyle} f-12px mt-half`}>Failed</div>
				}
			</div>
			<span className="ml-2">
				<CloseIcon />
			</span>
		</div>
	)
}
