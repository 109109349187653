import { FC } from 'react'
import { TextTitle } from 'Pages/Tenant/styles'
import { Button } from 'components/Atoms/Button/Button'
import { Input } from 'components/Atoms/Input/Input'
import { Message } from 'components/Atoms/Message/Message'
import { Modal } from 'components/Organisms/Modal/Modal'
import { TableDinamic } from 'components/Organisms/TableDinamic/TableDinamic'
import { theme } from 'config/OpusTheme'

export const IndustriesSegment: FC<any> = ({
    data,
    columns,
    setShowModalCreate,
    showModalCreate,
    setEditingIndustrySegment,
    editingIndustrySegment,
    onSubmit,
    submitLoading,
    registerInput,
    errors,
    showConfirmModal,
    setShowConfirmModal,
    handleDelete,
    nameIndustry
}) => {
    return (
        <>
            <div style={{ width: "100%", maxWidth: "70%", margin: "0 auto" }}>
                <Button
                    label="Create Industry Segment"
                    width="180px"
                    height="40px"
                    onClick={() => setShowModalCreate(true)}
                />
                <TableDinamic data={data} columns={columns} />
            </div>

            {showModalCreate && (
                <Modal
                    isOpen={true}
                    setShowModal={() => {
                        setShowModalCreate(false);
                        setEditingIndustrySegment(null);
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "60px",
                            alignItems: "center",
                            height: "80%",
                            padding: "10px",
                        }}
                    >
                        <TextTitle>
                            {editingIndustrySegment ? "Edit Industry Segment" : "Create Industry Segment"}
                        </TextTitle>
                        <form
                            onSubmit={onSubmit}
                            autoComplete="off"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Input
                                label="LABEL"
                                placeholder="Label"
                                value={editingIndustrySegment ? editingIndustrySegment.label : ""}
                                {...registerInput("label")}
                            />
                            {!submitLoading ? (
                                <Button label={editingIndustrySegment ? "Edit" : "Create"} />
                            ) : (
                                <div>
                                    <p>Loading</p>
                                </div>
                            )}
                            {errors ? (
                                <div className={"mt center"}>
                                    {errors.get("submitError") && (
                                        <Message
                                            message={errors.get("submitError")}
                                            color={"#fff"}
                                        />
                                    )}
                                </div>
                            ) : null}
                        </form>
                    </div>
                </Modal>
            )}
            {
                showConfirmModal && (
                    <Modal
                        isOpen={true}
                        setShowModal={() => {
                            setShowConfirmModal(false);
                        }}
                    >
                        <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "60px",
                            alignItems: "center",
                            height: "80%",
                            padding: "10px",
                        }}
                    >
                            <TextTitle>
                                Are you sure to Delete {nameIndustry}?
                            </TextTitle>
                            <button
                                style={{
                                    backgroundColor: theme.red_500,
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "10px 10px",
                                    cursor: "pointer",
                                    marginRight: "10px",
                                    width: "50%"
                                }}
                                onClick={handleDelete}>Confirm</button>
                            <button
                                style={{
                                    backgroundColor: "#000",
                                    color: "#fff",
                                    borderRadius: "5px",
                                    padding: "10px 10px",
                                    cursor: "pointer",
                                    width: "50%",
                                    marginTop: "10px",
                                    border: "1px solid #fff"
                                }}
                                onClick={() => setShowConfirmModal(false)}>Cancel</button>
                        </div>
                    </Modal>
                )
            }
        </>
    )
}
