import React, {useState} from 'react';
import {uploadPhoto} from '../../../../services/hotlists/hotlists.service';
import {MediaI, TagI} from '../../../../interfaces/general';
import {circleIconStyle, circleIconStyleSmall, inputFileContainerStyle} from '../../../../styles/classes';
import CloseIcon from '../../../../assets/Icons/Interface/CloseIcon';

interface FUI {
	files?: MediaI[]
	setFiles?: (f:MediaI[]) => void
	removeFiles?: (f:MediaI[]) => void
}

export default function MediaUpload({files, setFiles, removeFiles}:FUI) {

	const [uploading, setUploading] = useState<boolean>(false);

	const fileChange = (e:any) => {
		const files = e.target.files;
		let formData = new FormData();

		for (let i = 0; i < files.length; i++) {
			formData.append("files", files[i]);
		}

		//formData.append('files', files);
		setUploading(true);
		fileUpload(formData);

	}

	const fileUpload = async (formData:any) => {

		try {
			const res = await uploadPhoto(formData);
			if(setFiles) {
				const media:MediaI[] = res?.data?.map(({id,url}:any)=>{return {id, url}}) || [];
				if (media.length > 0){
					setFiles(media);
				}
			}
			setUploading(false);
		}
		catch (e){
			setUploading(false);
			console.log(e);
		}
	}

	const remove = (id:number|string) => {
		console.log('remove');
		if(id && files && files.length > 0){
			const f:MediaI[] = [...files];
			const index:number = f.findIndex(x => x.id === id);
			if (index !== -1) {
				f.splice(index, 1);
				if(removeFiles){
					removeFiles(f);
				}
			}
		}
	}


	return (
		<div>
			<div className="grid3 col-gap">
				{files && files?.length > 0 && files.map(({id, url}:MediaI, index:number)=> {
					return (
						<div key={`${url}${id}`} className="br neutral-600 mb relative">
							{url &&
								<img src={url} alt="preview" className="w-100 aspect-16-9 br obj-cover" />
							}
							<div className={`${circleIconStyleSmall} absolute top-half right-half cursor-pointer`} onClick={()=>remove(id || "")}>
								<CloseIcon size={10} />
							</div>
						</div>
					)
				})}
			</div>
			<div className={inputFileContainerStyle}>
				Select Files
				<input type="file" multiple name="media" onChange={fileChange} />
			</div>

			<div className="f-small mt-half h-1rem">
				{uploading ? "Uploading Files..." : " "}
			</div>
		</div>

	)
}
