import React from "react";

function TenantIcon(props: any) {
  const {fill, width, height} = props;
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 20}
    height={height || 20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={fill || "#374957"}
        d="M9.167 10a.833.833 0 1 1 0 1.667h-2.5a.833.833 0 1 1 0-1.667h2.5Zm5-2.5a.833.833 0 0 0-.834-.833H6.667a.833.833 0 1 0 0 1.666h6.666a.833.833 0 0 0 .834-.833ZM6.667 5h6.666a.833.833 0 0 0 0-1.667H6.667a.833.833 0 1 0 0 1.667Zm10 11.203v3.195a.602.602 0 0 1-1.026.425l-.641-.64-.64.64a.601.601 0 0 1-1.027-.425v-3.195A3.322 3.322 0 0 1 15 10c.282.004.562.043.833.118V4.167a2.5 2.5 0 0 0-2.5-2.5H6.667a2.5 2.5 0 0 0-2.5 2.5v10a2.5 2.5 0 0 0 2.5 2.5h4.166a.834.834 0 0 1 0 1.666H6.667A4.172 4.172 0 0 1 2.5 14.167v-10A4.172 4.172 0 0 1 6.667 0h6.666A4.172 4.172 0 0 1 17.5 4.167v6.985a3.291 3.291 0 0 1-.833 5.051Zm0-2.87a1.667 1.667 0 1 0-3.334 0 1.667 1.667 0 0 0 3.334 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
  );
}

const MemoSvgTenantIcon = React.memo(TenantIcon);
  export default MemoSvgTenantIcon;
