import React, {useState} from 'react';
import FileUpload from '../../../components/Form/FileUpload';
import {buttonStyle} from '../../../styles/classes';

export default function CsvForm() {
	const [url, setUrl] = useState('');
	const [uploaded, setUploaded] = useState(false);

	return (
		<div>
			{uploaded ?
				<div>
					<p className="f-12px lh-130 f-bold">
						The CSV was successfully uploaded.
					</p>
					<p className="t-neutral-200 f-12px lh-130 mb">
						The user creation is now in progress, please refresh the talent list in a few minutes. <br />
					</p>
					<a className={buttonStyle} onClick={()=>setUploaded(false)}>New Import</a>
				</div>
				:
				<div>
					<p className="f-12px mb">
						Import a csv file with multiple users.
					</p>
					<FileUpload
						fileUrl={url}
						endpoint={'admin/csv-document'}
						hidePreview={true}
						onComplete={()=>setUploaded(true)}
					/>
				</div>
			}
		</div>
	)
}