import React, {useEffect, useState} from 'react';
import {useRecoilState} from 'recoil';
import {selectedTalentsAtom} from '../../../context/atoms';
import {buttonStyle} from '../../../styles/classes';
import PopMessage from '../../../components/Form/PopMessage';
import {useMutation} from '@apollo/client';
import {resyncTalent, tenantAddUsersOne, userUpdateOneAdmin} from '../../../services/queries/mutations';
import TenantSelector from '../../../components/Form/TenantSelector';

interface ATFI {
	setLoading : (l:boolean)=>void
}

export default function AddTenantForm({setLoading}:ATFI) {
	const [selectedTalents, setSelectedTalents] = useRecoilState(selectedTalentsAtom);
	const [tenant, setTenant] = useState<string>("");
	const [pop, setPop] = useState<boolean>(false);

	const [addTenant, { data, loading, error }] = useMutation(tenantAddUsersOne);

	const saveTenantUsers = () => {
		addTenant({
			variables : {
				users:selectedTalents,
				tenant
			},
			onCompleted: (data:any):void => {
				setPop(false);
				setLoading(true);
			}
		});
	};

	return (
		<div>
			{selectedTalents.length > 0 &&
				<div className="fixed bottom-0 right-0 left-250px neutral-800 p-2 m-2 br">
					<div className="flex align-center">
						<div className="grows">
							{selectedTalents.length} talent{selectedTalents.length > 1 && "s"} selected
						</div>
						<button className={`${buttonStyle}`} onClick={()=>setPop(true)}>
							Add to tenant +
						</button>
					</div>
				</div>
			}

			{pop &&
				<PopMessage
					title={`Add a tenant for ${selectedTalents.length} talent${selectedTalents.length > 1 && "s"} selected`}
					setPop={setPop}
				>
					<div>
						<p className="t-neutral-300 mt center lh-130 mb">
							Choose a tenant below
						</p>
						<TenantSelector placeholder="Select tenant" selected={[tenant]} setSelected={(t)=>setTenant(t[0])} single={true} />
						{loading ?
							<p className="center mt f-12px">
								Adding users to tenant...
							</p>
							:
							<div>
								<div className="center flex justify-center mver">
									<a className={buttonStyle} onClick={saveTenantUsers}>
										Save
									</a>
								</div>
								<div className="center f-14px">
									<a onClick={() => setPop(false)}>
										Cancel
									</a>
								</div>
								{error &&
									<p className="center t-orange mt f-12px">
										{error?.message}
									</p>
								}
							</div>
						}

					</div>
				</PopMessage>
			}
		</div>
	)
}