import React, {useEffect, useState} from 'react';
import ProfileRender from './Profile.render';
import {useParams} from 'react-router-dom';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../../services/queries/queries';
import {GenderI, IndustryI, UserEditI} from '../../../interfaces/general';

export default function ProfileLoad(){

	const {id} = useParams();
	const [loading, setLoading] = useState<boolean>(true);
	const [user, setUser] = useState<UserEditI>();
	const [genders, setGenders] = useState<GenderI[]>();
	const [industries, setIndustries] = useState<IndustryI[]>();
	const [segments, setSegments] = useState<IndustryI[]>();

	const [findOneTalent, { data, loading:loadTalent }] = useLazyQuery(query("talentFindOneAdmin"));
	const [findGenders, { data:dataGenders }] = useLazyQuery(query("gendersFindMany"));
	const [findIndustries, { data:dataIndustries }] = useLazyQuery(query("industryFindMany"));
	const [findSegments, { data:dataSegments }] = useLazyQuery(query("industrySegmentFindMany"));


	useEffect(()=>{
		if(loading){
			findOneTalent({
				variables: {
					filter:{
						user: id
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					setUser(data?.talentFindOneAdmin);
					setLoading(false);
				}
			});
			findGenders({
				variables: {
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					setGenders(data?.gendersFindMany);
				}
			});

			findIndustries({
				variables: {
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					setIndustries(data?.industryFindMany);
				}
			});

			findSegments({
				variables: {
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					setSegments(data?.industrySegmentFindMany);
				}
			});
		}
	},[loading]);


	return (
		<div>
			{user?._id && !loadTalent &&
				<ProfileRender
					user={user}
					genders={genders}
					industries={industries}
					segments={segments}
				/>
			}
		</div>

	)
}