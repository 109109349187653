/* eslint-disable react-hooks/exhaustive-deps */
import { redirect } from "react-router-dom";
import React, { createContext, useEffect, useState } from "react";

import { dataOld, login } from "services/auth/auth.service";

import {
  User,
  LoginData,
  userContextInterface,
} from "./types";
import { useToast } from "context/toastContext/toastContext";

export const UserContext: React.Context<userContextInterface> = createContext(
  {}
);
interface userProviderProps {
  children: JSX.Element;
}

const UserContextProvider = ({ children }: userProviderProps): JSX.Element => {
  const localUserData = localStorage.getItem('user');
  const [user, setUser] = useState<User | null>(JSON.parse(localUserData as any) || null);
  const [loading, setLoading]: any = useState<boolean>(true);
  const [error, setError]: any = useState<boolean>(false);

  useEffect((): void => {
    if (!user) {
      refresh();
    }
  }, [user]);
  const { error : err } =  useToast()

  const refresh = async (): Promise<any> => {
    const dataService = await dataOld()

    if(dataService.type.label !== 'Administrator') {
      logout()
      setError(true);
      redirect("/login");
      err("You don't have permission to access this page");
      return;
    }
    try {
      setUser(dataService);
      localStorage.setItem("user", JSON.stringify(dataService));
      setError(false);
    } catch (e) {
      setError(true);
      redirect("/login");
      localStorage.removeItem("token-opus");
      localStorage.removeItem("user");
    } finally {
      setLoading(false);
    }
  };

  const userLogin = async (data: LoginData): Promise<any> => {
    try {
      await login(data);
      await refresh();
      redirect("/hotlists");
    } catch (error) {
      console.log("err", error);
      err("Email incorrect");
    }
  };

  const logout = (): void => {
    localStorage.removeItem("token-opus");
    localStorage.removeItem("user");
    setUser(null);
  };
  const value: userContextInterface = {
    user,
    error,
    loading,
    logout,
    refresh,
    userLogin,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
export default UserContextProvider;
