import React from "react";

function SearchFilter(props: any) {
    const {stroke, width, height} = props;
    return (
      <svg
      width={50}
      height={49}
      viewBox="0 0 50 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 12.25V8.167m0 4.083a4.21 4.21 0 00-2.946 1.196 4.042 4.042 0 00-1.22 2.887c0 1.083.438 2.122 1.22 2.888A4.21 4.21 0 0025 20.417m0-8.167a4.21 4.21 0 012.946 1.196 4.042 4.042 0 011.22 2.887 4.042 4.042 0 01-1.22 2.888A4.21 4.21 0 0125 20.417m0 0v20.416M12.5 36.75a4.21 4.21 0 002.946-1.196 4.042 4.042 0 001.22-2.887 4.042 4.042 0 00-1.22-2.888 4.21 4.21 0 00-2.946-1.196m0 8.167a4.21 4.21 0 01-2.946-1.196 4.042 4.042 0 01-1.22-2.887c0-1.083.438-2.122 1.22-2.888a4.21 4.21 0 012.946-1.196m0 8.167v4.083m0-12.25V8.167m25 28.583a4.21 4.21 0 002.946-1.196 4.042 4.042 0 001.22-2.887 4.042 4.042 0 00-1.22-2.888 4.21 4.21 0 00-2.946-1.196m0 8.167a4.21 4.21 0 01-2.946-1.196 4.042 4.042 0 01-1.22-2.887c0-1.083.438-2.122 1.22-2.888a4.21 4.21 0 012.946-1.196m0 8.167v4.083m0-12.25V8.167"
        stroke="#B8B8B8"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    );
  }
  const MemoSvgSearchFilter = React.memo(SearchFilter);
  export default MemoSvgSearchFilter;
  