import React, {useEffect, useState} from 'react';
import InstagramRender from './Instagram.render';
import {useParams} from 'react-router-dom';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../../services/queries/queries';

export default function InstagramLoad(){

	const {id}:any = useParams();
	const [loading, setLoading] = useState<boolean>(true);

	const [getData, { data }] = useLazyQuery(query('postFindMany'));

	useEffect(():void=>{
		if(loading){
			getData({
				variables: {
					filter: {
						user: id
					},
					limit: 15
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => setLoading(false)
			});
		}
	},[loading]);

	return (
		<InstagramRender posts={data?.postFindMany} />
	)
}