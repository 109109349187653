import React,{ChangeEvent,useState} from 'react';
import {SelectProps} from 'interfaces';
import SelectRender from './Select.load';
import {SelectNetwork} from '../SelectNetworks/SelectNwtworks';

export const Select=({network,onChang, ...props}:SelectProps):JSX.Element=>{
	const [unselected,setUnselected]=useState(true);
	const onChangeSelect=(event:ChangeEvent<HTMLSelectElement>):void=>{
		const selectedValue = event.target.value;

		if (onChang) {
			onChang(selectedValue); // Pasamos el valor seleccionado, no el evento completo
		}
		setUnselected(false);
	};

	return network ? <SelectNetwork unselected={unselected} onChange={onChangeSelect} {...props} /> :<SelectRender unselected={unselected} onChange={onChangeSelect} {...props}/>;
};
