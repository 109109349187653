import {Spinner} from './styles';

export default function SpinnerRender():JSX.Element{

	return (
		<Spinner>
			<div/><div/><div/><div/>
			<div/><div/><div/><div/>
			<div/><div/><div/><div/>
		</Spinner>
	);
}
