import React from 'react';
import {useRecoilState} from 'recoil';
import {selectedTalentsAtom} from '../../../context/atoms';

interface TCI{
	id: string
}

export default function TalentCheckbox({id}:TCI) {
	const [selectedTalents, setSelectedTalents] = useRecoilState(selectedTalentsAtom);

	const checkTalent = () => {
		const s:string[] = [...selectedTalents];

		const f = s.find((e:string):boolean => e===id );
		if(!f){
			s.push(id);
			setSelectedTalents(s);
		}
		else{
			const index:number = s.findIndex(x => x === id);
			if (index !== -1) {
				s.splice(index, 1);
				setSelectedTalents(s);
			}
		}
	}

	return (
		<div
			className="b-neutral-600 b w-1rem h-1rem p-quarter flex mr-half br-quarter cursor-pointer"
			onClick={()=>checkTalent()}
		>
			<div className={`grows ${selectedTalents.includes(id) ? "blue" : ""} br-quarter`}>
			</div>
		</div>
	)
}