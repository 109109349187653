import {createBrowserRouter, redirect, RouterProvider} from "react-router-dom";

import { Layout } from "components/Templates/Layout/Layout";

import ArticlesLoad from '../Pages/Hotlists/Articles/Articles.load';
import Login from '../Pages/Login/Login';
import HotlistsIndex from '../Pages/Hotlists/Hotlists.index';
import TagsLoad from '../Pages/Hotlists/Tags/Tags.load';
import HotlistsHomeLoad from '../Pages/Hotlists/Home/Home.load';
import ArticlesWork from '../Pages/Hotlists/Articles/Articles.work';
import ArticlesEdit from '../Pages/Hotlists/Articles/Articles.edit';
import {getUser} from '../services/auth/auth.service';
import {TalentLoad} from '../Pages/Talent/Talent.load'
import TalentGraphLoad from '../Pages/TalentGraph';
import ProfileEditLoad from '../ProfileEdit';
import TenantLoad from '../Pages/Tenant';
import TenantDetailLoad from '../Pages/Tenant/Detail';
import IndustriesLoad from '../Pages/Industries';
import {IndustrySegmentsLoad} from '../Pages/IndustriesSegment/IndustriesSegment.load';
import OpportunitiesIndex from '../Pages/Opportunities/Opportunities.index';
import TacticsLoad from '../Pages/Opportunities/Tactics/Tactics.load';
import SubtacticsLoad from '../Pages/Opportunities/Subtactics/Subtactics.load';
import AlertsLoad from '../Pages/Opportunities/Alerts/Alerts.load';
import AlertsWork from '../Pages/Opportunities/Alerts/Alerts.work';
import InterestLoad from '../Pages/Interest/Interest.load';
import TalentsLoad from '../Pages/Talents/Talents.load'
import TalentEditIndex from '../Pages/TalentEdit/TalentEdit.index';
import AccountLoad from '../Pages/TalentEdit/Account/Account.load';
import ProfileLoad from '../Pages/TalentEdit/Profile/Profile.load';
import SocialLoad from '../Pages/TalentEdit/Social/Social.load';
import MediaLoad from '../Pages/TalentEdit/Media/Media.load';
import InstagramLoad from '../Pages/TalentEdit/Instagram/Instagram.load';
import TalentGraphLoadNodes from '../Pages/TalentGraph/TalentGraph.load.nodes';
import TenantsLoad from '../Pages/Tenants/Tenants.load';
import AccountsLoad from '../Pages/Accounts/Accounts.load';


const adminRoutes = createBrowserRouter([
	{
		path: "/login",
		element: <Login />,
		loader: async () => {
			const t = localStorage.getItem("token");
			// if(t){
			//   return redirect("/hotlists/articles")
			// }
			return null
		}
	},
	{
		path: "/",
		element: <Layout />,
		loader: async () => {
			const t = localStorage.getItem("token-opus");
			if(!t){
				return redirect("/login");
			}
			else{
				try {
					const user = await getUser();
					console.log("Good session");
					return null
				}
				catch (e){
					console.log("ERRR", e);
					localStorage.removeItem("token");
					// return redirect("/login");
					return null
				}
			}

		},
		children: [
			{
				path: "/opportunities",
				element: <OpportunitiesIndex />,
				children: [
					{
						path: "/opportunities/tactics",
						element: <TacticsLoad />
					},
					{
						path: "/opportunities/subtactics",
						element: <SubtacticsLoad />
					},
					{
						path: "/opportunities/alerts",
						element: <AlertsLoad />
					},
					{
						path: "/opportunities/form",
						element: <AlertsWork />
					},
					{
						path: "/opportunities/form/:id",
						element: <AlertsWork />
					}
				]
			},
			{
				path: "/hotlists",
				element: <HotlistsIndex />,
				children: [
					{
						path: "/hotlists/articles",
						element: <ArticlesLoad />
					},
					{
						path: "/hotlists/tags",
						element: <TagsLoad />
					},
					{
						path: "/hotlists/home",
						element: <HotlistsHomeLoad />
					}
				]
			},
			{
				path: "hotlists/form",
				element: <ArticlesWork />
			},
			{
				path: "hotlists/form/:id",
				element: <ArticlesEdit />
			},
			{
				path: "accounts/",
				element: <AccountsLoad />
			},
			{
				path: "accounts/:page",
				element: <AccountsLoad />
			},
			{
				path: "/accounts/:id",
				element: <TalentEditIndex />,
				children: [
					{
						path: "/accounts/:id/account",
						element: <AccountLoad />
					},
					{
						path: "/accounts/:id/profile",
						element: <ProfileLoad />
					},
					{
						path: "/accounts/:id/social",
						element: <SocialLoad />
					},
					{
						path: "/accounts/:id/media",
						element: <MediaLoad />
					},
					{
						path: "/accounts/:id/instagram",
						element: <InstagramLoad />
					}
				]
			},
			{
				path: "talent/",
				element: <TalentsLoad />
			},
			{
				path: "talent/:page",
				element: <TalentsLoad />
			},
			{
				path: "/talent/:id",
				element: <TalentEditIndex />,
				children: [
					{
						path: "/talent/:id/account",
						element: <AccountLoad />
					},
					{
						path: "/talent/:id/profile",
						element: <ProfileLoad />
					},
					{
						path: "/talent/:id/social",
						element: <SocialLoad />
					},
					{
						path: "/talent/:id/media",
						element: <MediaLoad />
					},
					{
						path: "/talent/:id/instagram",
						element: <InstagramLoad />
					}
				]
			},
			{
				path: "talents/:page",
				element: <TalentLoad />
			},
			{
				path: "talent/graph/:id",
				element: <TalentGraphLoadNodes />
			},
			{
				path: "talent/graph/:id/table",
				element: <TalentGraphLoadNodes />
			},
			{
				path: "talent/edit/:id",
				element: <ProfileEditLoad />
			},
			{
				path: "tenant",
				element: <TenantLoad />
			},
			{
				path: "tenants",
				element: <TenantsLoad />
			},
			{
				path: "tenant/detail/:id",
				element: <TenantDetailLoad />
			},
			{
				path: "interests",
				element: <InterestLoad />
			},
			{
				path: "interests/:page",
				element: <InterestLoad />
			},
			{
				path: "industries",
				element: <IndustriesLoad />
			},
			{
				path: "industry-segment",
				element: <IndustrySegmentsLoad />
			},
		],
	},
]);

export default function AdminRoutes() {
	return (
		<RouterProvider router={adminRoutes} />
	);
};
