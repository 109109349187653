import React, {useEffect, useState} from 'react';
import AccountsRender from './Accounts.render';
import {QueryTuple, useLazyQuery} from '@apollo/client';
import {query} from '../../services/queries/queries';
import {TalentI} from '../../interfaces/general';
import {buttonStyle} from '../../styles/classes';
import SearchIcon from '../../assets/Icons/Interface/SearchIcon';
import AccountsForm from './Accounts.form';
import {useRecoilState} from 'recoil';
import {tenantAtom} from '../../context/atoms';
import TenantSelector from '../../components/Form/TenantSelector';
import AddTenantForm from './components/AddTenantForm';

export default function AccountsLoad() {

	const [form, setForm] = useState(false);
	const [currentTenant, setCurrentTenant] = useRecoilState(tenantAtom);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [queryTalents, {data,loading:l,error:e}]:QueryTuple<any, any> = useLazyQuery(query('usersFindManyAdmin'));

	const [loading, setLoading] = useState<boolean>(true);
	const [q, setQ] = useState<string>("");
	const [talents, setTalents] = useState<TalentI[]>([]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			getTalents();
		}, 400)

		return () => clearTimeout(delayDebounceFn)
	}, [q]);

	useEffect(()=> {
		if(loading) {
			getTalents();
		}
	},[loading]);

	useEffect(()=> {
		console.log("Changing Current tenant", currentTenant);
		if(currentTenant){
			setLoading(true);
		}
	}, [currentTenant]);

	const getTalents = () => {
		let variables:any = {
			limit: 50,
			skip: 0,
			sort: 'CREATEDAT_DESC',
			filter: {
				type: "5b7bd05a-32d7-11ed-a261-0242ac120002"
			}
		};
		if(q){
			variables.search = { name: q }
		};
		if(!q && currentTenant && currentTenant.length > 0){
			variables.filter = {...variables.filter, tenants:currentTenant}
		}
		queryTalents({
			variables,
			onCompleted: (data) => setLoading(false),
			fetchPolicy:'network-only'
		});
	}

	useEffect(()=>{
		if(data){
			setTalents(data?.usersFindManyAdmin);
		}
	},[data]);

	return (
		<div>
			<div className="flex">
				<div className="flex align-start">
					<h5 className="f-urbanist f-700 mr">User Accounts</h5>
				</div>


				<div className="grows flex justify-end align-start">
					<div className="mr-2 max-w-300px w-300px">
						<TenantSelector placeholder={"Filter by tenants..."} selected={currentTenant} setSelected={(t) => setCurrentTenant(t)} />
					</div>
					<div className="relative">
						<input type="text" placeholder="Search talents by name" style={{paddingRight: '3rem'}} name="query" onChange={(e)=>setQ(e.target.value)} />
						<div className="absolute h-3rem flex align-center top-0 right-1 op-60">
							<SearchIcon />
						</div>
					</div>
				</div>

			</div>

			<AccountsRender talents={talents} loading={loading} setLoading={setLoading} />

			{form &&
				<div className="fixed right-0 top-2 neutral-800 b-neutral-700-t-l br-tl p-2 bottom-0 flex form-enter overflow-y-scroll">
					<div className="grows">
						<AccountsForm setForm={setForm} setLoading={setLoading} />
					</div>
				</div>
			}

			<AddTenantForm setLoading={setLoading} />

		</div>
	)
}