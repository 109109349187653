import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../services/queries/queries';
import {IndustryI, TacticI, TenantI} from '../../interfaces/general';
import {tagStyle} from '../../styles/classes';
import CloseIcon from '../../assets/Icons/Interface/CloseIcon';

interface SelectorI {
	selected: string[]
	setSelected : (s:string[]) => void
	placeholder?: string
	single?:boolean
}

export default function IndustriesSelector({selected, setSelected, placeholder="Select one...", single=false}:SelectorI) {

	const [loading, setLoading] = useState<boolean>(true);
	const [entries, setEntries] = useState<IndustryI[]>();

	const [findIndustries, { data }] = useLazyQuery(query("industryFindMany"));

	useEffect(()=>{
		if(loading){
			findIndustries({
				variables: {},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					setEntries(data?.industryFindMany);
					setLoading(false);
				}
			});
		}
	},[loading]);

	const addSelected = (id:string) => {
		if(single){
			setSelected([id]);
		}
		else {
			const s: string[] = [...selected];

			const f = s.find((e: string): boolean => e === id);
			if (!f) {
				s.push(id);
				setSelected(s);
			}
		}
	}

	const removeSelected = (id:string) => {
		const s:string[] = [...selected];
		const index:number = s.findIndex(x => x === id);
		if (index !== -1) {
			s.splice(index, 1);
			setSelected(s);
		}
	}

	const getLabel = (id:string) => {
		const t = entries?.find((e:any)=>e._id === id);
		return t ? t.label : id
	}

	return (
		<div>
			<select onChange={(e)=>addSelected(e.target.value)}>
				<option value={''}>{placeholder}</option>
				{entries && entries?.length > 0 && entries?.map(({_id, label}:IndustryI)=>{
					return (
						<option key={_id} value={_id}>
							{label}
						</option>
					)
				})}
			</select>

			{!loading && !single &&
				<div className="flex align-center mt">
					{selected && selected.length > 0 && selected.map((id:string, index)=>{
						return (
							<div className={`${tagStyle} flex align-center`} key={`${id}-${index}`} onClick={()=>removeSelected(id)}>
								{getLabel(id)}
								<span className="ml-half">
									<CloseIcon size={10} className="white" />
								</span>
							</div>
						)
					})}
				</div>
			}
		</div>
	)
}
