import React, { useState } from 'react'
import { Detail } from './Detail'
import { LinkContainer } from '../styles';
import { QueryResult, useQuery } from '@apollo/client';
import { query } from 'services/queries/queries';
import { useFilters } from "context/useFilterContext/useFilterContext";
import { useParams } from 'react-router-dom';

export const TenantDetailLoad = () => {
  const { id } = useParams<{ id: string }>();
  const [page, setPage] = useState(0);
  const { filters }: any = useFilters();
  const limit = 50;
 

  const {
    data: talentData,
    loading: talentTypeLoading,
    error: talentTypeError,
  }: QueryResult<
    any,
    { skip: number; limit: number; search?: any; filter: any }
  > = useQuery(query("usersFindManyAdmin"), {
    variables: {
      skip: page * 10,
      limit,
      ...(Object.keys(filters).length ? { search: filters } : {}),
      filter: {
        tenants: id,
        type: "90b4abc2-11c4-11ed-861d-0242ac120002",
      },
    },
  });

  const {
    data: accessData1,
    loading: accessTypeLoading,
    error: accessTypeError,
  }: QueryResult<
    any,
    { skip: number; limit: number; search?: any; filter: any }
  > = useQuery(query("usersFindManyAdmin"), {
    variables: {
      skip: page * 10,
      limit,
      ...(Object.keys(filters).length ? { search: filters } : {}),
      filter: {
        tenants: id,
        type: "5b7bd05a-32d7-11ed-a261-0242ac120002",
      },
    },
  });

  const {
    data: accessData2,
    loading: accessTypeLoading2,
    error: accessTypeError2,
  }: QueryResult<
    any,
    { skip: number; limit: number; search?: any; filter: any }
  > = useQuery(query("usersFindManyAdmin"), {
    variables: {
      skip: page * 10,
      limit,
      ...(Object.keys(filters).length ? { search: filters } : {}),
      filter: {
        tenants: id,
        type: "5b7bceb6-32d7-11ed-a261-0242ac120002",
      },
    },
  });

  
  if (talentTypeLoading || accessTypeLoading || accessTypeLoading2) {
    return <div>Loading...</div>;
  }
  
  if (talentTypeError || accessTypeError || accessTypeError2) {
    return <div>Error!</div>;
  }
  
  if (!talentData) {
    return <div>Loading...</div>;
  }
  
  const accessData = [...accessData1.usersFindManyAdmin, ...accessData2.usersFindManyAdmin];
  

  return (
    <Detail talentData={talentData} accessData={accessData} />
  )
}
