import React, {useState} from 'react';
import {useMutation} from '@apollo/client';
import {culturalAlertCreateOne} from '../../../services/queries/mutations';
import {slugify} from '../../../utils/misc';
import {useNavigate} from 'react-router-dom';
import {buttonStyle} from '../../../styles/classes';

export default function AlertCreate() {
	const navigate = useNavigate();
	const [title, setTitle] = useState<string>("");
	const [error, setError] = useState<string>("");
	const [alertCreate, { data, loading, error:err }] = useMutation(culturalAlertCreateOne);

	const onSubmit = (e:any) => {
		e.preventDefault();

		setError("");

		if(!title){
			setError("Please add a title to your alert")
			return
		}
		const record = {
			title: title,
			active: false,
			culturalType: slugify(title || "")
		};

		alertCreate({
			variables : {
				...record
			},
			onCompleted: (data:any):void => finishSave(data?.culturalAlertCreateOne?._id)
		});
	};

	const finishSave = (id:string) => {
		navigate(`/opportunities/form/${id}`);
	}

	return (
		<div className="mt">
			<form
				onSubmit={onSubmit}
				onKeyPress={(e) => {
					if (e.key === "Enter" && e.target.type === "text") {
						e.preventDefault();
					}
				}}
			>
			<div>
				<p className="mb">
					<label>Title</label>
					<input
						type="text"
						name="title"
						placeholder="Add a Title"
						value={title || ""}
						onChange={(e)=>setTitle(e.target.value)}
					/>
				</p>
			</div>
			<p className="pb-2 center">
				<button className={buttonStyle}>
					Continue
				</button>
				{error && <p className="mver f-12px t-neutral-200">{error}</p>}
			</p>
			</form>

		</div>
	)
}