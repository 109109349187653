import React, {useEffect, useState} from 'react';
import TalentGraphRender from './TalentGraph.render';
import TalentTableRender from './TalentTable.render';
import { CSVLink } from "react-csv";
import {useQuery} from '@apollo/client';
import {query} from '../../services/queries/queries';
import {useParams} from 'react-router-dom';
import {kgFormat} from './kgFormat';
import {Igraph} from './types';
import {Tab} from './styles'

interface TGLI{
	nodes:Igraph
	csv:any
	audience?:any
}

export const TalentGraphLoad = ({nodes, audience, csv}:TGLI) => {

	const [table, setTable] = useState(false);
	// const [csv, setCsv] = useState<any>([]);
	let {id} = useParams();

	const {data:d, loading:l, error:e} = useQuery(
		query("talentFindOneAdmin"),
		{
			variables: {
				filter: {
					user : id
				}
			},
			//fetchPolicy: 'cache-first'
		}
	);


	return (
		<div>
			<div>
				<div className="flex mb">
					<Tab
						selected={!table ? true : false}
						onClick={()=>setTable(false)}
					>
						Graph
					</Tab>
					<Tab
						selected={table ? true : false}
						onClick={()=>setTable(true)}
					>
						Table
					</Tab>
					<div style={{flexGrow:'1'}} />
					<CSVLink style={{textDecoration: "none"}} data={csv} filename={`${d?.talentFindOneAdmin?.userObj.firstName} ${d?.talentFindOneAdmin?.userObj.lastName} - Audience Graph.csv`}>
						<Tab selected={true}>Download CSV</Tab>
					</CSVLink>
				</div>
				{table ?
					<TalentTableRender
						user={d?.talentFindOneAdmin?.userObj}
						data={audience}
					/>
					:
					<TalentGraphRender
						nodes={nodes.nodes}
						relations={nodes.relations}
						user={d?.talentFindOneAdmin?.userObj}
					/>
				}
			</div>
		</div>

	)
}