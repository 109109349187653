import React, {useEffect, useState} from 'react';
import {TalentGraphLoad} from './TalentGraph.load';
import {useParams} from 'react-router-dom';
import {QueryTuple, useLazyQuery} from '@apollo/client';
import {query} from '../../services/queries/queries';
import {kgFormat} from './kgFormat';
import {Igraph} from './types';

export default function TalentGraphLoadNodes(){

	let {id} = useParams();
	const [loading, setLoading] = useState<boolean>(true);
	const [n, setN] = useState<Igraph>({nodes: [], relations: []});
	const [csv, setCsv] = useState<any>([]);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [queryAudience, {data,loading:l,error:e}]:QueryTuple<any, any> = useLazyQuery(query('instagramAudienceFindOneAdmin'));


	useEffect(()=>{
		if(loading){
			getAudience();
		}
	},[loading]);


	const getAudience = () => {
		queryAudience({
			variables: {
				filter: {
					user : id
				}
			},
			onCompleted: (data) => {
				buildNodes(data);
			},
			fetchPolicy:'network-only'
		});
	}

	const buildNodes = (d:any) => {
		if(d?.instagramAudienceFindOneAdmin) {
			setCsv([
				...d?.instagramAudienceFindOneAdmin?.audienceBrand,
				...d?.instagramAudienceFindOneAdmin?.audienceInterests,
				...d?.instagramAudienceFindOneAdmin?.audienceGenderAge,
				...d?.instagramAudienceFindOneAdmin?.audienceEthnicities,
				...d?.instagramAudienceFindOneAdmin?.audienceCountry,
				...d?.instagramAudienceFindOneAdmin?.audienceState,
				...d?.instagramAudienceFindOneAdmin?.audienceCity,
				...d?.instagramAudienceFindOneAdmin?.audienceLocale
			]);
			const graph = kgFormat(d?.instagramAudienceFindOneAdmin, {userObj: {firstName: "", lastName: ""}});
			setN(graph);
			setLoading(false);
		};
	};

	useEffect(()=>{
		console.log('NNN', n);
	},[n]);

	return (
		<div>
			{loading ?
				"Loading nodes" :
				<TalentGraphLoad nodes={n} audience={data.instagramAudienceFindOneAdmin} csv={csv} />
			}

		</div>
	)
}