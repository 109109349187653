import React, { useState } from 'react';

import { StyledSelect, SelectOption } from './styles';
import { InputWrapper, Label, RequiredText } from 'styles/ui';

interface Option {
  value: string | number;
  label: string;
}

interface SelectProps {
  options: Option[];
  label?: string;
  required?: boolean;
  placeholder?: string;
  defaultValue?: string | number;
  unselected?: boolean;
  onChange: (selectedValue: string | number) => void;
}

export const SelectMulti: React.FC<SelectProps> = ({
  options,
  label,
  required,
  placeholder = 'Select an option',
  unselected,
  onChange
}) => {

  const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);

  const handleSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    if (onChange) {
      onChange(selectedValue);
    }
    setIsPlaceholderVisible(false);
  };

  return (
    <InputWrapper>
      <div>
        <div>
          {label && <Label>{label}</Label>}
          {required && <RequiredText>Required</RequiredText>}
        </div>
        <StyledSelect
          defaultValue=""
          required={required}
          onChange={handleSelectionChange}
        >
          {isPlaceholderVisible && <SelectOption value='' disabled>{placeholder}</SelectOption>}
          {options.map((option) => (
            <SelectOption key={option.value} value={option.value}>
              {option.label}
            </SelectOption>
          ))}
        </StyledSelect>
      </div>
    </InputWrapper>
  );
};

