import React from 'react';

interface IconProps {
	color?: string
	width?: number
	height?: number

}

export default function ExpandIcon({color="white", width=29, height=29}:IconProps){
	return (
		<svg width={width} height={height} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M2.08301 12.2998C1.69238 12.2998 1.37012 12.168 1.11621 11.9043C0.862305 11.6406 0.735352 11.3184 0.735352 10.9375V2.80762C0.735352 2.06543 0.94043 1.48926 1.35059 1.0791C1.77051 0.65918 2.35156 0.449219 3.09375 0.449219H11.2236C11.624 0.449219 11.9512 0.576172 12.2051 0.830078C12.4688 1.08398 12.6006 1.40625 12.6006 1.79688C12.6006 2.1875 12.4688 2.50977 12.2051 2.76367C11.9414 3.00781 11.6143 3.12988 11.2236 3.12988H10.0078L4.88086 2.83691L8.93848 6.68945L13.4648 11.2012C13.5918 11.3281 13.6895 11.4795 13.7578 11.6553C13.8262 11.8311 13.8604 12.0166 13.8604 12.2119C13.8604 12.6221 13.7236 12.9541 13.4502 13.208C13.1768 13.4521 12.8301 13.5742 12.4102 13.5742C12.0391 13.5742 11.7314 13.4473 11.4873 13.1934L6.97559 8.65234L3.12305 4.59473L3.41602 9.70703V10.9375C3.41602 11.3281 3.28906 11.6553 3.03516 11.9189C2.79102 12.1729 2.47363 12.2998 2.08301 12.2998ZM18.1523 28.3984C17.7617 28.3984 17.4346 28.2715 17.1709 28.0176C16.9072 27.7637 16.7754 27.4414 16.7754 27.0508C16.7754 26.6602 16.9072 26.3379 17.1709 26.084C17.4346 25.8398 17.7617 25.7178 18.1523 25.7178H19.3828L24.5098 26.0107L20.4375 22.1582L15.9258 17.6465C15.7891 17.5195 15.6865 17.3682 15.6182 17.1924C15.5498 17.0166 15.5156 16.8311 15.5156 16.6357C15.5156 16.2256 15.6523 15.8984 15.9258 15.6543C16.209 15.4004 16.5557 15.2734 16.9658 15.2734C17.3467 15.2734 17.6592 15.4004 17.9033 15.6543L22.4004 20.1953L26.2676 24.2529L25.96 19.1406V17.9102C25.96 17.5195 26.0869 17.1973 26.3408 16.9434C26.5947 16.6797 26.9121 16.5479 27.293 16.5479C27.6836 16.5479 28.0059 16.6797 28.2598 16.9434C28.5234 17.207 28.6553 17.5293 28.6553 17.9102V26.04C28.6553 26.7822 28.4453 27.3584 28.0254 27.7686C27.6152 28.1885 27.0342 28.3984 26.2822 28.3984H18.1523Z" fill={color} />
		</svg>
	)
}