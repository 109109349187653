import { FC } from "react";

import { Input } from "components/Atoms/Input/Input";
import { Button } from "components/Atoms/Button/Button";
import { Modal } from "components/Organisms/Modal/Modal";
import { Message } from "components/Atoms/Message/Message";
import { TableDinamic } from "components/Organisms/TableDinamic/TableDinamic";

import { TextTitle } from "./styles";

export const Tenant: FC<any> = ({
  data,
  columns,
  errors,
  onSubmit,
  editingTenant,
  submitLoading,
  registerInput,
  showModalCreate,
  setEditingTenant,
  setShowModalCreate,
}) => {
  return (
    <>
      <div style={{ width: "100%", maxWidth: "70%", margin: "0 auto" }}>
        <Button
          label="Create Tenant"
          width="150px"
          height="40px"
          onClick={() => setShowModalCreate(true)}
        />
        <TableDinamic data={data} columns={columns} />
      </div>

      {showModalCreate && (
        <Modal
          isOpen={true}
          setShowModal={() => {
            setShowModalCreate(false);
            setEditingTenant(null);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "60px",
              alignItems: "center",
              height: "80%",
              padding: "10px",
            }}
          >
            <TextTitle>
              {editingTenant ? "Edit Tenant" : "Create Tenant"}
            </TextTitle>
            <form
              onSubmit={onSubmit}
              autoComplete="off"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Input
                label="LABEL"
                placeholder="Label"
                value={editingTenant ? editingTenant.label : ""}
                {...registerInput("label")}
              />
              <Input
                label="IDENTIFIER"
                placeholder="Identifier"
                value={editingTenant ? editingTenant.identifier : ""}
                {...registerInput("identifier")}
              />
              {!submitLoading ? (
                <Button label={editingTenant ? "Edit" : "Create"} />
              ) : (
                <div>
                  <p>Loading</p>
                </div>
              )}
              {errors ? (
                <div className={"mt center"}>
                  {errors.get("submitError") && (
                    <Message
                      message={errors.get("submitError")}
                      color={"#fff"}
                    />
                  )}
                </div>
              ) : null}
            </form>
          </div>
        </Modal>
      )}
    </>
  );
};
