import React, {useEffect, useState} from 'react';
import {NavLink, Outlet, useParams} from 'react-router-dom';
import {UserEditI} from '../../interfaces/general';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../services/queries/queries';

export default function TalentEditIndex() {
	const {id} = useParams();

	const [loading, setLoading] = useState<boolean>(true);
	const [user, setUser] = useState<UserEditI>();

	const [findOneTalent, { data }] = useLazyQuery(query("talentFindOneAdmin"));

	useEffect(()=>{
		if(loading){
			findOneTalent({
				variables: {
					filter:{
						user: id
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					setUser(data?.talentFindOneAdmin);
					setLoading(false);
				}
			});
		}
	},[loading]);

	useEffect(()=>{
		console.log("Account user", user);
	},[user]);

	return (
		<div>
			<div className="flex justify-center">
				{user?.userObj?.type !== "5b7bd05a-32d7-11ed-a261-0242ac120002" ?
					<div className="p-half br neutral-800 flex f-small f-lato">
						<NavLink to={`/talent/${id}/profile`}
								 className={({isActive}) => isActive ? "neutral-900 t-white pver-half phor br-half block" : "pver-half phor t-neutral-300 block"}>
							Profile
						</NavLink>
						<NavLink to={`/talent/${id}/account`}
								 className={({isActive}) => isActive ? "neutral-900 t-white pver-half phor br-half block" : "pver-half phor t-neutral-300 block"}>
							Account
						</NavLink>
						<NavLink to={`/talent/${id}/media`}
								 className={({isActive}) => isActive ? "neutral-900 t-white pver-half phor br-half block" : "pver-half phor t-neutral-300 block"}>
							Media
						</NavLink>
						<NavLink to={`/talent/${id}/instagram`}
								 className={({isActive}) => isActive ? "neutral-900 t-white pver-half phor br-half block" : "pver-half phor t-neutral-300 block"}>
							Instagram
						</NavLink>
						<NavLink to={`/talent/${id}/social`}
								 className={({isActive}) => isActive ? "neutral-900 t-white pver-half phor br-half block" : "pver-half phor t-neutral-300 block"}>
							Social
						</NavLink>
					</div>
					:
					<div>

					</div>
				}
			</div>

			<Outlet />
		</div>
	)
}