import React from 'react';
import { StyledTable, StyledHeadCell, StyledCell } from './styles';

type ColumnType = {
  header: string;
  accessor: string;
  Cell?: (row: Record<string, any>) => JSX.Element;
};

type DataType = Record<string, any>;

interface TableProps {
  columns: ColumnType[];
  data: DataType[];
}

export const TableDinamic: React.FC<TableProps> = ({ columns, data }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <StyledHeadCell key={index}>{column.header}</StyledHeadCell>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            {columns.map((column) => (
              <StyledCell key={`${index}-${column.accessor}`}>
                {column.Cell ? column.Cell(item) : item[column.accessor]}
              </StyledCell>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};
