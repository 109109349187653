import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL,
});

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem("token-opus");
	return {
	  headers: {
				...headers,
				"token": token ? token : "",
	  }
	}
  });

  export const client = (): ApolloClient<any> =>
	new ApolloClient({
	  link: ApolloLink.from([authLink, httpLink]),
	  cache: new InMemoryCache(),
	});

	export const clientApi = new ApolloClient({
		link: ApolloLink.from([authLink, httpLink]),
		cache: new InMemoryCache(),
	});

