import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle<any>`

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: ${({theme}) => theme.black};
    color: ${({theme}) => theme.white};
    overflow-x: hidden;
	font-family: "Helvetica", sans-serif;
  }
`;
