import React, {useState} from 'react';
import {IgPostI} from '../../../interfaces/general';
import IgCommentIcon from '../../../assets/Icons/Interface/IgCommentIcon';
import IgLikeIcon from '../../../assets/Icons/Interface/IgLikeIcon';
import ResyncIcon from '../../../assets/Icons/Interface/ResyncIcon';
import {buttonStyle} from '../../../styles/classes';
import {useMutation} from '@apollo/client';
import {refreshTensorSocial, userAddPost} from '../../../services/queries/mutations';
import {useParams} from 'react-router-dom';

interface TalentFeedI {
	posts?: IgPostI[]
}

export const abbreviateMount=(mount:number|string,fixedValue:number=1):string=>{
	if(!mount && mount !== 0)
		return '';
	let value:number=typeof mount === 'string'? parseFloat(mount): mount;
	let suffix:string='';
	if(value/1000>0.5 && value/1000<=950) {
		suffix = 'K';
		value/=1000;
		return `${value.toFixed(0)}${suffix}`;
	}

	if(value/1000000>0.95) {
		suffix = 'M';
		value/=1000000;
		return `${value.toFixed(fixedValue)}${suffix}`;
	}
	return value.toFixed(fixedValue);
};

export default function InstagramRender({posts}:TalentFeedI){
	const [refreshStatus, setRefreshStatus] = useState<boolean>(false);
	const {id} = useParams();

	const [scrapeInstagram, { data:reportResponse, loading:reportLoading, error:reportError }] = useMutation(userAddPost);

	const startReport = () => {
		scrapeInstagram({
			variables : {
				user: id
			},
			onCompleted: (data:any):void => {
				setRefreshStatus(true);
			}
		});
	};

	return (
		<div className="pt pb-2">
			<div className="pver-3 flex justify-center">
				<div>
					{!refreshStatus ?
						<button className={`${buttonStyle} flex align-center`} onClick={startReport}>
							<ResyncIcon/> <span className="ml-half">Refresh Instagram Feed</span>
						</button>
						:
						<p className="center max-w-300px lh-150 f-12px">
							The instagram feed for this talent is being refreshed now. This action might take several minutes. Please check back later
						</p>
					}

					{posts && posts.length > 0 &&
						<p className="f-12px t-neutral-300 mt center">
							Last post extracted on {posts[0].createdAt &&
							new Date(posts[0].createdAt).toLocaleString('en-US', {
								month: 'short',
								day: 'numeric',
								year: 'numeric'
							})
						}
						</p>
					}
				</div>
			</div>
			<div className="grid3 col-gap-2 row-gap-2">
				{posts && posts?.length > 0 && posts.map(({mediaUrl, commentsCount, likesCount, permalink, postId, file}:IgPostI):JSX.Element|undefined=>{
					if(file?.url){
						return (
							<a target="_blank" rel="noreferrer" className="relative flex align-end justify-center w-100 aspect-1-1" href={permalink || postId} key={postId}>
								<img src={file?.url || mediaUrl || ''} className="absolute edges w-100 aspect-1-1 obj-cover br w-100 obj-top" />
								<div className="neutral-800 br-half phor pver-half flex align-center t-white relative mb f-urbanist f-12px f-700">
									<div className="flex align-center mr-2">
								<span className="mr-half">
									<IgLikeIcon className="white op-60" />
								</span>
										{abbreviateMount(likesCount || 0, 0)}
									</div>
									<div className="flex align-center">
								<span className="mr-half">
									<IgCommentIcon className="white op-60" />
								</span>
										{abbreviateMount(commentsCount || 0, 0)}
									</div>
								</div>
							</a>
						);
					}

				})}
			</div>
		</div>
	)
}