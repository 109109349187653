import React, {useState, useEffect} from 'react'
import {getEntries} from '../../../../services/hotlists/hotlists.service';
import {tagStyle} from '../../../../styles/classes';
import {TagI} from '../../../../interfaces/general';
import CloseIcon from '../../../../assets/Icons/Interface/CloseIcon';

interface TagFieldI {
	tags:TagI[]
	setTags:(t:TagI[])=>void
	placeholder?:string
}

export default function TagField({tags, setTags, placeholder="Add Tags"}:TagFieldI) {

	const [items, setItems] = useState([]);
	const [query, setQuery] = useState("");
	const [list, setList] = useState(false);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			searchTags()
			// Send Axios request here
		}, 400)

		return () => clearTimeout(delayDebounceFn)
	}, [query])

	const searchTags = async () => {
		const e = await getEntries({
			endpoint: "tags",
			params: {
				"filters[title][$containsi]" : query,
				"pagination[page]": 1,
				"pagination[pageSize]": 4,
				"sort[0]:asc": "title"
			}
		});
		setItems(e.data);
	}

	const addTag = (id:number, title:string) => {
		const t:TagI[] = [...tags];

		const f = t.find((e:TagI):boolean => e.id===id );
		if(!f){
			t.push({id, title});
			setTags(t);
		}
	}

	const removeTag = (id:number|string, title:string) => {
		const t:TagI[] = [...tags];
		const index:number = t.findIndex(x => x.id === id);
		if (index !== -1) {
			t.splice(index, 1);
			setTags(t);
		}
	}

	return (
		<div>
			<input placeholder={placeholder} type="text" onFocus={()=>setList(true)} onChange={(e):any => setQuery(e.target.value)} />
			{list &&
				<div className="p neutral-900">
					{items && items.length > 0 && items.map(({attributes : { title }, id})=>{
						return (
							<div
								className="f-small pver-quarter cursor-pointer"
								key={`${title}-${id}`}
								onClick={()=>{
									addTag(id, title);
									setList(false);
								}}
							>
								{title}
							</div>
						)
					})}
				</div>
			}
			<div className="flex align-center mt">
				{tags && tags.length > 0 && tags.map(({title, id}:TagI, index)=>{
					return (
						<div className={`${tagStyle} flex align-center`} key={`-${title}-${id}-${index}`}  onClick={()=>removeTag(id, title)}>
							#{title}
							<span className="ml-half">
								<CloseIcon size={10} className="white" />
							</span>
						</div>
					)
				})}
			</div>
		</div>
	)
};
