import React, { FC } from 'react'
import { WrapperName } from '../styles';
import { Photo } from 'components/Atoms/TableRow/styles';
import { Avatar } from 'components/Atoms/Avatar/Avatar';
import { TableDinamic } from 'components/Organisms/TableDinamic/TableDinamic';

export const UsersTenantsAccess:FC<any> = ({data}) => {
  console.log(data)
    const columns = [
        { 
          header: "Name", 
          accessor: "name",
          Cell: (row: any) => (
            <WrapperName>
              {row.image ?
              <Photo src={row.image} alt="photo" />
              : <Avatar firstName={row.name} lastName={row.name} />
              }
              
              {row.name}
            </WrapperName>
          ),
        },
        { header: "Email", accessor: "email" },
        { header: "Instagram", accessor: "instagram" },
        { header: "Tenant", accessor: "tenant" },
      ];
    
      const dataRows = data.map((item: any) => ({
        name: item.firstName + " " + item.lastName,
        image: item?.profilePicture?.url,
        email: item.email,
        instagram: item?.socialNetwork?.url,
        tenant: item?.tenantsObj?.[0].label,
        id: item._id,
      }));
  return (
    <TableDinamic data={dataRows} columns={columns} />
  )
}
