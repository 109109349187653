import React from 'react';
import styled from 'styled-components';
import {Item} from '../../../interfaces';
import {Tag} from '../Tag';

export interface SuggestionsRenderProps{
	className?:string;
	tags:Array<Item>;
	selected?:boolean;

	onClickTag?:(tag:Item)=>void;
}

const StyledTags:any=styled.div`
	display: flex;
	flex-wrap: wrap;
`;


export default function TagsRender({tags,selected,onClickTag}:SuggestionsRenderProps):JSX.Element{
	return (
		<StyledTags>
			{tags.map((catgory:Item):JSX.Element=>
				<Tag
					category={catgory}
					selected={selected}
					key={catgory.id}
					className={'mhor green'}

					onClickTag={onClickTag}
				/>
			)}
		</StyledTags>
	);
}
