import { FC, useState } from "react";
import { FilterTag } from "../FilterTag/FilterTag";
import { FilterTagsWrapper } from "../FilterTag/styles";

import {
  SearchInput,
  ClearButton,
  SearchButton,
  SearchWrapper,
} from "./styles";

export const Search: FC<any> = ({
  filters,
  setFilters,
  handleSearch,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
    if (e.target.value.length === 0) {
      handleSearch(e.target.value);
    }
  };

  const handleClear = () => {
    setSearchTerm("");
    handleSearch("");
    setFilters({});
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSearch(searchTerm);
    }
  };

  //

  const handleRemoveFilter = (filterName: string) => {
    const newFilters = { ...filters };
    delete newFilters[filterName];
    const filterArray = Object.keys(newFilters).map((key) => ({
      name: key,
      value: newFilters[key],
    }));
  
    setFilters(newFilters);
  
    if (filterArray.some((filter) => filter.value.trim() !== "")) {
      handleSearch(newFilters);
    } else {
      handleClear();
    }
  };
  
  

  const filterArray = Object.keys(filters).map((key) => ({
    name: key,
    value: filters[key],
  }));

  const hasFilters = Object.keys(filters).length > 0;

  return (
    <SearchWrapper>
      <SearchButton onClick={handleSearch} />
      <SearchInput
        type="text"
        placeholder="Search Instagram Username..."
        value={searchTerm}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
      <ClearButton
        visibility={searchTerm || hasFilters ? "visible" : "hidden"}
        onClick={handleClear}
      />
      <FilterTagsWrapper>
        {filterArray.map((filter) => {
          if (typeof filter.value === "string" && filter.value.trim() !== "" && Object.keys(filters).length > 1) {
            return (
              <FilterTag
                key={filter.name}
                name={filter.value}
                onRemove={() => handleRemoveFilter(filter.name)}
              />
            );
          } else {
            return null;
          }
        })}
      </FilterTagsWrapper>
    </SearchWrapper>
  );
};
