import React, { FC } from "react";
import { TextAreaWrapper, TextBoxStyle } from "./styles";
import { Label } from "styles/ui";

export const TextBox: FC<any> = ({
  label,
  currentLength,
  required,
  className,
  maxLength,
  ...props
}) => {
  return (
    <TextAreaWrapper className={className}>
      <div>
        <Label>{label}</Label>
        <p>{`${currentLength}/${maxLength}`}</p>
      </div>
      <TextBoxStyle
        as={"textarea"}
        {...props}
        required={required}
        maxLength={maxLength}
      />
    </TextAreaWrapper>
  );
};
