import React from 'react';
import {FormProps, Item} from 'interfaces';
import { Select } from 'components/Atoms/Select/Select';
import { FormButton } from 'components/Atoms/FormButton';


export interface FamilyFormRenderProps extends FormProps {
	relationshipOptions:Item[]
}
export const FamilyForm = ({relationshipOptions,registerSelect,onSubmit}:any):JSX.Element=>{
	return(
		<div style={{ width: "100%", maxWidth: "300px" }}>
			<form onSubmit={onSubmit} >
				<Select
					label={'Relationship status'}
					options={relationshipOptions}
					{...registerSelect('relationshipStatus')}
				/>
				<div className="grid2 col-gap-2">
					<Select
						label={'Do you have kids'}
						placeholder={'Select a option'}
						options={[{label:'Yes',id:'yes'},{label:'No',id:'no'}]}
						{...registerSelect('kids')}
					/>
					<Select
						label={'Do you have Pets'}
						placeholder={'Select a option'}
						options={[{label:'Yes',id:'yes'},{label:'No',id:'no'}]}
						{...registerSelect('pets')}
					/>
				</div>

				<div className="center">
					<FormButton type="submit" label="Save" />
				</div>


			</form>
		</div>
	);
};