import {ErrorsHook, ErrorsMap, FieldErrors, FormField} from './interfaces';
import {useState} from 'react';

export type fieldErrors ={
	required?:boolean
	pattern?:boolean
	validate?:boolean
};


export const useErrors=():ErrorsHook=>{
	const errorsMap:ErrorsMap = new Map<string, fieldErrors|string>();
	const [errors,setErrors]= useState<ErrorsMap>(errorsMap);

	const fieldError = (field:FormField,data:any):FieldErrors|null=>{
		let fieldError:FieldErrors={};
		if(field.array && data[field.name].length<1){
			fieldError.required= true;
		}
		if(field.required && !data[field.name]){
			fieldError.required= true;
		}else if(field.validate && !data[field.name]){
			fieldError.validate= true;
		}
		if(field.pattern && !field.pattern.test(data[field.name])){
			fieldError.pattern=true;
		}
		if(!Object.entries(fieldError).length){
			return null;
		}
		return fieldError;
	};

	const watchFieldErrors=(field:FormField,data:any):void=>{
		setFieldError(field,data);
	};

	const setFieldError=(field:FormField,data:any):void=>{

		let CurrentFieldErrors:any = fieldError(field,data);
		if(CurrentFieldErrors){
			setErrors(():ErrorsMap=>new Map(errors.set(field.name, CurrentFieldErrors) ));
		}
		else{
			errors.delete(field.name);
			setErrors(():ErrorsMap=>new Map(errors));
		}
	};

	const setFormErrors=(fields:FormField[],data:any):void=>{
		fields.forEach((field:FormField):void=> {
			setFieldError(field,data);
		});
	};

	const setSubmitError=(error:Error):void=> {
		if(error){
			let messageError:string=error.message;
			if(messageError.includes('E11000')){
				messageError='The email already exists. Please use a different email.';
			}
			setErrors(():ErrorsMap=>new Map(errors.set('submitError',messageError)));
		}
		else{
			errors.delete('submitError');
			setErrors(():ErrorsMap=>new Map(errors));
		}
	};
	return {errors,watchFieldErrors,setFormErrors,setFieldError,setSubmitError};
};

