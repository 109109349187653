import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../services/queries/queries';
import {TenantI} from '../../interfaces/general';
import {tagStyle} from '../../styles/classes';
import CloseIcon from '../../assets/Icons/Interface/CloseIcon';

interface SelectorI {
	selected: string[]
	setSelected : (s:string[]) => void
	placeholder?: string
	single?:boolean
}

export default function TenantSelector({selected, setSelected, placeholder="Select tenants...", single=false}:SelectorI) {

	const [loading, setLoading] = useState<boolean>(true);
	const [queryEntries, { data }] = useLazyQuery(query("tenantFindMany"));

	useEffect(()=>{
		if(loading){
			queryEntries({
				variables: {
					filter: {
						active: true
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => setLoading(false)
			});
		}
	},[loading]);

	const addSelected = (id:string) => {
		if(single){
			setSelected([id]);
		}
		else{
			const s:string[] = [...selected];

			if(id){
				const f = s.find((e:string):boolean => e===id );
				if(!f){
					s.push(id);
					setSelected(s);
				}
			}
		}
	}

	const removeSelected = (id:string) => {
		const s:string[] = [...selected];
		const index:number = s.findIndex(x => x === id);
		if (index !== -1) {
			s.splice(index, 1);
			setSelected(s);
		}
	}

	const getLabel = (id:string) => {
		const ts = data?.tenantFindMany;
		const t = ts.find((e:any)=>e._id === id);
		return t ? t.label : id
	}

	return (
		<div>
			<select onChange={(e)=>addSelected(e.target.value)}>
				<option value={''}>{[placeholder]}</option>
				{data && data?.tenantFindMany?.length > 0 && data?.tenantFindMany?.map(({_id, label}:TenantI)=>{
					return (
						<option key={_id} value={_id}>
							{label}
						</option>
					)
				})}
			</select>

			{!loading && !single &&
				<div className="flex align-center mt wrap">
					{selected && selected.length > 0 && selected.map((id:string, index)=>{
						return (
							<div className={`${tagStyle} flex align-center mb-half`} key={`${id}-${index}`} onClick={()=>removeSelected(id)}>
								{getLabel(id)}
								<span className="ml-half shrinks-0">
									<CloseIcon size={10} className="white" />
								</span>
							</div>
						)
					})}
				</div>
			}

		</div>
	)
}
