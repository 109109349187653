import { FormField, FormHook } from "hooks/useForm/interfaces";
import { useForm } from "hooks/useForm/useForm";

import { Item, TalentProfile } from "interfaces";
import { Ethnicity } from "./Ethnicity";
import { FC } from "react";
import { query } from "services/queries/queries";
import { QueryResult, useQuery } from "@apollo/client";

const countries: Item[] = [
  { id: "USA", label: "United States" },
  { id: "co", label: "Colombia" },
  { id: "mx", label: "Mexico" },
  { id: "nz", label: "New Zealand" },
  { id: "uk", label: "United Kingdom" },
  { id: "jp", label: "Japan" },
];

interface EthnicityLoadProps {
  languages: string[];
  country: string;
  ethnicity: string[];
  handleUpdate: (data: any) => void;
}

export const EthnicityLoad: FC<EthnicityLoadProps> = ({
  languages,
  country,
  ethnicity,
  handleUpdate,
}) => {
  const {data:languageData}:QueryResult=useQuery(query('languageFindMany'));
  const {data:ethnicityData}:QueryResult=useQuery(query('ethnicityFindMany'));

  const formFields: FormField[] = [
    { name: "languages", required: false, default: languages || [] },
    { name: "ethnicity", required: false, default: ethnicity || [] },
    { name: "country", required: false, default: country || "" },
  ];

  const handleSubmit = async (formData: TalentProfile): Promise<void> => {
    handleUpdate(formData);
  };

  const { data, errors, loading, ...rest }: FormHook = useForm(
    formFields,
    handleSubmit
  );

  const languagess:Item[]=languageData?.languageFindMany
  ?.map((i:any):Item=>({label:i.label,id:i._id}))||[];

  const ethnicities:Item[]=ethnicityData?.ethnicityFindMany
  ?.map((i:any):Item=>({label:i.label,id:i._id}))||[];

  return (
    <Ethnicity
      languages={languagess}
      ethnicities={ethnicities}
      countries={countries}
      submitLoading={loading}
      errors={errors}
      registerInput={rest.registerInput}
      registerSelect={rest.registerSelect}
      registerList={rest.registerList}
      onSubmit={rest.handleSubmitForm}
    />
  );
};
