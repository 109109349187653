import React from "react";

function IndustrySegment(props: any) {
    const {fill, width, height} = props;
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 32}
    height={height || 24}
    fill="none"
    {...props}
  >
    <circle cx={17} cy={4} r={3.25} stroke="#fff" strokeWidth={1.5} />
    <circle cx={12.5} cy={19.5} r={3.75} stroke="#fff" strokeWidth={1.5} />
    <circle cx={28} cy={16} r={3.25} stroke="#fff" strokeWidth={1.5} />
    <circle cx={4} cy={10} r={3.25} stroke="#fff" strokeWidth={1.5} />
    <path
      stroke={fill || "#fff"}
      d="m16 8-2 8M25.06 16.764l-9.146 2.614M5.555 12.195l5.236 4.584"
    />
  </svg>
    );
  }
  
  const MemoSvgIndustrySegment = React.memo(IndustrySegment);
  export default MemoSvgIndustrySegment;
  