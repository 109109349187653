import React from 'react';

interface IconI {
	size?: number
	className?: string
}

export default function GraphIcon({size=16, className='white'}:IconI) {
	return (
		<svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M11 2C10.1716 2 9.5 2.67157 9.5 3.5C9.5 4.32843 10.1716 5 11 5C11.8284 5 12.5 4.32843 12.5 3.5C12.5 2.67157 11.8284 2 11 2ZM7.5 3.5C7.5 1.567 9.067 0 11 0C12.933 0 14.5 1.567 14.5 3.5C14.5 5.433 12.933 7 11 7C10.3972 7 9.83 6.84761 9.33481 6.57926L6.57926 9.33481C6.69292 9.54454 6.78577 9.7672 6.85506 10H15.1449C15.5752 8.55426 16.9145 7.5 18.5 7.5C20.433 7.5 22 9.067 22 11C22 12.933 20.433 14.5 18.5 14.5C17.8972 14.5 17.33 14.3476 16.8348 14.0793L14.0793 16.8348C14.3476 17.33 14.5 17.8972 14.5 18.5C14.5 20.433 12.933 22 11 22C9.067 22 7.5 20.433 7.5 18.5C7.5 16.567 9.067 15 11 15C11.6027 15 12.1699 15.1523 12.665 15.4206L15.4206 12.665C15.307 12.4553 15.2142 12.2327 15.1449 12H6.85506C6.42479 13.4457 5.08551 14.5 3.5 14.5C1.567 14.5 0 12.933 0 11C0 9.067 1.567 7.5 3.5 7.5C4.10272 7.5 4.66986 7.65235 5.165 7.92064L7.92064 5.165C7.65235 4.66986 7.5 4.10272 7.5 3.5ZM17 11C17 11.8284 17.6716 12.5 18.5 12.5C19.3284 12.5 20 11.8284 20 11C20 10.1716 19.3284 9.5 18.5 9.5C17.6716 9.5 17 10.1716 17 11ZM3.5 9.5C4.32843 9.5 5 10.1716 5 11C5 11.8284 4.32843 12.5 3.5 12.5C2.67157 12.5 2 11.8284 2 11C2 10.1716 2.67157 9.5 3.5 9.5ZM9.5 18.5C9.5 17.6716 10.1716 17 11 17C11.8284 17 12.5 17.6716 12.5 18.5C12.5 19.3284 11.8284 20 11 20C10.1716 20 9.5 19.3284 9.5 18.5Z" className={className}/>
		</svg>


	)
}