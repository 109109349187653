import {color} from 'styles/colors';
import styled from 'styled-components';
import selectIcon from 'styles/icons/carat-down.svg';
import {inputField} from 'styles/ui';


export const StyledSelect:any=styled.select`
  	${inputField};
	  color: white;
	  background-image: url(${selectIcon});
	  background-repeat: no-repeat;
	  background-position: right;
	  
	  option:first-child {
		color: ${color.get('neutral-400')};
	  }
  
	  -webkit-appearance: none;
	  -moz-appearance: none;
`;

export const SelectOption:any=styled.option`
	color:black;
`;
