import React from 'react'
import {HomeDataI, TagRowI} from '../../../interfaces/general';

interface HotlistsHomeI{
	homeData? : HomeDataI
}

export default function HotlistsHomeRender({homeData}:HotlistsHomeI) {
	return (
		<div className="mt-2">
			<div className="flex mb">
				{homeData?.tagRow && homeData?.tagRow.length > 0 && homeData?.tagRow.slice(0,2).map(({title, cover, titleColor, id}:TagRowI, index:number) => {
					return (
						<div key={`${title}-home-item`} className={`${index === 0 ? "w-55 mr" : "grows"} h-170px br neutral-800 relative p-1half border-box flex justify-start align-end t-${titleColor}`}>
							{cover &&
								<img
									src={`${cover}`}
									className="w-100 h-100 obj-cover absolute edges br"
								/>
							}
							<span className="f-urbanist f-1rem f-700 relative">{title}</span>
						</div>
					)
				})}
			</div>

			<div className="grid3 col-gap mb">
				{homeData?.tagRow && homeData?.tagRow.length > 0 && homeData?.tagRow.slice(2,5).map(({title, cover, titleColor, id}:any) => {
					return (
						<div key={`${title}-home-item`} className={`h-170px br neutral-800 relative p-1half border-box flex justify-start align-end t-${titleColor}`}>
							{cover &&
								<img
									src={`${cover}`}
									className="w-100 h-100 obj-cover absolute edges br"
								/>
							}
							<span className="f-urbanist f-1rem f-700 relative">{title}</span>
						</div>
					)
				})}
			</div>

			<div className="flex mb">
				{homeData?.tagRow && homeData?.tagRow.length > 0 && homeData?.tagRow.slice(5,7).map(({title, cover, titleColor, id}:any, index:number) => {
					return (
						<div key={`${title}-home-item`} className={`${index !== 0 ? "w-55" : "grows mr"} h-170px br neutral-800 relative p-1half border-box flex justify-start align-end t-${titleColor}`}>
							{cover &&
								<img
									src={`${cover}`}
									className="w-100 h-100 obj-cover absolute edges br"
								/>
							}
							<span className="f-urbanist f-1rem f-700 relative">{title}</span>
						</div>
					)
				})}
			</div>

		</div>
	)
}