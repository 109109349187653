import styled from 'styled-components';

export const Main :any = styled.div`
`;

export const DropDownContainer: any = styled('div')`
  width: 10.5em;
  margin: 0;
  @media (max-width: 600px) {
    padding: 0rem 1.5rem;
  }
`;

export const DropDownHeader: any = styled('div')`
  padding: 0.4em 1em 0.4em 0em;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  display: flex;
`;

export const DropDownListContainer: any = styled('div')``;

export const DropDownList: any = styled('ul')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  background: #38383A;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  width: 159px;
  height: 95px;
  border-radius: 5px;
  &:first-child {
    padding: 0.8em 0;
  }
`;

export const ListItem: any = styled('li')`
  list-style: none;
  margin: 0 8px;
  padding: 7px 3px;
  &:hover {
    background-color: #131313;
	border-radius: 5px;
  }
`;