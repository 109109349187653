import React, {useEffect, useState} from 'react';
import TalentsRender from './Talents.render';
import {QueryTuple, useLazyQuery} from '@apollo/client';
import {query} from '../../services/queries/queries';
import {TalentI} from '../../interfaces/general';
import {buttonStyle} from '../../styles/classes';
import SearchIcon from '../../assets/Icons/Interface/SearchIcon';
import TalentsForm from './Talents.form';
import {useRecoilState} from 'recoil';
import {tenantAtom} from '../../context/atoms';
import TenantSelector from '../../components/Form/TenantSelector';
import AddTenantForm from './components/AddTenantForm';
import IndustriesSelector from '../../components/Form/IndustriesSelector';
import {Link, useParams, useNavigate} from 'react-router-dom';

export default function TalentsLoad() {

	const {page} = useParams();
	const navigate = useNavigate();
	const [form, setForm] = useState(false);
	const [currentTenant, setCurrentTenant] = useRecoilState(tenantAtom);

	// eslint-disable-next-line @typescript-eslint/typedef
	const [queryTalents, {data,loading:l,error:e}]:QueryTuple<any, any> = useLazyQuery(query('usersFindManyAdmin'));

	const [loading, setLoading] = useState<boolean>(true);
	const [q, setQ] = useState<string>("");
	const [talents, setTalents] = useState<TalentI[]>([]);
	const [industries, setIndustries] = useState<string[]>([]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			getTalents();
		}, 400)

		return () => clearTimeout(delayDebounceFn)
	}, [q]);

	useEffect(()=> {
		if(loading) {
			getTalents();
		}
	},[loading]);


	useEffect(()=> {

		if(currentTenant){
			navigate("/talent/1");
			setLoading(true);
		}
	}, [currentTenant]);

	useEffect(()=> {
		if(industries?.length > 0){
			navigate("/talent/1");
			setLoading(true);
		}
	}, [industries]);

	const getTalents = () => {
		let variables:any = {
			skip:page ? (Number(page) - 1) * 50 : 0 ,
			limit:50,
			sort: 'CREATEDAT_DESC',
			filter: {
				type: "90b4abc2-11c4-11ed-861d-0242ac120002"
			}
		};
		if(q){
			variables.search = { name: q }
		};
		if(industries.length > 0){
			variables.search = { ...variables.search, industry: industries[0]}
		}
		if(!q && currentTenant && currentTenant.length > 0){
			variables.filter = {...variables.filter, tenants:currentTenant}
		}
		console.log(variables);
		queryTalents({
			variables,
			onCompleted: (data) => {
				setLoading(false);
				window.scrollTo(0, 0);
			},
			fetchPolicy:'network-only'
		});
	}

	useEffect(()=>{
		if(data){
			setTalents(data?.usersFindManyAdmin);
		}
	},[data]);

	return (
		<div>
			<div className="flex">
				<div className="flex align-start">
					<h5 className="f-urbanist f-700 mr">Talents</h5>
					<button
						className={buttonStyle}
						onClick={()=>setForm(true)}
					>
						+ Add Talent
					</button>
				</div>


				<div className="grows flex justify-end align-start">
					<div className="mr-2 max-w-300px w-300px">
						<IndustriesSelector placeholder={"Filter by Industry"} selected={industries} setSelected={(t) => setIndustries(t)} single={true} />
					</div>
					<div className="mr-2 max-w-300px w-300px">
						<TenantSelector placeholder={"Filter by tenants"} selected={currentTenant} setSelected={(t) => setCurrentTenant(t)} />
					</div>
					<div className="relative">
						<input type="text" placeholder="Search talents by name" style={{paddingRight: '3rem'}} name="query" onChange={(e)=>setQ(e.target.value)} />
						<div className="absolute h-3rem flex align-center top-0 right-1 op-60">
							<SearchIcon />
						</div>
					</div>
				</div>

			</div>

			<TalentsRender talents={talents} loading={loading} setLoading={setLoading} />

			<div className="mver flex col-gap-half pb-6">
				{page && Number(page) > 1 &&
					<Link
						to={`/talent/${Number(page) - 1}`}
						className={buttonStyle}
						onClick={()=>setLoading(true)}
					>
						{`<`}
					</Link>
				}
				{talents?.length === 50 &&
					<Link to={`/talent/${page ? Number(page)+1 : 2}`} className={buttonStyle} onClick={()=>setLoading(true)}>{`>`}</Link>
				}
			</div>

			{form &&
				<div className="fixed right-0 top-2 neutral-800 b-neutral-700-t-l br-tl p-2 bottom-0 flex form-enter overflow-y-scroll">
					<div className="grows">
						<TalentsForm setForm={setForm} setLoading={setLoading} />
					</div>
				</div>
			}

			<AddTenantForm setLoading={setLoading} />

		</div>
	)
}