import React, { ChangeEvent } from "react";
import { ListInput, Item, SelectProps } from "interfaces";
import DropDownRender from "./DropDown";
import { listHandler, useListHandler } from "hooks/useListHandler";

export const DropDownLoad = ({
  options,
  onChange,
  name,
  value,
  ...props
}: ListInput): JSX.Element => {
  const { handleUnselected, handleSelected, getSelectedItems }: listHandler =
    useListHandler({ name, value, options, onChange });
  const handleSelect = (e: ChangeEvent<HTMLSelectElement>): void => {
    let item: any = options.find(
      (i: Item): boolean => i.id === e.target.value
    );
    handleSelected(item);
  };

  const selectProps: SelectProps = {
    ...props,
    options,
    onChange: handleSelect,
  };
  return (
    <DropDownRender
      selectProps={selectProps}
      selectedItems={getSelectedItems()}
      handleClickTag={handleUnselected}
    />
  );
};
