import React, {useEffect, useState} from 'react';
import CloseIcon from '../../assets/Icons/Interface/CloseIcon';
import {buttonStyle} from '../../styles/classes';
import IndustriesSelector from '../../components/Form/IndustriesSelector';
import SegmentsSelector from '../../components/Form/SegmentsSelector';
import TenantSelector from '../../components/Form/TenantSelector';
import {useMutation} from '@apollo/client';
import {linkTensorSocial, talentMediaCreateOne} from '../../services/queries/mutations';

interface TalentFormI {
	setForm: (f:boolean) => void
	setLoading: (f:boolean) => void
}

interface TensorUserI {
	username?: string
	firstName?: string
	email?: string
	lastName?: string
	tenant?: string
	industry?: string[]
	industrySegment?: string[]
}

var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;


export default function AccountsForm({setForm, setLoading}:TalentFormI) {

	const [entry, setEntry] = useState<TensorUserI>();
	const [error, setError] = useState<string>("");

	const [linkTensor, { data, loading, error:createError }] = useMutation(linkTensorSocial);

	const saveUser = () => {
		console.log(entry);
		setError("");
		if(!entry?.username){
			setError("Please enter a valid Intagram Handle");
			return
		}
		if(!reg.test(entry?.email || "")){
			setError("Please enter a valid email")
		}
		if(!entry?.firstName){
			setError("Please enter First Name");
			return
		}

		linkTensor({
			variables : {
				...entry
			},
			onCompleted: (data:any):void => finishSave()
		});
	}

	const finishSave = () => {
		setLoading(true);
		setForm(false);
	}

	useEffect(()=>{
		if(createError){
			setError(createError?.message || "Error");
		}
	},[createError]);
	
	return (
		<div className="max-w-500px w-500px">
			<div className="flex align-center mb-2">
				<h4 className="f-urbanist grows">
					Add Talent
				</h4>
				<a onClick={()=>{
					if (setForm) {
						setForm(false)
					}
				}}>
					<CloseIcon />
				</a>
			</div>

			<p className="mb">
				<label>Instagram Handle *</label>
				<input
					type="text"
					name="username"
					placeholder="Instagram"
					value={entry?.username || ""}
					onChange={(e)=>setEntry({...entry, username : e.target.value})}
				/>
			</p>

			<p className="mb">
				<label>Email *</label>
				<input
					type="text"
					name="email"
					placeholder="Email"
					value={entry?.email || ""}
					onChange={(e)=>setEntry({...entry, email : e.target.value})}
				/>
			</p>

			<div className="grid2 col-gap">
				<p className="mb">
					<label>First Name *</label>
					<input
						type="text"
						name="firstName"
						placeholder="First Name"
						value={entry?.firstName || ""}
						onChange={(e)=>setEntry({...entry, firstName : e.target.value})}
					/>
				</p>

				<p className="mb">
					<label>Last Name</label>
					<input
						type="text"
						name="lastName"
						placeholder="Last Name"
						value={entry?.lastName || ""}
						onChange={(e)=>setEntry({...entry, lastName : e.target.value})}
					/>
				</p>
			</div>

			<div className="mb">
				<label>Industry</label>
				<IndustriesSelector
					selected={entry?.industry || []}
					setSelected={(industries:string[])=>setEntry({...entry, industry: industries})}
				/>
			</div>

			<div className="mb">
				<label>Industry Segment</label>
				<SegmentsSelector
					selected={entry?.industrySegment || []}
					setSelected={(segments:string[])=>setEntry({...entry, industrySegment: segments})}
				/>
			</div>

			<div className="mb">
				<label>Tenant</label>
				<TenantSelector
					selected={entry?.tenant ? [entry?.tenant] : []}
					setSelected={(tenants:string[])=>setEntry({...entry, tenant: tenants[tenants?.length - 1 || 0]})}
				/>
			</div>

			<div className="pver">
				{loading ?
					<div>
						<p className="mt f-12px f-bold f-urbanist">
							'Loading new talent...'
						</p>
						<p className="f-12px t-neutral-200">
							This operation will take a few minutes. Please wait or feel free to close the widow and refresh the Talents list in a few minutes...
						</p>
					</div>
					:
					<button className={buttonStyle} onClick={saveUser}>
						Save
					</button>
				}

				{error &&
					<p className="t-orange mt f-12px">
						{error}
					</p>
				}
			</div>

		</div>
	)
}