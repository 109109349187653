import React from "react";

function Close(props: any) {
    const {stroke, width, height} = props;
    return (
      <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 5L5 15M5 5l10 10"
        stroke={stroke || "#636363"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    );
  }
  const MemoSvgClose = React.memo(Close);
  export default MemoSvgClose;
  