import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function Editor({setDescription, data=""}) {
	return (
		<CKEditor
			editor={ClassicEditor}
			data={data}
			config={{
				toolbar: [
					'undo', 'redo',
					'|', 'heading',
					'|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
					'|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
					'|', 'link', 'blockQuote', 'codeBlock',
					'|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
				]
			}}
			// onReady={ editor => {
			// 	// You can store the "editor" and use when it is needed.
			// 	console.log( 'Editor is ready to use!', editor );
			// } }
			onChange={ ( event, editor ) => {
				setDescription(editor.getData());
			} }
			// onBlur={ ( event, editor ) => {
			// 	console.log( 'Blur.', editor );
			// } }
			// onFocus={ ( event, editor ) => {
			// 	console.log( 'Focus.', editor );
			// } }
		/>
	)
}