import React from "react";

function Search(props: any) {
    const {stroke, width, height} = props;
    return (
      <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M8.75 15a6.25 6.25 0 100-12.5 6.25 6.25 0 000 12.5z"
        stroke={stroke || "#636363"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 17.5l-4.333-4.333"
        stroke={stroke || "#636363"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    );
  }
  const MemoSvgSearch = React.memo(Search);
  export default MemoSvgSearch;
  