import React, {useEffect, useState} from 'react';
import TacticsRender from './Tactics.render';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../../services/queries/queries';
import TacticForm from '../forms/TacticForm';

export default function TacticsLoad(){

	const [loading, setLoading] = useState<boolean>(true);
	const [form, setForm] = useState(false);
	const [tId, setTId] = useState<string>("");

	const [queryTactics, { data }] = useLazyQuery(query("tacticsFindMany"));

	useEffect(()=>{
		if(loading){
			queryTactics({
				//variables: {},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => setLoading(false)
			});
		}
	},[loading]);

	return (
		<div>
			<TacticsRender tactics={data?.tacticFindMany || []} setTId={setTId} setLoading={setLoading} tId={tId} setForm={setForm} />

			{form &&
				<div className="fixed right-0 top-2 neutral-800 b-neutral-700-t-l br-tl p-2 bottom-0 flex form-enter overflow-y-scroll">
					<div className="grows">
						<TacticForm setForm={setForm} setLoading={setLoading} tId={tId} setTId={setTId} />
					</div>
				</div>
			}

		</div>
	)
}
