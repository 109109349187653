import React, {useEffect, useState} from 'react';
import {TenantI} from '../../../interfaces/general';
import {tagSoftStyle} from '../../../styles/classes';
import CloseIcon from '../../../assets/Icons/Interface/CloseIcon';
import {useMutation} from '@apollo/client';
import {tenantRemoveUserMany} from '../../../services/queries/mutations';

interface TTI {
	tenantsObj: TenantI[]
	user?: string
}

export default function TalentTenants({tenantsObj, user}:TTI) {

	const [tenants, setTenants] = useState<TenantI[]>([]);
	const [removeTenant, { data, loading, error }] = useMutation(tenantRemoveUserMany);

	useEffect(()=>{
		if(tenantsObj?.length > 0) {
			setTenants([...tenantsObj]);
		}
	},[tenantsObj]);


	const removeTenantUser = (id:string) => {

		const t:TenantI[] = [...tenants];
		const index:number = t.findIndex((x:TenantI):boolean => x._id === id);
		if (index !== -1) {
			t.splice(index, 1);
			setTenants(t);
		}

		removeTenant({
			variables : {
				user,
				tenants: [id]
			}
		});
	};

	return (
		<div className="flex wrap">
			{tenants && tenants?.length > 0 && tenants.map(({identifier, label, _id}:TenantI)=>{
				return(
					<div key={_id} className={`${tagSoftStyle} hover:neutral-700 cursor-pointer`} onClick={()=>_id ? removeTenantUser(_id) : null}>
						{label} <CloseIcon size={10} />
					</div>
				)
			})}
		</div>
	)
}