import React, {useState} from 'react'
import {UserTitle, Tab} from './styles';
import AudienceTable from './components/AudienceTable';

interface TableProps {
	user:any
	data:any
}

export default function TalentTableRender({user, data}:TableProps) {

	const [tab, setTab] = useState<string>("Age/Gender")

	return (
		<div className="relative" style={{border:'1px solid #444', padding: '120px 3rem 3rem', minHeight: '300px'}}>
			{user &&
				<UserTitle>
					<img src={user.profilePicture?.url} />
					<h1>{user.firstName} {user.lastName}</h1>
					<div style={{flexGrow: 1}} />
				</UserTitle>
			}

			<div className='flex'>
				<Tab selected={tab === "Age/Gender"} onClick={()=>setTab("Age/Gender")}>Age/Gender</Tab>
				<Tab selected={tab === "Ethnicities"} onClick={()=>setTab("Ethnicities")}>Ethnicities</Tab>
				<Tab selected={tab === "Brands"} onClick={()=>setTab("Brands")}>Brand Interests</Tab>
				<Tab selected={tab === "Interests"} onClick={()=>setTab("Interests")}>Topic Interests</Tab>
				<Tab selected={tab === "Cities"} onClick={()=>setTab("Cities")}>Cities</Tab>
				<Tab selected={tab === "States"} onClick={()=>setTab("States")}>States</Tab>
				<Tab selected={tab === "Counties"} onClick={()=>setTab("Counties")}>Countries</Tab>
				<Tab selected={tab === "Locales"} onClick={()=>setTab("Locales")}>Locales</Tab>
			</div>

			{tab === "Age/Gender" &&
				<AudienceTable data={data.audienceGenderAge} title="Audience Age/Gender" />
			}
			{tab === "Ethnicities" &&
				<AudienceTable data={data.audienceEthnicities} title="Audience Ethnicities" />
			}
			{tab === "Brands" &&
				<AudienceTable data={data.audienceBrand} title="Audience Brand Interests" hasAffinity={true} />
			}
			{tab === "Interests" &&
				<AudienceTable data={data.audienceInterests} title="Audience Topic Interests" />
			}
			{tab === "Cities" &&
				<AudienceTable data={data.audienceCity} title="Audience Cities" />
			}
			{tab === "Counties" &&
				<AudienceTable data={data.audienceCountry} title="Audience Counties" />
			}
			{tab === "Counties" &&
				<AudienceTable data={data.audienceCountry} title="Audience Counties" />
			}
			{tab === "States" &&
				<AudienceTable data={data.audienceState} title="Audience States" />
			}
			{tab === "Locales" &&
				<AudienceTable data={data.audienceLocale} title="Audience Locales" />
			}
		</div>
	)
}