import React, {useEffect, useState} from 'react';
import {inputFileContainerStyle} from '../../styles/classes';
import {uploadCollectionFile} from '../../services/files/files.service';
import DocIcon from '../../styles/icons/DocIcon';

enum PreviewEnum {
	small  = "100px",
	medium   = "300px"
}

interface FUI {
	fileUrl: string
	hidePreview?:boolean
	showName?:boolean
	endpoint:string
	id?:string
	onComplete?:()=>void
	format?:"16-9" | "1-1" | "none"
	previewWidth?:PreviewEnum
	previewSize?:"100px" | "200px" | "300px"
	idField?:string
	user?:string
}

/**
 *
 * @param fileUrl: url string for preview
 * @param hidePreview: shows preview by default
 * @param endpoint: API endpoint for image upload.
 * @param id: ID of the entry to attach picture.
 * @param showName: For file uploads that do not have a preview
 * @param onComplete: Trigger action when upload is completed
 */
export default function FileUpload({fileUrl, hidePreview, endpoint, id, showName, onComplete, format='16-9', previewSize="300px", idField="_id", user }:FUI) {

	const [uploading, setUploading] = useState<boolean>(false);
	const [cover, setCover] = useState<string>("");
	const [error, setError] = useState<string>("");
	const [name, setName] = useState<string>("");

	useEffect(()=>{
		if(fileUrl){
			setCover(fileUrl);
		}
	},[fileUrl]);

	const fileChange = (e:any) => {
		const file = e.target.files[0];
		if (file) {
			const c = URL.createObjectURL(file);
			setCover(c);
			setName(file.name);
		}
		console.log(file);

		let formData = new FormData();
		formData.append('file', file);
		if(id){
			formData.append(idField, id);
		}
		if(user){
			console.log('JK User', user);
			formData.append('user', user);
		}
		setUploading(true);
		setError("");
		fileUpload(formData);

	}

	const fileUpload = async (formData:any) => {
		try {
			console.log('FD', formData);
			await uploadCollectionFile(formData, endpoint);
			setUploading(false);
			if(onComplete){
				onComplete();
			}
		}
		catch (e:any){
			console.log("Error", e);
			setError(e?.message);
			setUploading(false);
		}
	}
	return (
		<div>
			{!hidePreview &&
				<div className={`w-${previewSize} br neutral-600 aspect-${format} mb`}>
					{cover &&
						<img src={cover} alt="preview" className={`w-${previewSize} aspect-${format} br obj-cover`} />
					}
				</div>
			}
			{showName && cover &&
				<a href={cover} target="_blank">
					<div className="flex align-center f-12px f-bold pver-half f-uppercase t-white">
						<DocIcon />
						<p className="ml-half">
							{
								endpoint === "upload-deck" ? "Alert Deck" :
								endpoint === "upload-videoTreatment" ? "Video Treatment":
								"File uploaded"
							}
						</p>
					</div>
				</a>
			}
			<div className={inputFileContainerStyle}>
				Select File
				<input type="file" name="cover" placeholder="Cover" onChange={fileChange} />
			</div>

			<div className="f-small mt-half h-1rem">
				{uploading ? "Uploading..." : " "}
				{error && error}
			</div>
		</div>

	)
}
