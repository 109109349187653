import React, {useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import {interestUpdateOne, subtacticCreateOne, subtacticUpdateOne} from '../../../services/queries/mutations';
import {TacticI} from '../../../interfaces/general';
import {slugify} from '../../../utils/misc';
import PencilIcon from '../../../assets/Icons/Interface/PencilIcon';
import {circleIconStyle} from '../../../styles/classes';

interface InterestTitleI {
	title:string
	id:string
}

export default function InterestTitle({title,id}:InterestTitleI) {

	const [t, setT] = useState<string>(title);
	const [editing, setEditing] = useState<boolean>(false);

	const [interestUpdate, { data:updateData, loading, error }] = useMutation(interestUpdateOne);

	useEffect(()=>{
		if(title){
			setT(title);
		}
	},[title]);

	const onSubmit = () => {
		interestUpdate({
			variables : {
				record: {
					title: t,
				},
				filter: { _id: id }
			},
			onCompleted: (data:any):void => finishSave()
		});
	}

	const finishSave = () => {
		setEditing(false);
	}

	return (
		<div>
			{editing ?
				<div className="f-14px">
					<input
						type="text"
						placeholder="Add a title"
						value={t || ""} onChange={(e)=>setT(e.target.value)}
						onBlur={onSubmit}
					/>

				</div>
				:
				<div className="flex align-center cursor-pointer" onClick={()=>setEditing(true)}>
					<button className={circleIconStyle}>
						<PencilIcon />
					</button>
					<div className="ml-half">
						{t}
					</div>


				</div>
			}

		</div>
	)
}