import React, { FC, useState } from "react";
import { Content, LinkNavigation, LinkTab, TabsWrapper } from "../styles";
import { UsersTenantsTalent } from "../Components/UsersTenantsTalent";
import { Title } from "Pages/Talent/styles";
import { UsersTenantsAccess } from "../Components/UsersTenantsAccess";

export const Detail:FC<any> = ({talentData, accessData}) => {
  const [activeTab, setActiveTab] = useState("/tenant/detail/talent");
  let tabContent: JSX.Element | null = null;
  switch (activeTab) {
    case "/tenant/detail/talent":
      tabContent = <UsersTenantsTalent data={talentData} />;
      break;
    case "/tenant/detail/access":
      tabContent = <UsersTenantsAccess data={accessData} />
      break;
    default:
      tabContent = null;
  }

  const handleTabClick = (tabPath: string) => {
    setActiveTab(tabPath);
  };

  return (
    <Content>
      <Title style={{ marginBottom: "20px" }}>Public</Title>
      <LinkNavigation to="/tenant">Back</LinkNavigation>
      <TabsWrapper>
        <LinkTab
          $active={activeTab === "/tenant/detail/talent"}
          onClick={() => handleTabClick("/tenant/detail/talent")}
        >
          Talent
        </LinkTab>
        <LinkTab
          $active={activeTab === "/tenant/detail/access"}
          onClick={() => handleTabClick("/tenant/detail/access")}
        >
          Access
        </LinkTab>
      </TabsWrapper>

      {tabContent}
    </Content>
  );
};
