import styled from 'styled-components';


export const FilterTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

export const FilterTagWrapper = styled.div`
  display: flex;
  align-items: center;
  /* background-color: #f1f1f1; */
  border: 1px solid ${({ theme }) => theme.red_500};
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
`;

export const FilterName = styled.span`
  margin-right: 4px;
`;

export const RemoveFilterButton = styled.button`
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: ${({ theme }) => theme.red_500};
`;
