import gql from "graphql-tag";

const queries: any = {
  postFindMany:gql`
    query postFindMany(
      $filter: FilterFindManypostInput,
      $skip :Int,
      $limit :Int,
      $sort: SortFindManypostInput
    )
    {
      postFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
        _id
        user
        platform
        postId
        caption
        likesCount
        mediaUrl
        permalink
        commentsCount
        file{
          url
          type
        }
        createdAt
      }
    }`,

	'talentMediaFindMany':gql`
        query talentMediaFindMany(
            $filter: FilterFindManytalentMediaInput,
            $skip :Int,
            $limit :Int,
            $sort: SortFindManytalentMediaInput
        )
        {
            talentMediaFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
                _id
                user
                title
                link
                updatedAt
                createdAt
                files {
                    url
                    type
                }
            }
        }`,

  talentFindOneAdmin: gql`
    query TalentFindOneAdmin($filter: FilterFindOnetalentProfileInput) {
      talentFindOneAdmin(filter: $filter) {
        _id
        userObj(skip: 0, sort: _ID_ASC) {
          _id
          firstName
          lastName
          email
          emailVerified
          type
          sync
          acceptTerms
          profilePicture {
            url
            type
          }
          deleted
          tenants
          verified
          createdAt
          updatedAt
          socialNetwork {
            url
          }
          tenantsObj {
            _id
            label
            identifier
            createdAt
            updatedAt
          }
        }
        user
        interest
        country
        description
        phone
        languages
        website
        gender
        ethnicity
        musicGenres
        brands
        hasChildren
        hasPets
        spirits
        shoeSizes
        clothingSizes
        tenant
        industry
        industrySegment
        industryObj {
          _id
          label
          identifier
          createdAt
          updatedAt
        }
        industrySegmentObj {
          _id
          label
          identifier
          createdAt
          updatedAt
        }
        address {
          address1
          address2
          city
          state
          zip
        }
        relationshipStatus
        birthday
        createdAt
        updatedAt
      }
    }
  `,
		tacticsFindMany: gql`
			query tacticFindMany ($filter: FilterFindManytacticInput) {
				tacticFindMany(filter: $filter){
					_id
					slug
					active
					description
					title
					cover {
						url
						type
					}
					deletedAt
					createdAt
					updatedAt
				}
			}
		`,
		subtacticFindMany: gql`
			query subtacticFindMany ($filter: FilterFindManysubtacticInput) {
				subtacticFindMany(filter: $filter){
					_id
					slug
					active
					description
					title
					deletedAt
					createdAt
					updatedAt
				}
			}
		`,
  usersFindManyAdmin: gql`
    query usersFindManyAdmin(
      $skip: Int!
      $limit: Int!
      $search: SearchFindManyuserInput
      $filter: FilterFindManyuserInput
    ) {
      usersFindManyAdmin(
        skip: $skip
        limit: $limit
        search: $search
        sort: CREATEDAT_DESC
        filter: $filter
      ) {
        _id
        email
        firstName
        lastName
        profilePicture {
          url
        }
        tenants
        type
        tenantsObj {
          label
          _id
          createdAt
          identifier
          updatedAt
        }
        socialNetwork {
          url
        }
        createdAt
        verified
        sync
        syncStatus
        reportedAt
      }
    }
  `,

  skuFindManyAdmin: gql`
    query skuFindManyAdmin($skip: Int!, $limit: Int!) {
      skuFindManyAdmin(filter: {}, skip: $skip, limit: $limit, sort: _ID_ASC) {
        _id
        name
        brand
        brandObj {
          _id
          company
          description
          name
        }
        description
        sku
        picture {
          url
        }
      }
    }
  `,

  brandFindMany: gql`
    query BrandFindMany(
      $filter: FilterFindManybrandInput
      $skip: Int
      $limit: Int
    ) {
      brandFindMany(
        filter: $filter
        skip: $skip
        limit: $limit
        sort: CREATEDAT_DESC
      ) {
        _id
        company
        createdAt
        description
        interest
        name
        picture {
          url
        }
        updatedAt
        website
      }
    }
  `,

  opportunityFindManyAdmin: gql`
    query opportunityFindManyAdmin($skip: Int!, $limit: Int!) {
      opportunityFindManyAdmin(
        filter: {}
        skip: $skip
        limit: $limit
        sort: _ID_ASC
      ) {
        _id
        name
        description
        product
        productObj {
          _id
          company
          createdAt
          description
          interest
          name
          picture {
            url
          }
          updatedAt
          website
        }
      }
    }
  `,

  graphByUserFindOne: gql`
    query graphByUserFindOne($_id: String!) {
      graphByUserFindOne(_id: $_id) {
        user {
          _id
          firstName
          lastName
          profilePicture {
            url
          }
          socialNetwork(skip: 0, sort: _ID_ASC) {
            url
            identifier
          }
        }
        graph {
          platform
          subgraph
          graph {
            nodes {
              identity
              labels
              properties {
                name
                id
              }
            }
            relationships {
              identity
              type
              start_node
              end_node
              properties {
                weight
                affinity
              }
            }
          }
        }
      }
    }
  `,
  instagramAudienceFindOneAdmin: gql`
    query instagramAudienceFindOneAdmin(
      $filter: FilterFindOneinstagramAudienceInput
    ) {
      instagramAudienceFindOneAdmin(filter: $filter) {
        _id
        user
        followersCount
        followsCount
        audienceCity {
          name
          weight
          interest
          affinity
        }
        audienceCountry {
          name
          weight
          interest
          affinity
        }
        audienceGenderAge {
          name
          weight
          interest
          affinity
        }
        audienceLocale {
          name
          weight
          interest
          affinity
        }
        audienceEthnicities {
          name
          weight
          interest
          affinity
        }
        audienceInterests {
          name
          weight
          interest
          affinity
        }
        audienceBrand {
          name
          weight
          interest
          affinity
        }
        audienceState {
          name
          weight
          interest
          affinity
        }
      }
    }
  `,
  gendersFindMany: gql`
    query gendersFindMany {
      gendersFindMany(filter: {}, skip: 0, limit: 100, sort: _ID_ASC) {
        _id
        label
        identifier
        updatedAt
        createdAt
      }
    }
  `,

  languageFindMany: gql`
    query languageFindMany {
      languageFindMany(filter: {}, skip: 0, limit: 100, sort: _ID_ASC) {
        _id
        label
        identifier
        updatedAt
        createdAt
      }
    }
  `,
  ethnicityFindMany: gql`
    query ethnicityFindMany {
      ethnicityFindMany(filter: {}, skip: 0, limit: 100) {
        _id
        label
        updatedAt
        createdAt
      }
    }
  `,
  relationshipStatusFindMany: gql`
    query relationshipStatusFindMany {
      relationshipStatusFindMany(
        filter: {}
        skip: 0
        limit: 100
        sort: _ID_ASC
      ) {
        _id
        label
        updatedAt
        createdAt
      }
    }
  `,

  tenantFindMany: gql`
    query TenantFindMany($limit: Int) {
      tenantFindMany(limit: $limit, sort: _ID_ASC) {
        _id
        createdAt
        identifier
        label
      }
    }
  `,

  usersCompanyProfileFindOne: gql`
    query UsersCompanyProfileFindOne(
      $filter: FilterFindOnecompanyProfileInput
    ) {
      usersCompanyProfileFindOne(filter: $filter) {
        name
        phone
        position
        user
        website
        interest
      }
    }
  `,

  usersCompanyProfileFindMany: gql`
    query UsersCompanyProfileFindMany(
      $filter: FilterFindManycompanyProfileInput
      $limit: Int
      $skip: Int
      $sort: SortFindManycompanyProfileInput
    ) {
      usersCompanyProfileFindMany(
        filter: $filter
        limit: $limit
        skip: $skip
        sort: $sort
      ) {
        _id
        user
        name
        website
        phone
        interest
        position
        linkedinUrl
        logo {
          url
        }
        createdAt
        updatedAt
        userObj {
          _id
          firstName
          lastName
          email
          emailVerified
          type
          acceptTerms
          profilePicture {
            url
          }
          deleted
          tenants
          verified
          createdAt
          updatedAt
          socialNetwork {
            url
          }
          tenantsObj {
            _id
            label
            identifier
            createdAt
            updatedAt
          }
        }
      }
    }
  `,
  interestFindMany: gql`
    query InterestFindMany(
      $filter: FilterFindManyinterestInput
      $limit: Int
      $skip: Int
      $sort: SortFindManyinterestInput
    ) {
      interestFindMany(
        filter: $filter
        skip: $skip
        limit: $limit
        sort: $sort
      ) {
        _id
        label
        title
        identifier
        hidden
        order
        father
        tensorInterests
        picture {
          url
          type
        }
        updatedAt
        createdAt
      }
    }
  `,

	interestSearchMany: gql`
		query InterestSearchMany($query: String)
		{
			interestSearchMany(
				query: $query
			) {
				_id
				label
				title
				identifier
				hidden
				order
				tensorInterests
				updatedAt
				createdAt
			}
		}
	`,

  industryFindMany: gql`
    query IndustryFindMany {
      industryFindMany {
        _id
        label
        identifier
        updatedAt
        createdAt
      }
    }
  `,

  industrySegmentFindMany: gql`
    query IndustrySegmentFindMany {
      industrySegmentFindMany {
        _id
        label
        identifier
        updatedAt
        parent
        createdAt
      }
    }
  `,

	culturalAlertFindMany: gql`
		query culturalAlertFindMany(
			$filter: FilterFindManyculturalAlertInput
			$limit: Int
			$skip: Int
			$sort: SortFindManyculturalAlertInput
		) {
			culturalAlertFindMany(
				filter: $filter
				skip: $skip
				limit: $limit
				sort: $sort
			) {
				_id
				user
				culturalType
				title
				videoSongTitle
				videoSongUrl
				videoDirector
				videoShootDate
				videoShootTime
				eventPaid
				eventCategory
				eventFrom
				eventTo
				eventPartnership
				endDate
				description
				interest
				additionalInfo
				eventLocation
				city
				state
				website
				artist
				slug
				tenant
				tags
				subtactic
				tactic
				earnedMedia
				active
				deliverables
				createdAt
				updatedAt
				videoTreatment {
					url
					type
				}
				photos {
					url
					type
				}
				feeRange {
					min
					max
					createdAt
					updatedAt
				}
				deck {
					url
					type
				}
			}
		}
	`,

	culturalAlertByCompanyFindMany: gql`
		query culturalAlertByCompanyFindMany(
			$filter: FilterFindManybrandCulturalAlertInput
			$limit: Int!
			$skip: Int!
		) {
			culturalAlertByCompanyFindMany(
				filter: $filter
				skip: $skip
				limit: $limit
			) {
				_id
				user
				culturalType
				title
				videoSongTitle
				videoSongUrl
				videoDirector
				videoShootDate
				videoShootTime
				eventPaid
				eventCategory
				eventFrom
				eventTo
				eventPartnership
				endDate
				description
				interest
				additionalInfo
				eventLocation
				city
				state
				website
				artist
				slug
				tenant
				tags
				subtactic
				tactic
				earnedMedia
				active
				deliverables
				createdAt
				updatedAt
				videoTreatment {
					url
					type
				}
				photos {
					url
					type
				}
				feeRange {
					min
					max
					createdAt
					updatedAt
				}
				deck {
					url
					type
				}
			}
		}
	`,

	culturalAlertFindOne: gql`
		query culturalAlertFindOne(
			$filter: FilterFindOneculturalAlertInput
			$skip: Int
			$sort: SortFindOneculturalAlertInput
		) {
			culturalAlertFindOne(
				filter: $filter
				skip: $skip
				sort: $sort
			) {
				_id
				user
				culturalType
				title
				videoSongTitle
				videoSongUrl
				videoDirector
				videoShootDate
				videoShootTime
				eventPaid
				eventCategory
				eventFrom
				eventTo
				eventPartnership
				endDate
				description
				interest
				additionalInfo
				eventLocation
				city
				state
				website
				artist
				slug
				tenant
				tags
				subtactic
				tactic
				earnedMedia
				active
				deliverables
				createdAt
				updatedAt
				videoTreatment {
					url
					type
				}
				photos {
					url
					type
				}
				feeRange {
					min
					max
					createdAt
					updatedAt
				}
				deck {
					url
					type
				}
                additionalFees
                expectedAttendees
                participationRequirements
                product
                productDescription
                productIntegrationGuidelines
                paymentDetails
                availableSlots
			}
		}
	`,

  socialCardFindOne: gql`
    query socialCardFindOne($filter: FilterFindOnesocialCardInput) {
      socialCardFindOne(filter: $filter) {
        _id
        user
        updatedAt
        createdAt
        instagram {
          followers
          username
        }
        tiktok {
          followers
          username
        }
        youtube {
          followers
          username
        }
      }
    }`,
  usersFindByIdAdmin: gql`
    query usersFindByIdAdmin($id: String!) {
      usersFindByIdAdmin(_id: $id) {
        _id
        firstName
        lastName
        email
        emailVerified
        type
        acceptTerms
        deleted
        tenants
        verified
        sync
        syncStatus
        reportedAt
        createdAt
        updatedAt
        socialNetwork {
          url
        }
      }
    }`
};

export const query = (value: string): any => {
  return queries[value];
};
