import { Link } from "react-router-dom";
import styled from "styled-components";
import { mediaQuery } from "styles/mediaQueries";

export const LinkTab: any = styled(Link)<any>`
  font-weight: 500;
  line-height: 19px;
  font-size: 1rem;
  text-transform: capitalize;
  padding-bottom: 6px;
  ${({ $active }: any): string =>
    $active
      ? `border-bottom:solid 4px white;
		 color: white;
		`
      : `border-bottom:solid 4px black;
		color:gray;
		`}
`;

export const Content: any = styled.div`
  margin-top: 2rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: content;
  max-width: 900px;
  width: 100%;
  justify-self: center;
  > p {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    line-height: 32px;
    letter-spacing: 0.3px;
    margin-bottom: 0.4rem;
    color: #fff;
    background-color: #fff;
  }
  > form {
    width: 100%;
    max-width: 600px;
    align-items: center;
    > button {
      display: block;
      margin-right: auto;
      margin-left: auto;
    }
  }
`;

export const TabsWrapper: any = styled.div`
  width: 30%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1.5rem;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  ${mediaQuery("mobile")} {
    max-width: calc(100vw - 2rem);
  }
`;

export const Title: any = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  text-align: left;
  width: 100%;
  margin-bottom: 2rem;
`;

export const Image: any = styled.img`
  width: 182px;
  height: 182px;
  border-radius: 50%;
  margin-bottom: 2rem;
`;

export const Label: any = styled.label`
  position: absolute;
  bottom: 30px;
  right: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const LinkNavigation = styled(Link)`
 font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  text-align: left;
  width: 100%;
  margin-bottom: 2rem;
  color: #fff;
  text-decoration: none;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;
