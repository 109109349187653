import React from 'react';
import {TenantI} from '../../interfaces/general';
import {circleIconStyle} from '../../styles/classes';
import UsersIcon from '../../assets/Icons/Interface/UsersIcon';
import {Link} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import {tenantAtom} from '../../context/atoms';

interface TRI{
	tenants?: TenantI[]
}

export default function TenantsRender({tenants}:TRI){

	const [currentTenant, setCurrentTenant] = useRecoilState(tenantAtom);

	return (
		<div>
			<table className="list-table">
				<thead>
					<tr>
						<td>
							Label
						</td>
						<td>
							Id
						</td>
						<td>
							Actions
						</td>
					</tr>
				</thead>
				<tbody>
					{tenants && tenants?.length > 0 && tenants?.map(({_id, label, identifier}:TenantI)=>{
						return (
							<tr key={_id}>
								<td>
									<div>
										<p className="f-700">{label}</p>
										<span className="f-12px">{identifier}</span>
									</div>
								</td>
								<td>
									<p className="f-10px">{_id}</p>
								</td>
								<td className="right">
									<div className="flex justify-end">
										<Link
											to="/talent"
											className={`ml-half ${circleIconStyle}`}
											title='View users in tenant'
											onClick={()=>setCurrentTenant(_id ? [_id] : [""])}
										>
											<UsersIcon className="neutral-200" />
										</Link>
									</div>
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}