import { FC } from "react";
import styled from "styled-components";

const AvatarContainer = styled.div<{ size?: any }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #383838;
  width: ${({ size }) => size || "30px"};
  height: ${({ size }) => size || "30px"};
  border-radius: 50%;
  color: #ffffff;
  font-weight: bold;
  margin-right: 10px;
`;

interface AvatarProps {
  firstName: string;
  lastName: string;
  size?: any;
}

export const Avatar: FC<AvatarProps> = ({ firstName, lastName, size }) => {
  const initials = `${firstName.charAt(0)} ${lastName.charAt(0)}`.toUpperCase();

  return <AvatarContainer size={size}>{initials}</AvatarContainer>;
};
