import React from 'react';
import {InterestI} from '../../interfaces/general';
import Boolean from '../../components/Form/Boolean';
import {useMutation} from '@apollo/client';
import {interestUpdateOne} from '../../services/queries/mutations';
import FileUpload from '../../components/Form/FileUpload';
import InterestTitle from './components/InterestTitle';

interface InterestRenderI {
	interests? : InterestI[]
	setInterests : (i:InterestI[]) => void
}

export default function InterestRender({interests, setInterests}:InterestRenderI) {

	const [insterestUpdate, { data, loading, error }] = useMutation(interestUpdateOne);

	const updateStatus = (_id:string, hidden:boolean) => {
		insterestUpdate({
			variables : {
				record: { hidden },
				filter: { _id }
			}
		});

		let i:InterestI[] = interests && interests?.length > 0 ? [...interests] : [];
		const index:number|undefined = interests?.findIndex((e:InterestI):boolean=>e._id === _id);
		if (typeof index === 'number' && index !== -1) {
			i[index].hidden = hidden;
			setInterests(i);
		}
	}

	return (
		<div>
			<table className="list-table">
				<thead>
					<tr>
						<td>
							Label
						</td>
						<td>
							Title (Displayed in the UI)
						</td>
						<td>
							<div className="phor">
								Tensor Social Relation
							</div>

						</td>
						<td>
							Active
						</td>
					</tr>
				</thead>
				<tbody>
				{interests && interests.length > 0 && interests.map(({title, label, father, _id, hidden, tensorInterests, picture}:InterestI):JSX.Element=>{
					return (
						<tr key={_id}>
							<td>
								<div className="flex align-center">
									<div className="mr">
										<FileUpload
											fileUrl={picture?.url || ""}
											endpoint={"common/interest-picture"}
											id={_id}
											idField="id"
											format="1-1"
											previewSize="100px"
										/>
									</div>
									<div>
										<p className="f-700">{label}</p>
										<p className="f-12px">{_id}</p>
									</div>
								</div>

							</td>
							<td>
								<InterestTitle title={title || ""} id={_id || ""} />
							</td>
							<td className="phor">
								<div className="flex wrap max-w-300px">
									{tensorInterests && tensorInterests?.length > 0 && tensorInterests.map((interest)=><span key={`${interest}-$	{_id}`} className="p-half br-half neutral-800 mr-quarter t-neutral-200 mb-quarter">{interest}</span>)}
								</div>
							</td>
							<td>
								<Boolean value={!hidden || false} setValue={()=>_id ? updateStatus(_id, !hidden) : null} />
							</td>
						</tr>
					)
				})}
				</tbody>
			</table>
		</div>
	)
}
