import React from "react";

function Ellipsis(props: any) {
    const {fill, width, height} = props;
    return (
        <svg
        width={width || 17}
        height={height || 5}
        viewBox="0 0 17 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M2.594 4.172c.333 0 .635-.083.906-.25.276-.167.497-.386.664-.656.167-.276.25-.576.25-.899 0-.51-.174-.937-.523-1.281C3.547.737 3.115.562 2.594.562c-.328 0-.63.081-.906.243-.271.161-.49.38-.657.656-.161.27-.242.573-.242.906 0 .323.08.623.242.899.167.27.386.49.657.656.276.167.578.25.906.25zm5.906 0c.323 0 .62-.083.89-.25.277-.167.498-.386.665-.656.166-.276.25-.576.25-.899 0-.51-.175-.937-.524-1.281A1.726 1.726 0 008.5.562a1.777 1.777 0 00-1.563.899c-.161.27-.242.573-.242.906 0 .323.081.623.242.899.162.27.378.49.649.656.276.167.58.25.914.25zm5.898 0c.323 0 .62-.083.891-.25.276-.167.498-.386.664-.656.167-.276.25-.576.25-.899 0-.51-.174-.937-.523-1.281a1.726 1.726 0 00-1.282-.524c-.333 0-.638.081-.914.243-.276.161-.497.38-.664.656-.161.27-.242.573-.242.906 0 .323.08.623.242.899.167.27.388.49.664.656.276.167.581.25.914.25z"
          fill={fill || "#fff"}
        />
      </svg>
    );
  }
  const MemoSvgEllipsis = React.memo(Ellipsis);
  export default MemoSvgEllipsis;
  