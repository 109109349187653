import { useContext, useEffect, useState } from "react";
import { QueryResult, useQuery, useMutation } from "@apollo/client";

import { useForm } from "hooks/useForm/useForm";
import { query } from "services/queries/queries";
import Verificated from "assets/Images/verificado.png";
import { FormField, FormHook } from "hooks/useForm/interfaces";
import {
  ACTIVE_USER,
  RESYNC_USER,
  LINK_TENSOR_SOCIAL,
  REFRESH_TENSO_SOCIAL,
  TENANT_ADD_USER_MANY,
  TENANT_REMOVE_USER_MANY,
} from "services/queries/mutations";
import { useFilters } from "context/useFilterContext/useFilterContext";

import { Talent } from "./Talent";
import { useToast } from "context/toastContext/toastContext";
import { Link, redirect, useParams } from "react-router-dom";
import { WrapperName } from "Pages/Brands/styles";
import {
  DivLink,
  StyledLink,
  Text,
  TextTitle,
} from "components/Atoms/TableRow/styles";
import { Avatar } from "components/Atoms/Avatar/Avatar";
import { EllipsisSvg, ErrorIconSvg } from "assets";
import { FilterTagsWrapper, Photo, WrapperInput } from "./styles";
import { Button } from "components/Atoms/Button/Button";
import { Popup } from "components/Atoms/Popup/Popup";
import Divider from "components/Atoms/Divider/Divider";
import { Modal } from "components/Organisms/Modal/Modal";
import { FilterTag } from "components/Atoms/FilterTag/FilterTag";
import { Select } from "components/Atoms/Select/Select";
import { UserContext } from "context/userContext/userContext";

export const TalentLoad = () => {
  const limit = 50;

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [tenantsList, setTenantsList] = useState([]);
  const [showPopupIndex, setShowPopupIndex] = useState(-1);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [showDrawerTenant, setShowDrawerTenant] = useState(false);
  const [isLoadingGenerate, setIsLoadingGenerate] = useState(false);
  const { logout }: any = useContext(UserContext);
  const { error: err, success }: any | unknown = useToast();
  const { filters }: any = useFilters();
  const { page: pageParam } = useParams();

  const page = Number(pageParam) || 0;

  const [resyncTalent] = useMutation(RESYNC_USER);
  const [userUpdateOneAdmin] = useMutation(ACTIVE_USER);
  const [linkTensorSocial] = useMutation(LINK_TENSOR_SOCIAL);
  const [refreshTensorSocial] = useMutation(REFRESH_TENSO_SOCIAL);
  const [tenantAddUserMany] = useMutation(TENANT_ADD_USER_MANY);
  const [tenantRemoveUserMany] = useMutation(TENANT_REMOVE_USER_MANY);

  const setPage = (newPage: number) => {
    redirect(`/talents/${newPage}`);
  };

  const handleShowDrawer = () => {
    setShowDrawer(true);
  };

  const handleShowAddTenant = () => {
    setShowDrawerTenant(true);
  };

  const handleRemoveTenant = async (tenantId: string, idUser: string) => {
    try {
      await tenantRemoveUserMany({
        variables: {
          tenantAddUserManyUser2: idUser,
          tenantAddUserManyTenants2: [tenantId],
        },
      });
      success("User successfully removed from tenant!");
      refetch();
      setSelectedUser(null);
      setShowDrawerTenant(false);
    } catch (error: any) {
      if (error.message) {
        err(error.message);
      } else {
        err("An error occurred!");
      }
    }
  };

  const handleAddTenant = async (tenantData: any) => {
    try {
      await tenantAddUserMany({
        variables: {
          tenantAddUserManyUser2: selectedUser.id,
          tenantAddUserManyTenants2: [tenantData.tenant],
        },
      });
      success("User successfully added to tenant!");
      refetch();
      resetForm();
      setSelectedUser(null);
      setShowDrawerTenant(false);
    } catch (error: any) {
      if (error.message) {
        err(error.message);
      } else {
        err("An error occurred!");
      }
    }
  };

  const formFields: FormField[] = [
    {
      name: "email",
      required: true,
      default: "",
      pattern: /^\S+@\S+\.\S+$/,
      watch: true,
    },
    { name: "username", required: true, default: "" },
    { name: "name", required: false, default: "" },
    { name: "lastName", required: false, default: "" },
    { name: "tenant", required: false, default: "" },
    { name: "industry", required: false, default: [] },
    { name: "industrySegment", required: false, default: [] },
  ];

  const formFieldsTenanr: any[] = [
    { name: "tenant", required: false, default: "" },
  ];

  const registerAction = async (loginData: any): Promise<void> => {
    try {
      await linkTensorSocial({
        variables: {
          username: loginData.username,
          firstName: loginData.name,
          lastName: loginData.lastName,
          email: loginData.email,
          tenant: loginData.tenant,
          industry: loginData.industry,
          industrySegment: loginData.industrySegment,
        },
      });
      success("user successfully registered!");
      refetch();
      setShowModal(false);
    } catch (error: any) {
      if (error.message) {
        err(error.message);
      } else {
        err("An error occurred with register!");
      }
    }
  };

  const { data, errors, loading, ...rest }: FormHook = useForm(
    formFields,
    registerAction
  );

  const {
    data: dataTenant,
    errors: errorsTenant,
    loading: loadingTenant,
    resetForm,
    ...restData
  }: FormHook = useForm(formFieldsTenanr, handleAddTenant);

  const columns = [
    {
      header: "Name",
      accessor: "name",
      Cell: (row: any) => (
        <WrapperName>
          {row.image ? (
            <Photo src={row.image} alt="photo" />
          ) : (
            <Avatar firstName={row.name} lastName="" />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              {row.name}
              {row.verified ? (
                <img src={Verificated} alt="verified" width={20} height={20} />
              ) : (
                <ErrorIconSvg />
              )}
            </div>
            <div style={{ color: "#9B9B9B", fontSize: "10px" }}>{row.id}</div>
          </div>
        </WrapperName>
      ),
    },
    { header: "Email", accessor: "email" },
    { header: "Intagram", accessor: "intagram" },
    {
      header: "Tenant",
      accessor: "tenant",
      Cell: (row: any) => (
        <WrapperInput>
          <FilterTagsWrapper>
            {row.tenant.map((item: any) => {
              if (typeof item.label === "string" && item.label.trim() !== "") {
                return (
                  <FilterTag
                    key={item._id}
                    name={item.label}
                    onRemove={() => handleRemoveTenant(item._id, row.id)}
                  />
                );
              } else {
                return null;
              }
            })}
            <Button
              width="auto"
              height="30px"
              label="Add Tenant"
              onClick={() => {
                setSelectedUser(row); // Almacena el ID en el estado
                handleShowAddTenant();
              }}
            />
          </FilterTagsWrapper>

          {showDrawerTenant && (
            <Modal
              isOpen={true}
              setShowModal={() => setShowDrawerTenant(false)}
            >
              <form
                onSubmit={restData.handleSubmitForm}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "60px",
                  alignItems: "center",
                  height: "80%",
                  padding: "20px",
                }}
              >
                <TextTitle>Add Tenant</TextTitle>

                {selectedUser.image ? (
                  <Photo src={selectedUser.image} alt="photo" size="100px" />
                ) : (
                  <Avatar
                    firstName={selectedUser.name}
                    lastName=""
                    size="100px"
                  />
                )}

                <Text>
                  {selectedUser?.name} {selectedUser?.lastName}
                </Text>

                <Select
                  label={"Add Tenant"}
                  options={tenantsList.map((item: any) => ({
                    id: item._id,
                    label: item.label,
                    value: item._id,
                  }))}
                  {...restData.registerSelect2?.("tenant")}
                />
                <Button
                  width="100%"
                  height="50px"
                  label="Add Tenant"
                  isLoading={isLoadingGenerate}
                />
              </form>
            </Modal>
          )}
        </WrapperInput>
      ),
    },
    {
      header: "Action",
      accessor: "action",
      Cell: (row: any) => {
        return (
          <WrapperInput>
            <Link
              to={`/talent/graph/${row.id}`}
              style={{ textDecoration: "none" }}
            >
              <Button width="auto" height="auto" label="View Talent" />
            </Link>

            <div style={{ position: "relative" }}>
              <EllipsisSvg
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowPopupIndex(row.id);
                }}
              />

              {showPopupIndex === row.id && (
                <Popup onClose={() => setShowPopupIndex(-1)}>
                  <DivLink
                    onClick={() => {
                      setSelectedUser(row); // Almacena el ID en el estado

                      handleShowDrawer();
                    }}
                  >
                    <p>Generate Report</p>
                  </DivLink>
                  <Divider />
                  <DivLink
                    onClick={() => {
                      setSelectedUser(row); // Almacena el ID en el estado

                      handleShowDrawer();
                    }
                    }
                  >
                    <p>Resync User</p>
                  </DivLink>
                  <Divider />
                  <StyledLink to={`/talent/edit/${row.id}`}>
                    Update User
                  </StyledLink>
                  <Divider />
                  <DivLink
                    onClick={() => handleChangeActive(row.id, !row.verified)}
                  >
                    <p>{row.verified ? "Unverify User" : "Verify User"}</p>
                  </DivLink>
                </Popup>
              )}

              {showDrawer && (
                <Modal isOpen={true} setShowModal={() => setShowDrawer(false)}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "60px",
                      alignItems: "center",
                      height: "80%",
                      padding: "20px",
                    }}
                  >
                    <TextTitle>Generate Report</TextTitle>
                    <Text>
                      This action will generate a new Tensor Social Report and
                      spend one token from the account
                    </Text>
                    <Button
                      width="50%"
                      height="40px"
                      label="Generate"
                      isLoading={isLoadingGenerate}
                      onClick={() => handleRefreshUser(selectedUser?.id)}
                    />

                    <TextTitle> Resync Talent</TextTitle>
                    <Text>
                      This action will synchronize the data with Neo4j
                    </Text>
                    <Button
                      width="50%"
                      height="40px"
                      label="Resync"
                      isLoading={isLoading}
                      onClick={() => handleResyncUser(selectedUser?.id)}
                    />
                  </div>
                </Modal>
              )}
            </div>
          </WrapperInput>
        );
      },
    },
  ];

  const {
    data: tenantsData,
  }: QueryResult<
    any,
    { skip: number; limit: number; search?: any; filter: any }
  > = useQuery(query("tenantFindMany"));

  const {
    data: industryData,
  }: QueryResult<
    any,
    { }
  > = useQuery(query("industryFindMany"));

  const {
    data: industrySegmentData,
  }: QueryResult<
    any,
    { }
  > = useQuery(query("industrySegmentFindMany"));

  useEffect(() => {
    if (tenantsData?.tenantFindMany) {
      setTenantsList(tenantsData?.tenantFindMany);
    }
  }, [tenantsData]);

  useEffect(() => {
    if (page === undefined || page === null || isNaN(page)) {
      redirect("/1");
    }
  }, [page, pageParam]);

  useEffect(() => {
    const pageNumber = Number(pageParam);
    if (!isNaN(pageNumber) && pageNumber !== page) {
      setPage(pageNumber);
    }
  }, [page, pageParam]);

  useEffect(() => {
    if (Number(pageParam) !== page) {
      redirect(`/${page}`);
    }
  }, [page, pageParam]);

  const handleResyncUser = async (id: string) => {
    setIsLoading(true);
    try {
      await resyncTalent({
        variables: {
          id: id,
        },
      });
      success("Resync user successfully!");
    }  catch (error: any) {
      if (error.message) {
        err(error.message);
      } else {
        err("An error occurred!");
      }
    }
    setIsLoading(false);
  };

  const handleRefreshUser = async (id: string) => {
    setIsLoadingGenerate(true);
    try {
      await refreshTensorSocial({
        variables: {
          id: id,
        },
      });
      success("Refresh user successfully!");
    }  catch (error: any) {
      if (error.message) {
        err(error.message);
      } else {
        err("An error occurred!");
      }
    }
    setIsLoadingGenerate(false);
  };

  const handleChangeActive = async (id: string, active: boolean) => {
    try {
      await userUpdateOneAdmin({
        variables: {
          id: id,
          verified: active,
        },
      });
      success("Refresh user successfully!");
      refetch();
    }  catch (error: any) {
      if (error.message) {
        err(error.message);
      } else {
        err("An error occurred!");
      }
    }
  };

  const {
    data: userTypesData,
    loading: userTypeLoading,
    error: userTypeError,
    refetch,
  }: QueryResult<
    any,
    { skip: number; limit: number; search?: any; filter: any }
  > = useQuery(query("usersFindManyAdmin"), {
    variables: {
      skip: page * 10,
      limit,
      ...(Object.keys(filters).length ? { search: filters } : {}),
      filter: {
        type: "90b4abc2-11c4-11ed-861d-0242ac120002",
      },
    },
  });

  useEffect(() => {
    refetch({
      skip: (page - 1) * limit,
      limit,
      ...(Object.keys(filters).length ? { search: filters } : {}),
    });
  }, [filters, page, limit, refetch]);

  if (userTypeLoading) {
    return <div>Loading...</div>;
  }

  if (userTypeError || !userTypesData) {
    if (userTypeError?.message === "You should be Admin, to have access to this action.") {
      return logout()
    }
    return <div>Error!</div>;
  }

  if (!userTypesData) {
    return <div>Loading...</div>;
  }

  const dataRows = userTypesData.usersFindManyAdmin.map((user: any) => {
    return {
      name: user.firstName + " " + user.lastName,
      email: user.email,
      intagram: user.socialNetwork?.url || "No Instagram",
      tenant: user.tenantsObj,
      action: user.id,
      image: user?.profilePicture?.url,
      id: user._id,
      verified: user.verified,
    };
  });

  return (
    <Talent
      page={page}
      limit={limit}
      columns={columns}
      data={dataRows}
      setPage={setPage}
      refetch={refetch}
      showModal={showModal}
      setShowModal={setShowModal}
      registerInput={rest.registerInput}
      submitLoading={loading}
      onSubmit={rest.handleSubmitForm}
      industryData={industryData?.industryFindMany}
      industrySegmentData={industrySegmentData?.industrySegmentFindMany}
      onChangeMultiSelect={rest.onChangeMultiSelect}
    />
  );
};
