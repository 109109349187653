import React, {useEffect} from 'react';

interface EmbedPreviewI {
	embed: string
}

export default function EmbedPreview({embed}:EmbedPreviewI) {
	useEffect(():void=>{
		if(embed){
			// @ts-ignore
			if(window['twttr']) {
				// @ts-ignore
				window['twttr']?.widgets?.load();
			}
		}
	},[embed]);

	return (
		<div className="f-urbanist lh-220 f-1rem body neutral-900 br w-100 aspect-16-9 neutral-800 iframe" dangerouslySetInnerHTML={{__html: embed || ""}}/>
	)
}
