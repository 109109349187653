import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  width: 100%;
  padding: 20px 30px 0 0;
`;

export const Button = styled.button<{ page?: any; width?: string }>`
  width: ${(props) => (props.width ? props.width : "36px")};
  height: 30px;
  background: ${(props) => (props.page === 1 ? "#333333" : "#FF5656")};
  box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.05);
  border-radius: 4px;
  border: none;
  cursor: pointer;
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
  margin: 15px 0;
  padding: 0px;
  text-align: center;
`;

export const ContainerButton = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const WrapperButton = styled.div`
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  alignitems: center;
  margin-top: 120px;
  height: 100%;
`;

export const WrapperForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  padding: 1rem;
`;

export const Subtitle = styled.h2<{ color?: any }>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: ${(props) => props.color || "#333333"};
`;

export const WrapperInput = styled.div`
  display: flex;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  width: 135px;
  font-size: 1.2rem;
  color: ${(props: any) => props.color};
`;

export const FilterTagsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3px;
`;

export const Photo = styled.img<{size?: any}>`
  width: ${(props) => (props.size || "30px")};
  height: ${(props) => (props.size || "30px")};
  border-radius: 50%;
  margin-right: 10px;
`;

export const ContainerItemps = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  margin-bottom: 3px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  /* width: 100%; */
  padding: 10px;
  border-radius: 5px;
  margin: 0 auto;
  border: 1px solid #fff;
`;

export const ItemClose = styled.div`
  margin-left: 10px;
  cursor: pointer;
  color: red;
  font-size: 1.2rem;
`;
