import React, {useState} from 'react';
import {uploadPhoto} from '../../../../services/hotlists/hotlists.service';
import {inputFileContainerStyle} from '../../../../styles/classes';

interface FUI {
	fileUrl: string
	setFileId?: (id:number|string) => void
	setFileUrl?: (url:string) => void
	setFile?: (url:string, id:number|string) => void
	hideBox?:boolean
}

export default function FileUpload({setFileId, setFileUrl, fileUrl, setFile, hideBox}:FUI) {

	const [uploading, setUploading] = useState<boolean>(false);

	const fileChange = (e:any) => {
		const file = e.target.files[0];
		// if (file) {
		// 	const cover = URL.createObjectURL(file);
		// 	if(setFileUrl){
		// 		setFileUrl(cover);
		// 	}
		// }

		let formData = new FormData();
		formData.append('files', file);
		// formData.append('ref', 'user');
		// formData.append('refId', user.id);
		// formData.append('field', 'profilePhoto');
		// formData.append('source', 'users-permissions');
		setUploading(true);
		fileUpload(formData);

	}

	const fileUpload = async (formData:any) => {

		try {
			const res = await uploadPhoto(formData);
			if(setFile) {
				setFile(res?.data[0]?.url, res?.data[0]?.id);
			}
			setUploading(false);
		}
		catch (e){
			setUploading(false);
			console.log(e);
		}
	}


	return (
		<div>
			{!hideBox &&
				<div className="w-300px br neutral-600 aspect-16-9 mb">
					{fileUrl &&
						<img src={fileUrl} alt="preview" className="w-300px aspect-16-9 br obj-cover" />
					}
				</div>
			}
			<div className={inputFileContainerStyle}>
				Select File
				<input type="file" name="cover" placeholder="Cover" onChange={fileChange} />
			</div>

			<div className="f-small mt-half h-1rem">
				{uploading ? "Uploading..." : " "}
			</div>
		</div>

	)
}
