import React from 'react'
import {NavLink, Outlet} from 'react-router-dom';

export default function HotlistsIndex() {
	return (
		<div>
			<div className="flex justify-center">
				<div className="p-half br neutral-800 flex f-small f-lato">
					<NavLink to="/hotlists/articles" className={({isActive}) => isActive ? "neutral-900 t-white pver-half phor br-half block" : "pver-half phor t-neutral-300 block"}>
						Articles
					</NavLink>
					<NavLink to="/hotlists/tags" className={({isActive}) => isActive ? "neutral-900 t-white pver-half phor br-half block" : "pver-half phor t-neutral-300 block"}>
						Tags
					</NavLink>
					<NavLink to="/hotlists/home" className={({isActive}) => isActive ? "neutral-900 t-white pver-half phor br-half block" : "pver-half phor t-neutral-300 block"}>
						Hotlist Home
					</NavLink>
				</div>
			</div>

			<Outlet />
		</div>
	)
}