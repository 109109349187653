import React, {useEffect, useState} from 'react';
import InterestRender from './Interest.render';
import {Link} from 'react-router-dom';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../services/queries/queries';
import {InterestI} from '../../interfaces/general';
import {useParams} from 'react-router-dom';
import {buttonStyle} from '../../styles/classes';

interface FilterI {
	hidden?:boolean
}

export default function InterestLoad() {

	const {page} = useParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [interests, setInterests] = useState<InterestI[]>([]);
	const [q, setQ] = useState("");
	const [status, setStatus] = useState("visible");

	const [doQuery, { data }] = useLazyQuery(query("interestFindMany"));
	const [doSearch, { data:dataSearch }] = useLazyQuery(query("interestSearchMany"));

	//change page
	useEffect(()=>{
		setLoading(true);
	},[page]);

	useEffect(()=>{
		if(loading){
			let filter:FilterI = {}

			switch (status){
				case "visible":
					filter.hidden = false;
					break
				case "hidden":
					filter.hidden = true
					break;
			}

			doQuery({
				variables: {
					filter,
					sort: "LABEL_DESC",
					skip:page ? (Number(page) - 1) * 300 : 0 ,
					limit:300
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => setLoading(false)
			});
		}
	},[loading]);

	//Set results
	useEffect(()=> {
		if (data?.interestFindMany){
			const i:InterestI[] = data?.interestFindMany?.map(({title, tensorInterests, father, label, _id, hidden, identifier, order, picture}:InterestI):InterestI=>{
				return {title,tensorInterests,identifier,_id,father,label,hidden,order, picture}
			});
			setInterests(i);
		}
	},[data]);

	//Filter by status
	useEffect(()=> {
		setLoading(true);
	},[status]);

	//Search with query keywords
	useEffect(() => {
		if(q.length > 0){
			const delayDebounceFn = setTimeout(() => {
				//setLoading(true);
				doSearch({
					variables: {
						query: q
					},
					fetchPolicy: 'no-cache',
					onCompleted: (data:any):void => setLoading(false)
				});
			}, 300);
			return () => clearTimeout(delayDebounceFn);
		}
		else {
			setLoading(true);
		}

	}, [q]);

	//Set interests from query search
	useEffect(()=> {
		if (dataSearch?.interestSearchMany?.length > 0){
			setInterests(dataSearch?.interestSearchMany);
		}
	},[dataSearch]);

	return (
		<div>
			<div className="flex">

				<div className="flex align-center grows">
					<h5 className="f-urbanist f-700 mr">Interests</h5>
				</div>
				<div>
					<input type="text" placeholder="Search Interests" value={q} onChange={(e)=>setQ(e.target.value)} />
				</div>
				<div className="flex ml-2 align-center w-250px">
					<p className="mr-half">Status</p>
					<select value={status} onChange={(e)=>setStatus(e.target.value)}>
						<option value="">All</option>
						<option value="visible">Visible</option>
						<option value="hidden">Hidden</option>
					</select>
				</div>
			</div>

			{interests && !loading ?
				<div>
					<InterestRender interests={interests} setInterests={setInterests} />
					<div className="mver flex col-gap-half">
						{page && Number(page) > 1 &&
							<Link
								to={`/interests/${Number(page) - 1}`}
								className={buttonStyle}
							>
								{`<`}
							</Link>
						}
						<Link to={`/interests/${page ? Number(page)+1 : 2}`} className={buttonStyle}>{`>`}</Link>
					</div>
				</div>
				:
				<div>
					Loading...
				</div>
			}
		</div>
	)
}
