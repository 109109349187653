interface Color {
	name: string,
	hex: string
}
const colors: Array<Color> = [

];
type colorOpts = typeof colors[number]['name'];
type ColorMap = Map<colorOpts, string>;

const colorsArray: any[] = colors.map((color: Color): string[] => Object.values(color));
const color: ColorMap = new Map<colorOpts, string>(colorsArray);


// eslint-disable-next-line @typescript-eslint/typedef
let style = colors.map(
	({ name, hex }: any): string => {
		return (
			`.t-${name}, .hover\\:t-${name}:hover{ color: ${hex}; }
.${name}, .hover\\:${name}:hover{ background-color: ${hex}; fill: ${hex} }
.b-${name}, .hover\\:b-${name}:hover, .focus\\:b-${name}:focus{ border-color: ${hex}; stroke: ${hex}; }
.sh-4-${name}, .hover\\:sh-4-${name}:hover, .focus\\:sh-4-${name}:focus{box-shadow: 0px 0px 4px ${hex};}
.sh-10-${name}, .hover\\:sh-10-${name}:hover, .focus\\:sh-10-${name}:focus{filter: drop-shadow(0px 0px 10px ${hex});}
.sh-20-${name}, .hover\\:sh-20-${name}:hover, .focus\\:sh-20-${name}:focus{filter: drop-shadow(0px 0px 20px ${hex});}
@media (max-width: 640px) {
.mobile\\:${name} { background-color: ${hex}}
.mobile\\:b-${name} { border-color: ${hex}; stroke: ${hex};}
.mobile\\:sh-10-${name}{filter: drop-shadow(0px 0px 10px ${hex});}
}
@media (max-width: 1024px) {
.tablet\\:${name} { background-color: ${hex}}
.tablet\\:b-${name} { border-color: ${hex}; stroke: ${hex};}
.tablet\\:sh-10-${name}{filter: drop-shadow(0px 0px 10px ${hex});}
}`
		);
	}
).join(' \n\n');


// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function addcss(css: string) {
	// eslint-disable-next-line @typescript-eslint/typedef
	let head = document.getElementsByTagName('head')[0];
	// eslint-disable-next-line @typescript-eslint/typedef
	let s = document.createElement('style');
	s.setAttribute('type', 'text/css');
	// eslint-disable-next-line no-mixed-spaces-and-tabs
	// the world
	s.appendChild(document.createTextNode(css));

	head.appendChild(s);
}

addcss(style);

export { color };