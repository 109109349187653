import React from 'react'
import {Table} from '../styles';
import {Iaudience} from '../types';

interface AudienceProps {
	data: any
	title?: string
	hasAffinity?:boolean
}

export default function AudienceTable({data, title, hasAffinity=false}:AudienceProps) {
	return(
		<div>
			{/*<h3 className="uppercase">{title}</h3>*/}
			<Table>
				<thead>
					<tr style={{fontWeight: 'bold'}}>
						<td>
							NAME
						</td>
						<td>
							WEIGHT
						</td>
						{hasAffinity &&
							<td>
								INTEREST
							</td>
						}
					</tr>
				</thead>
				<tbody>
				{data.map(({affinity, name, weight, interest}:Iaudience)=>{
					return(
						<tr key={`${name}-brand`}>
							<td>{name}</td>
							<td>{String(weight)}</td>
							{hasAffinity &&
								<td>
									{interest.map((inte:String, index)=>{
										return `${inte}${index < interest.length-1 ? ' | ' : ''}`
									})}
								</td>
							}
						</tr>
					)
				})}
				</tbody>
			</Table>
		</div>
	)
}