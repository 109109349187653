import React, {useState} from 'react'
import { LogoOpus } from "assets";
import {loginUser} from '../../services/auth/auth.service';
import {useNavigate} from 'react-router-dom';
import {buttonStyle} from '../../styles/classes';

export default function Login(){

	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");

	const onSubmit = (e:any) => {
		e.preventDefault();
		const data = new FormData(e.target);

		doLogin(data);
	}

	const doLogin = async (data:any) => {
		setLoading(true);
		setError("");
		try{
			const r = await loginUser(data);
			if(r["token"]){
				return navigate("/hotlists/articles");
			}
			else{
				console.log("No token");
				setError("No valid session. Please try again");
				setLoading(false);
			}
		}
		catch (e:any){
			console.log("Error");
			console.log(e.message, e);
			setError(e?.response?.data || e?.message || "Error");
			setLoading(false);
		}

	}

	return (
		<div
				style={{
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexDirection: 'column',
			width: "100%",
			height: "100vh",
				}}
		>
				<img src={LogoOpus} alt="logo" />
				<h4 className="mver-2">Login</h4>
				<div
			style={{
					color: "#fff",
					maxWidth: "900px",
					width: "335px",
			}}
				>
			<form
					onSubmit={onSubmit}
					autoComplete="off"
					style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
					}}
			>
				<div className="mb">
					<label>Email</label>
					<input type="text" name="email" placeholder="Email"/>
				</div>
				<div className="mb">
					<label>Password</label>
					<input type="password" name="password" placeholder="Password"/>
				</div>
				<button className={buttonStyle} disabled={loading}>
					{loading ? "Loading..." : "Submit"}
				</button>
				{error &&
					<p className="t-neutral-300 mt f-12px">{error}</p>
				}

			</form>
				</div>
		</div>
	);
};
