import React from 'react';
import {Item, SelectProps} from 'interfaces';


import styled from 'styled-components';
import { Select } from 'components/Atoms/Select/Select';
import { Tags } from 'components/Atoms/Tags';

export interface DropDownRenderProps{
	selectProps:SelectProps
	selectedItems:Item[]
	handleClickTag:(item:Item)=>void
}

const DropDownStyle:any=styled(Select)`
	margin-bottom: 0.4rem;
`;

export default function DropDown({selectProps,selectedItems,handleClickTag}:DropDownRenderProps):JSX.Element{
	return <div>
		<DropDownStyle
			defaultValue={''}
			{...selectProps}
		/>
		{selectedItems.length>0&&<Tags
			onClickTag={handleClickTag}
			tags={selectedItems}
		/>}
	</div>
	;
}
