import { useMutation } from "@apollo/client";
import { ContainerItemps, Item, ItemClose } from "Pages/Talent/styles";
import { Button } from "components/Atoms/Button/Button";
import { FormButton } from "components/Atoms/FormButton";
import { Input } from "components/Atoms/Input/Input";
import Select from "components/Atoms/Select/Select.load";
import { SelectMulti } from "components/Atoms/Select/SelectMulti";
import { TextBoxLoad } from "components/Atoms/TextBox/TextBox.load";
import { useToast } from "context/toastContext/toastContext";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UPDATE_USER_ONE_TALENT } from "services/queries/mutations";

export const ProfileForm = ({
  user,
  submitLoading,
  genders,
  registerInput,
  registerTextArea,
  registerSelect,
  onSubmit,
  industryData,
  onChangeMultiSelect,
  industrySegmentData,
  industryProfile,
  industrySegmentProfile,
}: any): JSX.Element => {
  const {id} = useParams();
  const [talentUpdateOneAdmin] = useMutation(UPDATE_USER_ONE_TALENT);
  const [selectedOptions, setSelectedOptions] = useState<any>(industryProfile ?? []);
  const [selectedOptionsSegment, setSelectedOptionsSegment] = useState<any>(industrySegmentProfile ?? []);

  const {success} = useToast();

  const handleRemoveSelected = async(optionToRemove: any) => {
    const newSelectedOptions = selectedOptions.filter((option: any) => option.value !== optionToRemove.value);
    setSelectedOptions(newSelectedOptions);
    try {
      await talentUpdateOneAdmin({
        variables: {
          user: id,
          industry: newSelectedOptions.map((i: any) => i._id),
        },
      });
      success("Delete industry successfully!");
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemoveSelectedSegment = async(optionToRemove: any) => {
    const newSelectedOptions = selectedOptionsSegment.filter((option: any) => option._id !== optionToRemove._id);
    setSelectedOptionsSegment(newSelectedOptions);
    try {
      await talentUpdateOneAdmin({
        variables: {
          user: id,
          industrySegment: newSelectedOptions.map((i: any) => i._id),
        },
      });
      success("Delete industry segment successfully!");
    } catch (err) {
      console.log(err);
    }
  }

  const handleChange = (selectedValue: string | number) => {
    const optionObject = industryData.find((data: any) => data._id === selectedValue);
    if (optionObject && !selectedOptions.some((opt: any) => opt.value === optionObject._id)) {
      setSelectedOptions([...selectedOptions, { value: optionObject._id, label: optionObject.label }]);
      onChangeMultiSelect(optionObject._id, "industry");
    }
  };

  const hanfleChangeSegmentIndustry = (selectedValue: string | number) => {
    const optionObject = industrySegmentData.find((data: any) => data._id === selectedValue);
    if (optionObject && !selectedOptionsSegment.some((opt: any) => opt.value === optionObject._id)) {
      setSelectedOptionsSegment([...selectedOptionsSegment, { value: optionObject._id, label: optionObject.label }]);
      onChangeMultiSelect(optionObject._id, "industrySegment");
    }
  };

  return (
    <div style={{ width: "100%", maxWidth: "600px", padding: "4px" }}>
      <form onSubmit={onSubmit}>

        <div style={{ display: "flex", gap: '15px' }}>
          <Input
            label={"Date of Birth"}
            placeholder={"mm/dd/yyyy"}
            {...registerInput('birthday')}
          />
          <Select
            label={"Gender"}
            options={genders}
            {...registerSelect('gender')}
          />
        </div>
        <SelectMulti
            label={"INDUSTRY"}
            options={industryData.map((data: any) => ({ value: data._id, label: data.label }))}
            onChange={(option: any) => handleChange(option)}
          />
          <ContainerItemps>
            {selectedOptions.map((option: any) => (
              <div key={option.value}>
                <Item>
                  {option.label} <ItemClose onClick={() => handleRemoveSelected(option)}>x</ItemClose>
                </Item>
              </div>
            ))}
          </ContainerItemps>

          <SelectMulti
            label={"INDUSTRY_SEGMENT"}
            options={industrySegmentData.map((data: any) => ({ value: data._id, label: data.label }))}
            onChange={(option: any) => hanfleChangeSegmentIndustry(option)}
          />
          <ContainerItemps>
            {selectedOptionsSegment.map((option: any) => (
              <div key={option.value}>
                <Item>
                  {option.label} <ItemClose onClick={() => handleRemoveSelectedSegment(option)}>x</ItemClose>
                </Item>
              </div>
            ))}
          </ContainerItemps>
        <TextBoxLoad
          label={"Bio"}
          {...registerTextArea('description')}
        />

        <div className="center">
          <FormButton loading={submitLoading} type="submit" label="Save"  />
        </div>
      </form>
    </div>
  );
};
