import React, {useEffect, useState} from 'react';
import AlertsRender from './Alerts.render';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../../services/queries/queries';
import {AlertsI} from '../../../interfaces/general';

export default function AlertsLoad(){

	const [loading, setLoading] = useState<boolean>(true);
	const [alerts, setAlerts] = useState<AlertsI[]>();
	const [active, setActive] = useState<boolean>(true)

	const [queryAlerts, { data }] = useLazyQuery(query('culturalAlertFindMany'));

	useEffect(()=>{
		if(loading){
			queryAlerts({
				variables: {
					skip:0,
					limit:500,
					sort: 'CREATEDAT_ASC',
					filter : {
						active
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					setLoading(false);
					setAlerts(data?.culturalAlertFindMany.reverse());
				}
			});
		}
	},[loading]);

	return (
		<div>
			<AlertsRender alerts={alerts} setLoading={setLoading} setActive={setActive} active={active} />
		</div>
	)
}
