import { post, get, put } from "utils/AxiosAdaparte";
import axios from 'axios';

const REACT_APP_REST_API: string | undefined = process.env.REACT_APP_REST_API;
export interface LoginData {
  email: string;
  password: string;
}

export const addHeaders = (value: any): any =>
  localStorage.getItem("token-opus")
    ? { ...value, "access-token": localStorage.getItem("token-opus") }
    : value;

export const authResponse = (response: Response): Error | any => {
  if (
    !response.ok &&
    response.headers.get("content-type")?.indexOf("application/json") === -1
  ) {
    return response.text().then((res: string): void => {
      throw new Error(res);
    });
  }
  return response.json().then((res: any): any => {
    if (res["access-token"]) localStorage.setItem("token-opus", res["access-token"]);
    return res;
  });
};

export const sendFile = async (data: any): Promise<any> => {
  const uri = `${REACT_APP_REST_API}/admin/csv-document`;
  const response = await put({ url: uri, data });
  return response;
};

export const updatePhoto = async (file: File, idUser: any): Promise<any> => {
  const formData:FormData=new FormData();
	formData.append('file',file);
	formData.append('user',idUser);
  const uri = `${REACT_APP_REST_API}/admin/update-profile-picture`;
  await put({ url: uri, data: formData });
};


export const uploadCollectionFile = async (data:any, endpoint:string) => {
	try {
		const save = await axios.put(
			`${REACT_APP_REST_API}/${endpoint}`,
			data,
			{
				headers : {
					"Content-Type": "multipart/form-data",
					"token": localStorage.getItem("token-opus")
				}
			}
		);
		console.log(save);
		return save;
	} catch (error) {
		console.log(error);
		throw error
	}
}


// https://test.opusintel.co/common/upload-videoTreatment
// https://test.opusintel.co/common/upload-videoTreatment