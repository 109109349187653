import React, {useEffect, useState} from 'react';
import {QueryTuple, useLazyQuery} from '@apollo/client';
import {query} from '../../services/queries/queries';
import {TalentI} from '../../interfaces/general';
import TalentPreview from '../../Pages/Hotlists/forms/fields/Talent.Preview';
import {tagErrorStyle, tagSuccessStyle} from '../../styles/classes';

interface SelectorI {
	selected: string[]
	setSelected : (s:string[]) => void
}

export default function TalentSelector({selected, setSelected}:SelectorI) {

	// eslint-disable-next-line @typescript-eslint/typedef
	const [queryTalents, {data,loading,error}]:QueryTuple<any, any> = useLazyQuery(query('usersFindManyAdmin'));

	const [q, setQ] = useState<string>("");
	const [talents, setTalents] = useState<TalentI[]>([]);

	useEffect(()=> {
		if(q.length > 3) {
			queryTalents({
				variables:{
					search : {
						name : q
					},
					limit: 5,
					skip: 0,
					sort: 'FIRSTNAME_ASC',
					filter: {
						type: "90b4abc2-11c4-11ed-861d-0242ac120002"
					}
				},
				// onCompleted: getMatchScores,
				fetchPolicy:'network-only'
			});
		}
	},[q]);

	useEffect(()=>{
		if(data){
			setTalents(data?.usersFindManyAdmin);
		}
	},[data]);

	const addTalent = (id:string) => {
		const s:string[] = [...selected];

		const f = s.find((e:string):boolean => e===id );
		if(!f){
			s.push(id);
			setSelected(s);
		}
		console.log("Talent added");
		setQ("");
		setTalents([]);
	}

	const removeTalent = (id:string) => {
		const s:string[] = [...selected];
		const index:number = s.findIndex(x => x === id);
		if (index !== -1) {
			s.splice(index, 1);
			setSelected(s);
		}
	}



	return (
		<div>
			<input type="text" placeholder="Search talent" name="query" value={q || ""} onChange={(e)=>setQ(e.target.value)} />
			{talents && talents?.length > 0 &&
				<div className="mt flex wrap">
					{talents && talents?.length > 0 && talents?.map(({firstName, profilePicture, lastName, _id, sync}: TalentI) => {
							return (
								<div key={`tal${_id}`} className="flex align-center f-bold mr cursor-pointer neutral-900 pver-half phor-2 br mb-quarter w-100" onClick={()=> _id ? addTalent(_id) : null}>
									<img src={profilePicture?.url} className="w-50px h-50px br-50 obj-cover mr-half" />
									<div className="mr">
										{firstName} {lastName}
									</div>
									{sync ?
										<div className={`${tagSuccessStyle} f-12px`}>Succesful</div>
										:
										<div className={`${tagErrorStyle} f-12px`}>Failed</div>
									}
								</div>
							)
						})
					}
				</div>
			}

			{selected && selected?.length > 0 &&
				<div className="mt flex wrap">
					{selected && selected?.length > 0 && selected?.map((id:string) => {
						return (
							<div key={`sel${id}`} className="flex align-center f-bold mr mb" onClick={()=> removeTalent(id)}>
								<TalentPreview id={id} />
							</div>
						)
					})
					}
				</div>
			}

		</div>
	)
}
