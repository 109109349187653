import React, {useState} from 'react';
import {TalentI, TenantI} from '../../interfaces/general';
import {buttonStyle, circleIconStyle, tagErrorStyle, tagSoftStyle, tagSuccessStyle} from '../../styles/classes';
import {Link} from 'react-router-dom';
import PencilIcon from '../../assets/Icons/Interface/PencilIcon';
import EllipsisIcon from '../../assets/Icons/Interface/EllipsisIcon';
import GraphIcon from '../../assets/Icons/Interface/GraphIcon';
import ResyncIcon from '../../assets/Icons/Interface/ResyncIcon';
import ReportIcon from '../../assets/Icons/Interface/ReportIcon';
import VerifyIcon from '../../assets/Icons/Interface/VerifyIcon';
import PopMessage from '../../components/Form/PopMessage';
import {useMutation} from '@apollo/client';
import {refreshTensorSocial, resyncTalent, userUpdateOneAdmin} from '../../services/queries/mutations';
import TalentCheckbox from './components/TalentCheckbox';
import TalentTenants from './components/TalentTenants';

interface TalentsRenderI {
	talents? : TalentI[]
	loading?: boolean
	setLoading?: (l:boolean)=>void
}

interface SelectedI {
	id?: string
	title?: string
}

export default function TalentsRender({talents, loading, setLoading}:TalentsRenderI) {
	const [selected, setSelected] = useState<SelectedI>({id: '', title: ''});
	const [pop, setPop] = useState<boolean>(false);
	const [popReport, setPopReport] = useState<boolean>(false);

	const [resync, { data:resyncResponse, loading:resyncLoading, error:resyncError }] = useMutation(resyncTalent);
	const [verify, { data, loading:verifyLoading, error }] = useMutation(userUpdateOneAdmin);
	const [report, { data:reportResponse, loading:reportLoading, error:reportError }] = useMutation(refreshTensorSocial);

	const startReport = () => {
		report({
			variables : {
				id:selected?.id
			},
			onCompleted: (data:any):void => {
				setPopReport(false);
				if(setLoading){
					setLoading(true)
				}
			}
		});
	};

	const startSync = () => {
		resync({
			variables : {
				id:selected?.id
			},
			onCompleted: (data:any):void => {
				setPop(false);
				if(setLoading){
					setLoading(true)
				}
			}
		});
	};

	const verifyUser = (id:string, verified:boolean) => {
		verify({
			variables : {
				id,
				verified: !verified
			},
			onCompleted: (data:any):void => {
				console.log('Loading');
				if(setLoading){
					setLoading(true)
				}
			}
		});
	}

	return (
		<div>
			<div>
				<table className="list-table">
					<thead>
						<tr>
							<td>
							</td>
							<td>
								Talent
							</td>

							<td>
								Instagram
							</td>

							<td>
								Email
							</td>

							<td>
								Visible in Tenants
							</td>

							<td>
								<div className="pr-2">
									Report From
								</div>

							</td>

							<td>
								Sync Status
							</td>

							<td>
								Actions
							</td>

						</tr>
					</thead>
					<tbody>
						{talents && talents.length > 0 && talents.map(({firstName, lastName, _id, profilePicture, tenantsObj, socialNetwork, sync, syncStatus, reportedAt, verified, createdAt, email}:TalentI):JSX.Element=>{
							return (
								<tr key={_id}>
									<td>
										<TalentCheckbox id={_id || ""} />
									</td>
									<td>
										<div className="flex align-center pr-2">
											{profilePicture?.url ?
												<img src={profilePicture.url} alt={firstName} className="w-50px aspect-1-1 obj-cover br" />
												:
												<div className="w-50px aspect-1-1 neutral-800 br" />
											}
											<div className="ml">
												<div className="flex align-center">
													<b className='mr-half'> {firstName} {lastName} </b> {verified && <VerifyIcon className='green' />}
												</div>
												<div className="flex f-10px t-neutral-300 no-wrap mt-quarter">
													{_id}
												</div>
											</div>
										</div>
									</td>
									<td>
										<p className="f-12px t-neutral-200 pr-2">
											{socialNetwork?.url && `@${socialNetwork?.url}`}
										</p>

									</td>
									<td>
										<p className="f-12px t-neutral-200 pr-2">
											{email}
										</p>

									</td>
									<td>
										<TalentTenants tenantsObj={tenantsObj} user={_id} />
									</td>

									<td>
										<div className="f-12px t-neutral-200">
											{reportedAt ?
												new Date(reportedAt).toLocaleString('en-US', {
													month: 'short',
													day: 'numeric',
													year: 'numeric'
												})
												:
												createdAt?
												new Date(createdAt).toLocaleString('en-US', {
													month: 'short',
													day: 'numeric',
													year: 'numeric'
												})
												:
												null
											}
										</div>
									</td>

									<td>
										<div>
											<div className="mb-half f-12px pr-2">
												{sync === null ?
													<div className="f-12px t-neutral-300">
														Not in sync
													</div>
													:
													sync
														?
														<div className={tagSuccessStyle}>Succesful</div>
														:
														<div className={tagErrorStyle}>Failed</div>
												}
											</div>
											<div className="f-10px t-neutral-300">
												{sync !== null && !sync && syncStatus && syncStatus?.length > 0 && syncStatus?.map((e:string, index)=>{
													return (
														<div key={e} className={`${!sync && syncStatus.length === index + 1 ? 'f-bold t-neutral-200' : ''}`}>
															{e}
														</div>
													)
												})}
												{/*{sync &&*/}
												{/*	<div className="f-bold t-neutral-200">*/}
												{/*		Completed*/}
												{/*	</div>*/}
												{/*}*/}
											</div>

										</div>


									</td>

									<td>

										<div className="flex align-center justify-end">
											{socialNetwork?.url &&
												<Link
													className={circleIconStyle}
													to={`/talent/graph/${_id}`}
													// onClick={()=>{
													// 	setCurrent(id);
													// 	setPop(true);
													// }}
													title='View Graph'
												>
													<GraphIcon className="neutral-200" />
												</Link>
											}

											{socialNetwork?.url &&
												<button
													className={`ml-half ${circleIconStyle}`}
													onClick={()=>{
														setSelected({id:_id, title: `${firstName} ${lastName}`});
														setPop(true);
													}}
													title='Resync'
												>
													<ResyncIcon className="neutral-200" />
												</button>
											}

											<Link
												to={`/talent/${_id}/profile`}
												className={`ml-half ${circleIconStyle}`}
												title='Edit Profile'
											>
												<PencilIcon className="neutral-200" />
											</Link>
											<div className="ml submenu-hover relative">
												<EllipsisIcon />
												<div className="f-12px t-neutral-200 neutral-800 phor-half br-half submenu w-100px z-1000 box-shadow">
													<a
														className="flex align-center left pver-half b-bottom b-neutral-600"
														onClick={()=>{
															setSelected({id:_id, title: `${firstName} ${lastName}`});
															setPopReport(true);
														}}
													>
														<div className="w-1rem shrinks-0">
															<ReportIcon size={16} />
														</div>
														<span className="ml-half">New Tensor Social Report</span>
													</a>

													<a
														className="flex align-center left pver-half"
														onClick={()=>{
															if(_id && typeof verified === 'boolean'){
																verifyUser(_id, verified);
															}
														}}
													>
														<div className="w-1rem shrinks-0">
															<VerifyIcon size={16} />
														</div>
														<span className="ml-half">{verified ? 'Unverify' : 'Verify'} Talent</span>
													</a>
												</div>
											</div>
										</div>

									</td>
								</tr>
							)
						})}
					</tbody>
				</table>

				{pop &&
					<PopMessage
						title={`Resync "${selected?.title}"`}
						setPop={setPop}
					>
						<div>
							<p className="t-neutral-300 mt center lh-130">
								This action will syncrhonize databases and recalculate authority scores for this user.
							</p>
							{resyncLoading ?
								<p className="center mt f-12px">
									'Resync in progress...'
								</p>
								:
								<div>
									<div className="center flex justify-center mver">
										<a className={buttonStyle} onClick={startSync}>
											Confirm
										</a>
									</div>
									<div className="center f-14px">
										<a onClick={() => setPop(false)}>
											Cancel
										</a>
									</div>
									{resyncError &&
										<p className="center t-orange mt f-12px">
											{resyncError?.message}
										</p>
									}
								</div>
							}

						</div>
					</PopMessage>
				}

				{popReport &&
					<PopMessage
						title={`Create a new report for "${selected?.title}"`}
						setPop={setPopReport}
					>
						<div>
							<p className="t-neutral-300 mt center lh-130">
								This action will create a new tensor Social Report and use a credit.
							</p>
							{reportLoading ?
								<p className="center mt f-12px">
									'Report creation in progress...'
								</p>
								:
								<div>
									<div className="center flex justify-center mver">
										<a className={buttonStyle} onClick={startReport}>
											Confirm
										</a>
									</div>
									<div className="center f-14px">
										<a onClick={() => setPopReport(false)}>
											Cancel
										</a>
									</div>
									{reportError &&
										<p className="center t-orange mt f-12px">
											{reportError?.message}
										</p>
									}
								</div>
							}

						</div>
					</PopMessage>
				}

			</div>
		</div>
	)
}