import { FC, useEffect, useState } from "react";

import { Search } from "components/Atoms/Search/Search";
import { useFilters } from "context/useFilterContext/useFilterContext";

import { Button as ButtonAtom } from "../../components/Atoms/Button/Button";

import {
  Button,
  Container,
  Subtitle,
  WrapperButton,
} from "./styles";
import { FormNewRegister } from "./Components/FormNewRegister";
import { FormUploadFileRegister } from "./Components/FormUploadFileRegister";
import { SearchFilterSvg } from "assets";
import { ModalFilterOptions } from "./Components/ModalFilterOptions";
import { TableDinamic } from "components/Organisms/TableDinamic/TableDinamic";

export const Talent: FC<any> = ({
  data,
  page,
  limit,
  errors,
  setPage,
  columns,
  refetch,
  onSubmit,
  addToast,
  showModal,
  setShowModal,
  registerInput,
  submitLoading,
  industryData,
  onChangeMultiSelect,
  industrySegmentData
}) => {
  const [showModalCSV, setShowModalCSV] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filteredData, setFilteredData] = useState(data.slice());

  const { filters, setFilters }: any = useFilters();

  const handleSearch = (searchTerm: string) => {
    if (searchTerm === "") {
      setFilteredData(data.slice());
    } else {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      setFilters({ instagramName: lowerCaseSearchTerm });
    }
  };

  const handleRefetch = (page: number) => {
    setPage(page);
    refetch({
      skip: page * limit,
      limit,
    } as any);
  };

  const handleFilter = (newFilters: any) => {
    setFilters(newFilters);
  };

  useEffect(() => {
    setFilteredData(data.slice());
  }, [data]);

  return (
    <div style={{ width: "100%", maxWidth: "90%", margin: "0 auto" }}>
      {showModal && (
        <FormNewRegister
          industryData={industryData}
          industrySegmentData={industrySegmentData}
          showModal={showModal}
          setShowModal={setShowModal}
          registerInput={registerInput}
          onChangeMultiSelect={onChangeMultiSelect}
          onSubmit={onSubmit}
          submitLoading={submitLoading}
          errors={errors}
        />
      )}
      {showModalCSV && (
        <FormUploadFileRegister
          showModalCSV={showModalCSV}
          setShowModalCSV={setShowModalCSV}
          addToast={addToast}
        />
      )}
      {showFilterOptions && (
        <ModalFilterOptions
          showModalFilterOptions={showFilterOptions}
          setShowModalFilterOptions={setShowFilterOptions}
          handleFilter={handleFilter}
        />
      )}
      <WrapperButton>
        <ButtonAtom
          label="Add User"
          width="114px"
          height="33px"
          borderRadius="7px"
          onClick={() => setShowModal(!showModal)}
        />
        <ButtonAtom
          label="Import CSV"
          width="114px"
          height="33px"
          border="1px solid #FF5656"
          borderRadius="7px"
          background="transparent"
          onClick={() => setShowModalCSV(!showModalCSV)}
        />
      </WrapperButton>
      <div style={{ display: "flex" }}>
        <Search
          handleSearch={handleSearch}
          filters={filters}
          setFilters={setFilters}
        />
        <SearchFilterSvg onClick={() => setShowFilterOptions(true)} />
      </div>

      <TableDinamic data={data} columns={columns} />

      <Container>
        <Button
          page={page}
          disabled={page === 1}
          onClick={() => handleRefetch(page - 1)}
        >
          {"<"}
        </Button>
        <div style={{ padding: "0 10px" }}>
          <Subtitle color="#FF5656">{page}</Subtitle>
        </div>
        <Button
          disabled={filteredData.length < limit}
          onClick={() => handleRefetch(page + 1)}
        >
          {">"}
        </Button>
      </Container>
    </div>
  );
};
