import React from 'react';
import {TagProps} from 'interfaces';
import {StyledTag} from 'styles/ui';
import { DeleteTagIcon } from 'assets/Icons/Alerts/DeleteTagIcon';



export default function TagRender({category,filled,selected,className,deletable,rounded,isClickable,onClickTag}:any):JSX.Element{

	return (
		<StyledTag
			isClickable={isClickable}
			className={className}
			selected={selected}
			filled={filled}
			rounded={rounded}
			onClick={():any=>onClickTag(category)}
		>
			<p>{category.label}</p>
			{deletable && <DeleteTagIcon/>}
		</StyledTag>
	);
}
