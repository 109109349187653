import styled from 'styled-components';

export const WrapperName = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  `;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  width: 100%;
  padding: 20px 30px 0 0;
`;

export const Button = styled.button<{ page?: any; width?: string }>`
  width: ${(props) => (props.width ? props.width : "36px")};
  height: 30px;
  background: ${(props) => (props.page === 0 ? "#333333" : "#FF5656")};
  box-shadow: 0px 0px 14px rgba(53, 64, 82, 0.05);
  border-radius: 4px;
  border: none;
  cursor: pointer;
`;

export const Subtitle = styled.h2<{ color?: any }>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: ${(props) => props.color || "#333333"};
`;