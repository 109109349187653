import React from "react";

function InterestsIcon(props: any) {
  const {fill, width, height} = props;
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 25}
    height={height ||25}
    fill="none"
    {...props}
  >
    <path
      stroke="#B8B8B8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9.385 19.95v-6a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2Zm0 0v-10a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v10m-6 0a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2m0 0v-14a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2Z"
    />
  </svg>
  );
}

const MemoSvgInterestsIcon = React.memo(InterestsIcon);
  export default MemoSvgInterestsIcon;
