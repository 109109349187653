import React from 'react';
import {NavLink, Outlet} from 'react-router-dom';

export default function OpportunitiesIndex() {
	return (
		<div>
			<div>
				<div className="flex justify-center">
					<div className="p-half br neutral-800 flex f-small f-lato">
						<NavLink to="/opportunities/alerts" className={({isActive}) => isActive ? "neutral-900 t-white pver-half phor br-half block" : "pver-half phor t-neutral-300 block"}>
							Opportunities
						</NavLink>
						<NavLink to="/opportunities/tactics" className={({isActive}) => isActive ? "neutral-900 t-white pver-half phor br-half block" : "pver-half phor t-neutral-300 block"}>
							Tactics
						</NavLink>
						<NavLink to="/opportunities/subtactics" className={({isActive}) => isActive ? "neutral-900 t-white pver-half phor br-half block" : "pver-half phor t-neutral-300 block"}>
							Subtactics
						</NavLink>
					</div>
				</div>

				<Outlet />
			</div>
		</div>
	)
}
