import { FormEvent, useEffect, useMemo, useState } from "react";
import {
  FormField,
  FormHook,
  ChangeEvent,
  ErrorsHook,
  Register,
  ListObjInput,
  fieldValue,
  listFieldValue,
  ValuesMap,
  defaultMap,
  defaultValue,
} from "./interfaces";
import { useErrors } from "./useErrors";

export const useForm = (
  fields: Array<FormField>,
  submitAction: (data: any) => void,
  deactivatable?: boolean
): FormHook => {
  const values: ValuesMap = new Map<string, fieldValue | listFieldValue>();

  const defaultValues: defaultMap = new Map<string, defaultValue>();
  fields.forEach((field: FormField): void => {
    defaultValues.set(field.name, field.default || "");
    values.set(field.name, field.default || "");
  });
  const initialValues: any = Object.fromEntries(values);
  const [loading, setLoading]: any = useState(false);
  const { errors, setFormErrors, setSubmitError }: any = useErrors();
  const [hasChanges, setHasChanges] = useState(false);

  const [data, setData]: any = useState(initialValues);

  useMemo((): void => {
    if (JSON.stringify(data) !== JSON.stringify(initialValues)) {
      setData((): any => Object.fromEntries(defaultValues));
    }
  }, [JSON.stringify(initialValues)]);

  useEffect((): void => {
    if (
      deactivatable &&
      JSON.stringify(data) !== JSON.stringify(Object.fromEntries(values))
    ) {
      setHasChanges(true);
      validateForm();
    }
    setSubmitError(null);
  }, [data]);

  const onChangeInput = ({
    target,
  }: ChangeEvent<HTMLInputElement>["target"]): void | null => {
    if (data[target.name] === undefined) {
      return null;
    }
    if (target.type === "checkbox") {
      return setData((prev: any): any => ({
        ...prev,
        [target.id]: target.checked,
      }));
    }
    setData((prev: any): any => ({ ...prev, [target.name]: target.value }));
  };

  const onChangeMultiSelect = (selectedOption: any, field: string) => {
    if (!selectedOption) {
      return;
    }
    setData((prev: any) => {
      const updatedField = [...(prev[field] || []), selectedOption];
      return { ...prev, [field]: updatedField };
    });
};


  const onChangeField = (
    value: string | number | boolean,
    name: string
  ): void => {
    setData((prev: any): any => ({ ...prev, [name || "badInput"]: value }));
  };

  const onChangeSelect = ({
    target,
  }: ChangeEvent<HTMLSelectElement>["target"]): void | null => {
    if (data[target.name] === undefined) {
      return null;
    }
    setData((prev: any): any => ({ ...prev, [target.name]: target.value }));
  };

  const onChangeSelect2 = ({
    target,
  }: ChangeEvent<HTMLSelectElement>["target"]): void | null => {
    if (data[target.name] === undefined || target.value === "") {
      return null;
    }
    setData((prev: any): any => ({
      ...prev,
      [target.name]: target.value,
      [target.name + "Label"]: target.options[target.selectedIndex].text,
    }));
  };

  const onChangeTextArea = ({
    target,
  }: ChangeEvent<HTMLTextAreaElement>["target"]): void | null => {
    if (data[target.name] === undefined) {
      return null;
    }
    setData((prev: any): any => ({ ...prev, [target.name]: target.value }));
  };

  const onChangeList = ({ name, value }: any): void => {
    setData((prev: any): any => ({ ...prev, [name]: value }));
  };

  const onBlur = (): void => {
    setFormErrors(fields, data);
  };

  const validateForm = (): boolean => {
    setFormErrors(fields, data);
    return !errors.size;
  };

  const handleSubmitForm = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    setSubmitError(null);
    setLoading(true);
    if (validateForm()) {
      try {
        await submitAction(data);
      } catch (e: any) {
        setSubmitError(e);
      }
    }
    setLoading(false);
  };

  const registerInput = (name: string): Register<HTMLInputElement> => ({
    name,
    value: data[name] || "",
    valid: !errors.get(name),
    onChange: onChangeInput,
    onBlur,
  });

  const registerSelect = (name: string): Register<HTMLSelectElement> => ({
    name,
    value: data[name],
    valid: !errors.get(name),
    onChange: onChangeSelect,
    onBlur: onBlur,
  });

  const registerSelect2 = (name: string): Register<HTMLSelectElement> => {
    return {
      name,
      value: data[name],
      valid: !errors.get(name),
      onChange: onChangeSelect2,
      onBlur: onBlur,
    };
  };

  const registerTextArea = (name: string): Register<HTMLTextAreaElement> => ({
    name,
    value: data[name],
    valid: !errors.get(name),
    onChange: onChangeTextArea,
    onBlur: onBlur,
  });

  const registerList = (name: string): ListObjInput => ({
    name,
    value: data[name],
    valid: !errors.get(name),
    onChange: onChangeList,
    onBlur: onBlur,
  });

  const resetForm = () => {
    setData(Object.fromEntries(defaultValues));
  };

  return {
    data,
    errors,
    loading,
    hasChanges,
    defaultValues,
    resetForm,
    handleSubmitForm,
    onChangeInput,
    registerSelect2,
    onChangeSelect,
    onChangeTextArea,
    onChangeList,
    onChangeField,
    onBlurInput: onBlur,
    registerInput,
    registerSelect,
    registerTextArea,
    registerList,
    onChangeMultiSelect,
  };
};
