import styled from 'styled-components';

export const Container = styled.main`
  padding: 40px 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  h1 {
    font-size: 14px;
  }
`;

export const LayoutStyle = styled.div`
  display: flex;
`;