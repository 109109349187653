import {atom} from 'recoil';

export const tenantAtom = atom<string[]>({
	key: 'tenantAtom', // unique ID (with respect to other atoms/selectors)
	default: [], // default value (aka initial value)
});

export const selectedTalentsAtom = atom<string[]>({
	key: 'selectedTalentsAtom', // unique ID (with respect to other atoms/selectors)
	default: [], // default value (aka initial value)
});