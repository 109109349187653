import styled from "styled-components";

import { CloseSvg, SearchSvg } from "assets";

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid ${({ theme }) => theme.gray_200};
`;

export const SearchInput = styled.input`
  padding: 2px;
  border-radius: 0.5rem 0 0 0.5rem;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 12px;
  color: ${({theme}) => theme.gray_200};
  outline: none;
  &:focus {
    border: none;
  }
  &:active {
    border: none;
  }
`;

export const SearchButton = styled(SearchSvg)`
  cursor: pointer;
  font-size: 1.5rem;
  /* Estilos adicionales */
`;

export const ClearButton = styled(CloseSvg)`
  cursor: pointer;
  font-size: 1.5rem;
  margin-left: -1.5rem;
  visibility: ${(props: any) => props.visibility};
`;
