import React, {useState} from 'react';
import {TalentI, TenantI} from '../../interfaces/general';
import {buttonStyle, circleIconStyle, tagErrorStyle, tagSoftStyle, tagSuccessStyle} from '../../styles/classes';
import {Link} from 'react-router-dom';
import PencilIcon from '../../assets/Icons/Interface/PencilIcon';
import EllipsisIcon from '../../assets/Icons/Interface/EllipsisIcon';
import GraphIcon from '../../assets/Icons/Interface/GraphIcon';
import ResyncIcon from '../../assets/Icons/Interface/ResyncIcon';
import ReportIcon from '../../assets/Icons/Interface/ReportIcon';
import VerifyIcon from '../../assets/Icons/Interface/VerifyIcon';
import PopMessage from '../../components/Form/PopMessage';
import {useMutation} from '@apollo/client';
import {resyncTalent, userUpdateOneAdmin} from '../../services/queries/mutations';
import TalentCheckbox from './components/TalentCheckbox';
import TalentTenants from './components/TalentTenants';

interface TalentsRenderI {
	talents? : TalentI[]
	loading?: boolean
	setLoading?: (l:boolean)=>void
}

interface SelectedI {
	id?: string
	title?: string
}

export default function AccountsRender({talents, loading, setLoading}:TalentsRenderI) {
	const [selected, setSelected] = useState<SelectedI>({id: '', title: ''});
	const [pop, setPop] = useState<boolean>(false);

	const [resync, { data:resyncResponse, loading:resyncLoading, error:resyncError }] = useMutation(resyncTalent);
	const [verify, { data, loading:verifyLoading, error }] = useMutation(userUpdateOneAdmin);

	const startSync = () => {
		resync({
			variables : {
				id:selected?.id
			},
			onCompleted: (data:any):void => {
				setPop(false);
				if(setLoading){
					setLoading(true)
				}
			}
		});
	};

	const verifyUser = (id:string, verified:boolean) => {
		verify({
			variables : {
				id,
				verified: !verified
			},
			onCompleted: (data:any):void => {
				console.log('Loading');
				if(setLoading){
					setLoading(true)
				}
			}
		});
	}

	return (
		<div>
			<div className="pb-4">
				<table className="list-table">
					<thead>
						<tr>
							<td>
							</td>
							<td>
								Talent
							</td>

							<td>
								Email
							</td>

							<td>
								Access to Tenants
							</td>


							<td>
								Actions
							</td>

						</tr>
					</thead>
					<tbody>
						{talents && talents.length > 0 && talents.map(({firstName, lastName, _id, profilePicture, tenantsObj, socialNetwork, sync, syncStatus, reportedAt, verified, email}:TalentI):JSX.Element=>{
							return (
								<tr key={_id}>
									<td>
										<TalentCheckbox id={_id || ""} />
									</td>
									<td>
										<div className="flex align-center pr-2">
											{profilePicture?.url ?
												<img src={profilePicture.url} alt={firstName} className="w-50px aspect-1-1 obj-cover br" />
												:
												<div className="w-50px aspect-1-1 neutral-800 br" />
											}
											<div className="ml">
												<div className="flex align-center">
													<b className='mr-half'> {firstName} {lastName} </b> {verified && <VerifyIcon className='green' />}
												</div>
												<div className="flex f-10px t-neutral-300 no-wrap mt-quarter">
													{_id}
												</div>
											</div>
										</div>
									</td>

									<td>
										<p className="f-12px t-neutral-200 pr-2">
											{email}
										</p>

									</td>
									<td>
										<TalentTenants tenantsObj={tenantsObj} user={_id} />
									</td>

									<td>

										<div className="flex align-center justify-end">
											<Link
												to={`/talent/${_id}/account`}
												className={`ml-half ${circleIconStyle}`}
												title='Edit Profile'
											>
												<PencilIcon className="neutral-200" />
											</Link>
											<div className="ml submenu-hover relative">
												<EllipsisIcon />
												<div className="f-12px t-neutral-200 neutral-800 phor-half br-half submenu w-100px z-1000 box-shadow">

													<a
														className="flex align-center left pver-half"
														onClick={()=>{
															if(_id && typeof verified === 'boolean'){
																verifyUser(_id, verified);
															}
														}}
													>
														<div className="w-1rem shrinks-0">
															<VerifyIcon size={16} />
														</div>
														<span className="ml-half">{verified ? 'Unverify' : 'Verify'} Talent</span>
													</a>
												</div>
											</div>
										</div>

									</td>
								</tr>
							)
						})}
					</tbody>
				</table>

			</div>
		</div>
	)
}