import React, {useEffect, useState} from 'react';
import AccountRender from './Account.render';
import {useParams} from 'react-router-dom';
import {UserEditI} from '../../../interfaces/general';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../../services/queries/queries';

export default function AccountLoad(){

	const {id} = useParams();
	const [loading, setLoading] = useState<boolean>(true);
	const [user, setUser] = useState<UserEditI>();

	const [findOneTalent, { data }] = useLazyQuery(query("talentFindOneAdmin"));

	useEffect(()=>{
		if(loading){
			findOneTalent({
				variables: {
					filter:{
						user: id
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					setUser(data?.talentFindOneAdmin);
					setLoading(false);
				}
			});
		}
	},[loading]);

	return (
		<AccountRender user={user} />
	)
}