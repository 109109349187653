import React, {useState} from 'react'
import {buttonStyle, circleIconStyle} from '../../../styles/classes';
import PencilIcon from '../../../assets/Icons/Interface/PencilIcon';
import TrashIcon from '../../../assets/Icons/Interface/TrashIcon';
import PopMessage from '../../../components/Form/PopMessage';

interface TagsRenderI {
	tags?: any
	setForm:(f:boolean)=>void
	setTagId:(t:string | number)=>void
	deleteTag:(t:string | number)=>void
}

interface SelectedTagI {
	title?: string
	id: number | string
}

export default function TagsRender({tags, setForm, setTagId, deleteTag}:TagsRenderI) {

	const [pop, setPop] = useState(false);
	const [selectedTag, setSelectedTag] = useState<SelectedTagI>();

	const startDelete = async () => {
		if(selectedTag?.id){
	  		await deleteTag(selectedTag?.id);
		}
		setPop(false);
	}

	return (
		<div>
			<div className="flex align-center">
				<h5 className="f-urbanist f-700 mr">Tags</h5>
				<button className={buttonStyle} onClick={()=>setForm(true)}> + New Tag</button>
			</div>

			<div className="grid4 col-gap-2 row-gap-2 pver-2">
				{tags && tags.length > 0 && tags.map(({attributes: {title, titleColor, cover, slug}, id}:any)=>{
					return (
						<div key={`${id}-${title}`}>
							<div className={`w-100 aspect-16-9 br neutral-800 relative p-1half border-box flex justify-start align-end t-${titleColor}`}>
								{cover?.data?.attributes?.url &&
									<img
										src={`${cover?.data?.attributes?.url}`}
										className="w-100 h-100 obj-cover absolute edges br"
									/>
								}
								<span className="f-urbanist f-1rem f-700 relative">{title}</span>
							</div>
							<div className="flex align-center mt-half">
								<div className="grows">
									#{slug}
								</div>
								<a
									className={`${circleIconStyle} mr-half`}
									onClick={()=>{
										setTagId(id);
										setForm(true);
									}}
								>
									<PencilIcon className="neutral-200" />
								</a>
								<a
									className={`${circleIconStyle}`}
									onClick={()=>{
										setPop(true);
										setSelectedTag({title, id})
									}}
								>
									<TrashIcon className="neutral-300" />
								</a>
							</div>

						</div>
					)
				})}
			</div>

			{pop &&
				<PopMessage
					title={`Are you sure to delete the tag "${selectedTag?.title}"`}
					setPop={setPop}
				>
					<div>
						<p className="t-neutral-300 mt center lh-130">
							This action cannot be undone and it will update all the articles with this tag
						</p>
						<div className="center flex justify-center mver">
							<a className={buttonStyle} onClick={startDelete}>
								Yes, Delete
							</a>
						</div>
						<div className="center f-14px">
							<a onClick={()=>setPop(false)}>
								Cancel
							</a>
						</div>

					</div>
				</PopMessage>
			}
		</div>
	);
}
