import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { mediaQuery } from "styles/mediaQueries";

export const LinkContainer = styled(Link)`
    text-decoration: none;
    background-color: ${({ theme }): any => theme.red_500};
    padding: 4px;
    border-radius: 14px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 100%;
`;

export const TabsWrapper: any = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-bottom: 1.5rem;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  ${mediaQuery("mobile")} {
    max-width: calc(100vw - 2rem);
  }
`;



export const LinkTab: any = styled(Link)<any>`
  font-weight: 500;
  line-height: 19px;
  font-size: 1rem;
  text-transform: capitalize;
  padding-bottom: 6px;
  ${({ $active }: any): string =>
    $active
      ? `border-bottom:solid 4px white;
		 color: white;
		`
      : `border-bottom:solid 4px black;
		color:gray;
		`}
`;

export const Content: any = styled.div`
  margin-top: 2rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: content;
  max-width: 900px;
  width: 100%;
  margin: auto;
  justify-self: center;
  > p {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    line-height: 32px;
    letter-spacing: 0.3px;
    margin-bottom: 0.4rem;
    color: #fff;
    background-color: #fff;
  }
  > form {
    width: 100%;
    max-width: 600px;
    align-items: center;
    > button {
      display: block;
      margin-right: auto;
      margin-left: auto;
    }
  }
`;


export const LinkNavigation = styled(Link)`
 font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  text-align: left;
  width: 100%;
  margin-bottom: 2rem;
  color: #fff;
  text-decoration: none;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;


export const WrapperName = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  `;

export const TextTitle = styled.div`
margin-top: 100px;
margin-bottom: 20px;
font-size: 2.4rem;
font-weight: 600;
color: ${({ theme }) => theme.white};
margin-right: 10px;
`;

export const Text = styled.div`
margin-bottom: 20px;
text-align: center;
font-size: 1.6rem;
font-weight: 400;
color: ${({ theme }) => theme.gray_100};
margin-right: 10px;
`;