import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {AlertsI} from '../../../interfaces/general';
import {buttonStyle} from '../../../styles/classes';
import {useMutation} from '@apollo/client';
import {culturalAlertUpdateOne} from '../../../services/queries/mutations';
import {slugify} from '../../../utils/misc';
import Boolean from '../../../components/Form/Boolean';
import Editor from '../../../components/Form/Editor';
import TalentSelector from '../../../components/Form/TalentSelector';
import TacticSelector from '../../../components/Form/TacticSelector';
import TenantSelector from '../../../components/Form/TenantSelector';
import FileUpload from '../../../components/Form/FileUpload';
import SubtacticSelector from '../../../components/Form/SubtacticSelector';
import AlertPictures from './fields/AlertPictures';
import FormSection from '../../../components/Form/FormSection';

interface AlertFormI {
	alert?: AlertsI
	setAlert : (a:AlertsI)=>void
}

const em = [1,2,3,4,5,6,7,8,9,10]

export default function AlertForm({alert, setAlert}:AlertFormI) {

	const {id} = useParams();
	const navigate = useNavigate();
	const [loadPictures, setLoadPictures] = useState<boolean>(true);
	const [error, setError] = useState<string>("");
	const [body, setBody] = useState<string>(alert?.description || "");
	const [deliverables, setDeliverables] = useState<string>(alert?.deliverables || "");
	const [productDescription, setProductDescription] = useState<string>(alert?.productDescription || "");

	const [cost, setCost] = useState({
		min: alert?.feeRange?.min || 0,
		max: alert?.feeRange?.max || 0
	});

	const [alertUpdate, { data:updateData, loading, error:err }] = useMutation(culturalAlertUpdateOne);

	const onSubmit = (e:any) => {
		e.preventDefault();

		if(!alert?.title) {
			return setError("Please add a Title for the opportunity")
		}
		if(!alert?.endDate) {
			return setError("Please add a Respond By Date")
		}
		if(!body) {
			return setError("Please add a Description for the opportunity")
		}
		if(!deliverables) {
			return setError("Please add Deliverables for the opportunity")
		}
		if(cost.min === 0 || cost.max === 0) {
			return setError("Please add Estimated Costs for the opportunity")
		}
		if(!alert?.earnedMedia) {
			return setError("Please define Earned Media Potential")
		}

		const record = {
			title: alert?.title,
			slug: slugify(alert?.title || ""),
			culturalType: slugify(alert?.title || ""),
			active: alert?.active,
			endDate: alert?.endDate,
			videoShootDate: alert?.videoShootDate,
			eventFrom: alert?.eventFrom,
			description: body,
			deliverables,
			earnedMedia: Number(alert?.earnedMedia) || 1,
			videoSongTitle: alert?.videoSongTitle,
			videoSongUrl: alert?.videoSongUrl,
			videoDirector: alert?.videoDirector,
			city: alert?.city,
			state: alert?.state,
			website: alert?.website,
			artist: alert?.artist,
			tactic: alert?.tactic,
			subtactic: alert?.subtactic,
			tenant: alert?.tenant,
			feeRange : cost,
			expectedAttendees : Number(alert?.expectedAttendees),
			eventLocation: alert?.eventLocation,
			availableSlots:Number(alert?.availableSlots),
			additionalFees: alert?.additionalFees,
			participationRequirements: alert?.participationRequirements,
			product: alert?.product,
			productDescription,
			productIntegrationGuidelines: alert?.productIntegrationGuidelines,
			paymentDetails: alert?.paymentDetails

		};
		console.log('Record', record);

		if(id){
			alertUpdate({
				variables : {
					record,
					id
				},
				onCompleted: (data:any):void => finishSave(),
				onError: (err:any):void => setError(err.message)
			});
		}
	}

	const finishSave = () => {
		navigate("/opportunities/alerts");
	}

	const textUpdate = (e:any) => {
		setAlert({...alert, [e.target.name]:e.target.value});
	}

	useEffect(()=>{
		console.log(alert);
	},[alert]);

	return (
		<div>
			<div>
				<div className="flex align-start">
					<h4 className="mb-2 f-urbanist grows">
						{id ? "Edit" : "Create"} Alert
					</h4>
				</div>

				<form
					onSubmit={onSubmit}
					onKeyPress={(e) => {
						if (e.key === "Enter" && e.target.type === "text") {
							e.preventDefault();
						}
					}}
				>

					<div className="grid2 col-gap-4">
						<div>
							<FormSection title="About the opportunity">
								<p className="mb">
									<label>Title  <span className="f-1rem"> * </span></label>
									<input
										type="text"
										name="title"
										placeholder="Title"
										value={alert?.title || ""}
										onChange={(e)=>textUpdate(e)}
									/>
								</p>

								<div className="mb">
									<label>
										{alert?.active ? "Published" : "Draft"}
									</label>
									<Boolean value={alert?.active ? alert.active : false} setValue={()=> setAlert({...alert, active: !alert?.active})} />
								</div>

								<div className="grid2 col-gap">
									<div className="mb">
										<label>
											Respond By  <span className="f-1rem"> * </span>
										</label>
										<input type="date" name="endDate" placeholder="Respond By Date" value={alert?.endDate?.substring(0,10) || ""} onChange={(e)=>textUpdate(e)} />
									</div>
								</div>

								<div className="mb" style={{color: "black"}}>
									<label>
										Description  <span className="f-1rem"> * </span>
									</label>
									<Editor data={body} setDescription={(d:string)=>setBody(d)} />
								</div>

								<div className="mb" style={{color: "black"}}>
									<label>
										Deliverables  <span className="f-1rem"> * </span>
									</label>
									<Editor data={deliverables} setDescription={(d:string)=>setDeliverables(d)} />
								</div>

								<div className="mb">
									<label>
										Estimated Cost <span className="f-1rem"> * </span>
									</label>
									<div className="grid2 col-gap">
										<input
											type="number"
											name="min"
											placeholder="Min"
											value={cost.min}
											onChange={(e)=>setCost({...cost, [e.target.name]:Number([e.target.value])})}
										/>
										<input
											type="number"
											name="max"
											placeholder="Max"
											value={cost.max}
											onChange={(e)=>setCost({...cost, [e.target.name]:Number([e.target.value])})}
										/>
									</div>
								</div>

								<div className="mb">
									<label>Earned Media Potential <span className="f-1rem"> * </span></label>
									<div className="flex">
										{em.map((v:number)=>{
											return (
												<div
													className={`pver phor-075rem br-quarter flex align-center mr-quarter cursor-pointer justify-center ${alert?.earnedMedia === v ? "blue" : "neutral-700"}`}
													onClick={()=>setAlert({...alert, earnedMedia : v})}
												>
													{v}
												</div>
											)
										})}
									</div>
								</div>



							</FormSection>


							<FormSection title="Song / Video">

								<div className="mb">
									<label>
										Deck
									</label>
									{id ?
										<FileUpload
											fileUrl={alert?.deck && alert?.deck?.length > 0 && alert?.deck[alert?.deck?.length-1]?.url || ""}
											endpoint={"common/upload-deck"}
											id={id}
											hidePreview={true}
											showName={true}
										/>
										:
										<p className="f-14px t-neutral-200 pver-half">
											(*Please save the entry first before uploading files)
										</p>
									}
								</div>

								<p className="mb">
									<label>Song Title</label>
									<input
										type="text"
										name="videoSongTitle"
										placeholder="Song Title"
										value={alert?.videoSongTitle || ""}
										onChange={(e)=>textUpdate(e)}
									/>
								</p>

								<p className="mb">
									<label>Song Url</label>
									<input
										type="text"
										name="videoSongUrl"
										placeholder="Song Url"
										value={alert?.videoSongUrl || ""}
										onChange={(e)=>textUpdate(e)}
									/>
								</p>

								<p className="mb">
									<label>Video Director</label>
									<input
										type="text"
										name="videoDirector"
										placeholder="Video Director"
										value={alert?.videoDirector || ""}
										onChange={(e)=>textUpdate(e)}
									/>
								</p>

								<div className="mb">
									<label>
										Release Date
									</label>
									<input type="date" name="eventFrom" placeholder="Release Date" value={alert?.eventFrom?.substring(0,10) || ""} onChange={(e)=>textUpdate(e)} />
								</div>

								<div className="mb">
									<label>
										Video Shoot Date
									</label>
									<input type="date" name="videoShootDate" placeholder="Video Shoot Date" value={alert?.videoShootDate?.substring(0,10) || ""} onChange={(e)=>textUpdate(e)} />
								</div>

								<div className="mb">
									<label>
										Video Treatment
									</label>
									{id ?
										<FileUpload
											fileUrl={alert?.videoTreatment?.url || ""}
											endpoint={"common/upload-videoTreatment"}
											id={id}
											hidePreview={true}
											showName={true}
										/>
										:
										<p className="f-14px t-neutral-200 pver-half">
											(*Please save the entry first before uploading files)
										</p>
									}
								</div>
							</FormSection>

							<FormSection title="Location">
								<p className="mb">
									<label>Location</label>
									<input
										type="text"
										name="eventLocation"
										placeholder="Location"
										value={alert?.eventLocation || ""}
										onChange={(e)=>textUpdate(e)}
									/>
								</p>

								<p className="mb">
									<label>City</label>
									<input
										type="text"
										name="city"
										placeholder="City"
										value={alert?.city || ""}
										onChange={(e)=>textUpdate(e)}
									/>
								</p>

								<p className="mb">
									<label>State</label>
									<input
										type="text"
										name="state"
										placeholder="State"
										value={alert?.state || ""}
										onChange={(e)=>textUpdate(e)}
									/>
								</p>

								<p className="mb">
									<label>Expected Attendees</label>
									<input
										type="number"
										name="expectedAttendees"
										placeholder="Expected Attendees"
										value={alert?.expectedAttendees || 1}
										onChange={(e)=>setAlert({...alert, [e.target.name]:e.target.value})}
									/>
								</p>

								<p className="mb">
									<label>Website</label>
									<input
										type="text"
										name="website"
										placeholder="Website"
										value={alert?.website || ""}
										onChange={(e)=>textUpdate(e)}
									/>
								</p>
							</FormSection>

							<FormSection title="Publish Section">
								<div className="mb">
									<label>
										Tactics
									</label>
									<TacticSelector selected={alert?.tactic || []} setSelected={(t)=>setAlert({...alert, tactic: t})} />
								</div>

								<div className="mb">
									<label>
										Subtactics
									</label>
									<SubtacticSelector
										selected={alert?.subtactic || []}
										setSelected={(t)=>setAlert({...alert, subtactic: t})}
									/>
								</div>

								<div className="mb">
									<label>
										Tenants
									</label>
									<TenantSelector selected={alert?.tenant || []} setSelected={(t)=>setAlert({...alert, tenant: t})} />
								</div>
							</FormSection>



							<div className="pb-2">
								<button className={buttonStyle}>
									Save
								</button>
								{error && <p className="t-red mt f-12px">{error}</p>}
							</div>
						</div>

						<div>
							<FormSection title="Talent">
								<div className="mb">
									<label>
										Featured Talent <span className="f-1rem"> * </span>
									</label>
									<TalentSelector selected={alert?.artist || []} setSelected={(t)=>setAlert({...alert, artist: t})} />
								</div>
								<div className="mb">
									<label>
										Pictures
									</label>
									<AlertPictures
										id={id}
										loadPictures={loadPictures}
										setLoadPictures={setLoadPictures}
									/>

									{id ?
										<FileUpload
											fileUrl={""}
											endpoint={"common/cultural-alert-picture"}
											id={id}
											hidePreview={true}
											onComplete={() => setLoadPictures(true)}
										/>
										:
										<p className="f-14px t-neutral-200 pver-half">
											(*Please save the entry first before uploading files)
										</p>
									}
								</div>
							</FormSection>

							<FormSection title="Participation">

								<div>
									<div className="mb grows">
										<label>Available Slots</label>
										<input
											type="number"
											name="availableSlots"
											placeholder="Available Slots"
											value={alert?.availableSlots || 1}
											onChange={(e)=>setAlert({...alert, [e.target.name]:e.target.value})}
										/>
									</div>

								</div>

								<p className="mb">
									<label>Additional Fees</label>
									<input
										type="text"
										name="additionalFees"
										placeholder="Additional Fees"
										value={alert?.additionalFees || ""}
										onChange={(e)=>textUpdate(e)}
									/>
								</p>

								<p className="mb">
									<label>Payment Details</label>
									<input
										type="text"
										name="paymentDetails"
										placeholder="Payment Details"
										value={alert?.paymentDetails || ""}
										onChange={(e)=>textUpdate(e)}
									/>
								</p>
							</FormSection>

							<FormSection title="Product">
								<p className="mb">
									<label>Product Name</label>
									<input
										type="text"
										name="product"
										placeholder="Product Name"
										value={alert?.product || ""}
										onChange={(e)=>textUpdate(e)}
									/>
								</p>

								<div className="mb" style={{color: "black"}}>
									<label>
										Description
									</label>
									<Editor data={productDescription} setDescription={(d:string)=>setProductDescription(d)} />
								</div>

								<p className="mb">
									<label>Product Integration Guidelines</label>
									<input
										type="text"
										name="productIntegrationGuidelines"
										placeholder="Product Integration Guidelines"
										value={alert?.productIntegrationGuidelines || ""}
										onChange={(e)=>textUpdate(e)}
									/>
								</p>
							</FormSection>

						</div>
					</div>
				</form>

			</div>
		</div>
	)
}
