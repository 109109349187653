import React, {useState} from 'react';
import {TacticI} from '../../../interfaces/general';
import {buttonStyle, circleIconStyle} from '../../../styles/classes';
import PencilIcon from '../../../assets/Icons/Interface/PencilIcon';
import EllipsisIcon from '../../../assets/Icons/Interface/EllipsisIcon';
import PopMessage from '../../../components/Form/PopMessage';
import {useMutation} from '@apollo/client';
import {tacticDeleteOne} from '../../../services/queries/mutations';

interface TacticsRenderI {
	tactics?:TacticI[]
	tId?: string
	setTId?: (t:string) => void
	setForm?: (f:boolean) => void
	setLoading?: (f:boolean) => void
}

interface SelectedI{
	title?: string
	_id?: string
}

export default function TacticsRender({tactics, setForm, setTId, setLoading}:TacticsRenderI){

	const [pop,setPop] = useState<boolean>(false);
	const [selected, setSelected] = useState<SelectedI>();
	const [tacticDelete, { data, loading, error }] = useMutation(tacticDeleteOne);

	const startDelete = () => {
		tacticDelete({
			variables : {
				_id: selected?._id
			},
			onCompleted: (data:any):void => finishSave()
		});
	}

	const finishSave = () => {
	  setPop(false);
	  setSelected({title: "", _id: ""});
	  if(setLoading){
	  	setLoading(true);
	  }
	}

	return (
		<div>
			<div className="flex">
				<h5 className="f-urbanist f-700 mr">Tactics</h5>
				<a
					className={buttonStyle}
					onClick={()=>{
						if(setForm){
							setForm(true);
						}
						if(setTId){
							setTId("");
						}
					}}
				>
					+ New Tactic
				</a>
			</div>
			<table className="mt-2">
				<tbody>
				{tactics && tactics?.length > 0 && tactics.map(({_id, title, slug, active, description, cover}:TacticI)=>{
					return(
						<tr key={_id} className="b-neutral-700-b">
							<td className="pver">
								<div className="flex align-center">
									{cover && cover.length > 0 ?
										<img src={cover[cover.length-1]?.url} className="w-100px aspect-1-1 br-half obj-cover" />
										:
										<div className="w-100px br-half aspect-1-1 neutral-800" />
									}

									<div className="ml">
										<p className="bold">{title}</p>
										<span className="f-12px block">/{slug}</span>
										<span className="f-12px">{_id}</span>
									</div>
								</div>
							</td>
							<td className="pver">
								<div className="f-12px max-w-400px" dangerouslySetInnerHTML={{__html: description || ""}} />
							</td>
							<td className="pver">
								<div className="f-uppercase f-bold p-half b-neutral-300 inline-flex br-quarter mb-half">
									{active? "Published" : "Draft"}
								</div>
							</td>
							<td>
								<div className="flex align-center justify-end">
									<a
										className={`ml-half ${circleIconStyle}`}
										onClick={()=>{
											if(setTId && setForm && _id){
												setTId(_id);
												setForm(true);
											}
										}}
									>
										<PencilIcon className="neutral-200" />
									</a>
									<div className="ml submenu-hover relative">
										<EllipsisIcon />
										<div className="f-12px t-neutral-200 neutral-800 pver phor-half br-half submenu">
											<a onClick={()=>{
													setPop(true);
													setSelected({title, _id})
												}}
											>
												Delete
											</a>
										</div>
									</div>
								</div>
							</td>
						</tr>
					)
				})}
				</tbody>
			</table>

			{pop &&
				<PopMessage
					title={`Do you want to delete "${selected?.title}"`}
					setPop={setPop}
				>
					<div>
						<p className="t-neutral-300 mt center lh-130">
							This action cannot be undone.
						</p>
						<div className="center flex justify-center mver">
							<a className={buttonStyle} onClick={startDelete}>
								Yes, Delete
							</a>
						</div>
						<div className="center f-14px">
							<a onClick={()=>setPop(false)}>
								Cancel
							</a>
						</div>

					</div>
				</PopMessage>
			}
			
		</div>
	)
}
