import styled from 'styled-components';
import {color} from 'styles/colors';

interface StyledTagProps{
	selected:boolean
	filled:boolean
	rounded:boolean
}
interface StyledTagAttrs{
	color:string;
	backgroundColor:string;
	borderColor:string;
}

const selectedTheme:any={
	color:color.get('primary')
};
const unselectedTheme:any={
	color:color.get('neutral-300'),
	backgroundColor:'rgba(0,0,0,0)'
};

const FilledTheme:any={
	color:color.get('white'),
	borderColor:color.get('primary'),
	backgroundColor:color.get('primary')
};

export const StyledTag:any=styled.div.attrs(
	({selected,filled}:StyledTagProps):StyledTagAttrs=> {
		if (filled)
			return FilledTheme;
		return selected ? selectedTheme : unselectedTheme;
	}
)`
  	
  	display: flex;
  	align-items: center;
	width: fit-content;
  	height: 30px;
  	white-space: nowrap;
	cursor: pointer;
  	border-radius: ${({rounded}:StyledTagProps):string=>rounded? '15px' :'4px'};
	padding: 6px 10px;
	margin:0.25rem 0.2rem ;
	font-weight: 500;
	font-size: 0.75rem;
	color: ${({color}:StyledTagAttrs):string=>color};
	background-color:${({backgroundColor}:StyledTagAttrs):string=>backgroundColor};
	border: 1px solid ${({borderColor}:StyledTagAttrs):string=>borderColor};
  	>svg{
	  margin-left: 4px;
	}
`;
