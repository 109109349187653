import React from 'react';
import CloseIcon from '../../assets/Icons/Interface/CloseIcon';

interface PopMessageI{
	title:string
	setPop?:(p:boolean) => void
	children?: JSX.Element
}

export default function PopMessage({title, children, setPop}:PopMessageI){
	return (
		<div className="fixed edges overflow-y-scroll flex align-center justify-center">
			<div className="fixed edges black80" />
			<div className="neutral-1000 br max-w-400px p-3 relative">
				<p className="right">
					<a onClick={()=> setPop ? setPop(false) : null}>
						<CloseIcon />
					</a>
				</p>
				<h3 className="f-30px mr-2 center">
					{title}
				</h3>
				{children}
			</div>
		</div>
	)
}
