import { post, get } from "utils/AxiosAdaparte";
import axios from 'axios';

const REACT_APP_REST_API: string | undefined = process.env.REACT_APP_REST_API;
export interface LoginData {
  email: string;
  password: string;
}

export const addHeaders = (value: any): any =>
  localStorage.getItem("token")
    ? { ...value, "token": localStorage.getItem("token-opus") }
    : value;

export const authResponse = (response: Response): Error | any => {
  if (
    !response.ok &&
    response.headers.get("content-type")?.indexOf("application/json") === -1
  ) {
    return response.text().then((res: string): void => {
      throw new Error(res);
    });
  }
  return response.json().then((res: any): any => {
    if (res["access-token"]) localStorage.setItem("token-opus", res["token"]);
    return res;
  });
};

export const login = async (data: LoginData): Promise<any> => {
  const uri = `${REACT_APP_REST_API}/webapi/login`;
  const response = await post({ url: uri, data });
  localStorage.setItem("token-opus", response["token"]);
  return response as LoginData;
};

export const loginUser = async (data: LoginData): Promise<any> => {
  const uri = `${REACT_APP_REST_API}/webapi/login`;
  const response = await post({ url: uri, data });
  localStorage.setItem("token-opus", response["token"]);
  return response as LoginData;
};

export const dataOld = async (): Promise<any> => {
  const uri = `${REACT_APP_REST_API}/common/user-data`;
  const response = await get({ url: uri });
  return response as LoginData;
};

export const getUser = async () => {
  const t = localStorage.getItem("token-opus");
  try {
    const user = await axios.get(
        `${REACT_APP_REST_API}/common/user-data`,
        {
          headers : {
            'Content-Type': 'application/json',
            'token' : t
          }
        }
    );
    return user.data;
  } catch (error:any) {
    throw {error : error?.response}
  }
}

