import { FormButton } from "components/Atoms/FormButton";
import { Input } from "components/Atoms/Input/Input";
import React, { FC } from "react";

export const Account: FC<any> = ({ registerInput, onSubmit }) => {
  return (
    <div>
      <form
        onSubmit={onSubmit}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: "3rem",
        }}
      >
        <Input label={"First Name"} {...registerInput("firstName")} />
        <Input label={"Last Name"} {...registerInput("lastName")} />
        <Input label={"Email"} {...registerInput("email")} />
        <Input label={"Password"} {...registerInput("password")} />
        <FormButton label={"Save"} type="submit" />
      </form>
    </div>
  );
};
