import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;
`;

export const ModalContent = styled.div`
  background-color: #000;
  border-radius: 5px;
  width: 500px;
  border: 1px solid #191919;
  height: 100vh;
  border-radius: 33px 0px 0px 33px;
  padding: 20px;
  position: relative;

  /* Agregar desplazamiento interno */
  overflow-y: auto;

  /* Ocultar barra de desplazamiento para Chrome, Safari */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Ocultar barra de desplazamiento para Firefox */
  scrollbar-width: none;

  /* Ocultar barra de desplazamiento para IE, Edge */
  -ms-overflow-style: none;
`;
