import React from 'react';

interface BooleanI {
	value: boolean
	setValue: (b:boolean) => void
}

export default function Boolean({value, setValue}:BooleanI) {
	return (
		<div
			className={`b-neutral-600 w-60px h-30px p-quarter br-2 cursor-pointer flex ${value ? 'justify-end blue' : 'justify-start neutral-700'}`}
			onClick={()=>setValue(!value)}
		>
			<div className={`white w-30px br-50 aspect-1-1`} />
		</div>
	)
}
