import { ProfileForm } from "./ProfileForm";
import { useForm } from "hooks/useForm/useForm";
import { FormField, FormHook } from "hooks/useForm/interfaces";
import { FC } from "react";
import { query } from "services/queries/queries";
import { QueryResult, useQuery } from "@apollo/client";

interface ProfileFormLoadProps {
  birth: string;
  gender: string;
  bio: string;
  industry: string[];
  industrySegment: string[];
  handleUpdate: (data: any) => void;
}

export const ProfileFormLoad: FC<ProfileFormLoadProps> = ({ birth, gender, bio, handleUpdate, industry, industrySegment }) => {
  console.log('industry ==============', industry)
  console.log('industrySegment ---------------', industrySegment)
  const { data: gendersData }: QueryResult = useQuery(query('gendersFindMany'));
  const formFields: any[] = [
    { name: "birthday", required: false, default: birth || "" },
    { name: "gender", required: false, default: gender || "8c27e9f8-3518-11ed-a261-0242ac120002" },
    { name: "description", required: false, default: bio || "" },
    { name: "industry", required: false, default: industry.map((i: any) => i._id) || []  },
    { name: "industrySegment", required: false, default: industrySegment.map((i: any) => i._id) || [] },
  ];

  const handleSubmit = async (updateData: any): Promise<void> => {
    handleUpdate(updateData);
  };

  const {
    data: industryData,
  }: QueryResult<
    any,
    {}
  > = useQuery(query("industryFindMany"));

  const {
    data: industrySegmentData,
  }: QueryResult<
    any,
    { }
  > = useQuery(query("industrySegmentFindMany"));

  let gendersArray: any[] = gendersData?.gendersFindMany?.map((i: any): any => ({ ...i, id: i._id }));
  const { data, errors, loading, ...rest }: FormHook = useForm(
    formFields,
    handleSubmit
  );
  return (
    <ProfileForm
      user={[]}
      submitLoading={loading}
      errors={errors}
      genders={gendersArray || []}
      onSubmit={rest.handleSubmitForm}
      formData={data}
      registerInput={rest.registerInput}
      registerTextArea={rest.registerTextArea}
      registerSelect={rest.registerSelect}
      industryData={industryData?.industryFindMany || []}
      onChangeMultiSelect={rest.onChangeMultiSelect}
      industrySegmentData={industrySegmentData?.industrySegmentFindMany || []}
      industryProfile={industry}
      industrySegmentProfile={industrySegment}
    />
  );
};
