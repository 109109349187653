import React from "react";

function TalentIcon(props: any) {
  const {fill, width, height} = props;
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 20}
    height={height || 20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={fill || "#374957"}
        d="M10.003-.002C4.472-.002-.013 4.482-.013 10.014c0 5.531 4.485 10.015 10.016 10.015s10.015-4.484 10.015-10.015c0-5.532-4.484-10.016-10.015-10.016Zm8.283 9.12-.004-.003a8.279 8.279 0 0 1-4.396-1.833l2.558-2.559a8.292 8.292 0 0 1 1.842 4.395Zm-3.023-5.574-2.555 2.563a8.272 8.272 0 0 1-1.834-4.396c1.61.17 3.136.807 4.389 1.833ZM9.21 1.707a9.944 9.944 0 0 0 2.32 5.585L10 8.822l-5.27-5.27a8.297 8.297 0 0 1 4.48-1.845ZM3.551 4.73l-.003-.003.004.003L8.821 10 7.29 11.53a9.935 9.935 0 0 0-5.583-2.32A8.296 8.296 0 0 1 3.55 4.73Zm-1.837 6.152v-.004a8.272 8.272 0 0 1 4.396 1.834L3.552 15.27a8.293 8.293 0 0 1-1.838-4.388Zm3.02 5.566 2.554-2.558a8.272 8.272 0 0 1 1.834 4.396 8.292 8.292 0 0 1-4.389-1.838Zm6.055 1.845a9.934 9.934 0 0 0-2.32-5.584L10 11.18l5.27 5.27a8.29 8.29 0 0 1-4.48 1.844Zm5.658-3.023L11.179 10l1.53-1.53a9.944 9.944 0 0 0 5.583 2.32 8.297 8.297 0 0 1-1.845 4.48Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
  );
}

const MemoSvgTalentIcon = React.memo(TalentIcon);
  export default MemoSvgTalentIcon;
