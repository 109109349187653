import { FC } from "react";

import {
  Label,
  InputWrapper,
  RequiredText,
  OKIconWrapper,
  Input as InputStyle,
} from "./styles";
import { InputProps } from "./types";

export const Input: FC<InputProps> = ({
  Icon,
  value,
  label,
  required,
  className,
  type,
  ...props
}) => {
  return (
    <InputWrapper className={className}>
      <div>
        <div>
          <Label>
            {label}
            {required && <RequiredText>*</RequiredText>}
          </Label>
        </div>
        <InputStyle
          {...props}
          value={value}
          type={type}
        />
      </div>
      <OKIconWrapper value={value}>{Icon}</OKIconWrapper>
    </InputWrapper>
  );
};
