import React, { useState } from 'react'
import { IndustriesSegment } from './IndustriesSegment'
import { QueryResult, useMutation, useQuery } from '@apollo/client';
import { query } from 'services/queries/queries';
import { EllipsisSvg } from 'assets';
import { Popup } from 'components/Atoms/Popup/Popup';
import { DivLink } from 'components/Atoms/TableRow/styles';
import Divider from 'components/Atoms/Divider/Divider';
import { useForm } from 'hooks/useForm/useForm';
import { FormHook, FormField } from 'hooks/useForm/interfaces';
import { useToast } from 'context/toastContext/toastContext';
import { CREATE_INDUSTRY_SEGMENT_ONE, DELETE_INDUSTRY_SEGMENT_ONE, UPDATE_INDUSTRY_SEGMENT_ONE } from 'services/queries/mutations';


export const IndustrySegmentsLoad = () => {

    const [industrySegmentCreateOne] = useMutation(CREATE_INDUSTRY_SEGMENT_ONE);
    const [industrySegmentUpdateOne] = useMutation(UPDATE_INDUSTRY_SEGMENT_ONE);
    const [industrySegmentDeleteOne] = useMutation(DELETE_INDUSTRY_SEGMENT_ONE);

    const [showPopupIndex, setShowPopupIndex] = useState(-1);
    const [nameIndustry, setNameIndustry] = useState<any>(null);
    const [showModalCreate, setShowModalCreate] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [editingIndustrySegment, setEditingIndustrySegment] = useState<any>(null);

    const { error, success } = useToast();

    const handleInfoUpdate = (data: any) => {
        setEditingIndustrySegment(data);
        setShowModalCreate(true);
    };

    const columns = [
        { header: "Label", accessor: "label" },
        { header: "Identifier", accessor: "identifier" },
        {
            header: "Action",
            accessor: "action",
            Cell: (row: any) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}
                >
                    <div style={{ position: "relative" }}>
                        <EllipsisSvg
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setShowPopupIndex(row.id);
                            }}
                        />
                        {showPopupIndex === row.id && (
                            <Popup onClose={() => setShowPopupIndex(-1)}>
                                <DivLink onClick={() => handleInfoUpdate(row)}>
                                    <p>Edit Industry Segment</p>
                                </DivLink>
                                <Divider />
                                <DivLink onClick={() => {
                                    setShowConfirmModal(true)
                                    setNameIndustry(row.label)
                                }
                                }>
                                    <p>Deleted Industry Segment</p>
                                </DivLink>
                            </Popup>
                        )}
                    </div>
                </div >
            ),
        },
    ];

    const {
        data,
        loading: loadingQuery,
        error: errorQuery,
        refetch,
    }: QueryResult<
        any,
        { skip: number; limit: number; search?: any; filter: any }
    > = useQuery(query("industrySegmentFindMany"));

    const formFields: FormField[] = [
        {
            name: "label",
            required: true,
            default: editingIndustrySegment ? editingIndustrySegment?.label : "",
        },
        {
            name: "identifier",
            default: editingIndustrySegment ? editingIndustrySegment?.identifier : "",
        },
    ];

    const handleDelete = async () => {
        try {
           await industrySegmentDeleteOne({
                variables: {
                    id: showPopupIndex,
                },
            });
            success("Create Industry Segment successfully!");
            await refetch();
            setShowConfirmModal(false);
            setNameIndustry(null);
        } catch (err) {
            console.log(err);
            error("An error occurred with create!");
        }
    }

    const actionForm = async (data: any): Promise<void> => {
        if (showPopupIndex !== -1) {
            await editIndustrySegmentAction(data);
        } else {
            await registerIndustrySegmentAction(data);
        }

        resetForm();
        setEditingIndustrySegment(null);
    };

    const editIndustrySegmentAction = async (data: any): Promise<void> => {
        console.log('data', showPopupIndex)
        try {
            await industrySegmentUpdateOne({
                variables: {
                    id: showPopupIndex,
                    label: data.label,
                },
            });
            success("Create Industry Segment successfully!");
            refetch();
            setShowModalCreate(false);
        } catch (err) {
            console.log(err);
            error("An error occurred with create!");
        }
    };

    const registerIndustrySegmentAction = async (data: any): Promise<void> => {

        try {
            await industrySegmentCreateOne({
                variables: {
                    label: data.label,
                },
            });
            success("Create Industry Segment successfully!");
            refetch();
            setShowModalCreate(false);
        } catch (err) {
            console.log(err);
            error("An error occurred with create!");
        }
    };

    const {
        data: formData,
        errors,
        loading,
        resetForm,
        ...rest
    }: FormHook = useForm(formFields, actionForm);

    if (loadingQuery) return <p>Loading...</p>;
    if (errorQuery) return <p>Error</p>;


    const dataIndustrySegment = data.industrySegmentFindMany.map((item: any) => ({
        ...item,
        id: item._id,
    }));
    return (
        <IndustriesSegment
            data={dataIndustrySegment}
            columns={columns}
            setShowModalCreate={setShowModalCreate}
            showModalCreate={showModalCreate}
            setEditingIndustrySegment={setEditingIndustrySegment}
            editingIndustrySegment={editingIndustrySegment}
            onSubmit={rest.handleSubmitForm}
            submitLoading={loading}
            registerInput={rest.registerInput}
            errors={errors}
            showConfirmModal={showConfirmModal}
            setShowConfirmModal={setShowConfirmModal}
            handleDelete={handleDelete}
            nameIndustry={nameIndustry}
        />
    )
}
