import { color } from "styles/colors";
import styled, { css } from "styled-components";

export const inputWrapper:any=css`
  width: 100%;
  background-color:${({theme}) => theme.black} ;
  display:flex;
  align-items: center;

  :focus-within{
    border-color: ${({theme}) => theme.white};
  }

  border: 1px solid ${({theme}) => theme.white};
  border-radius: 8px;
  /* padding: 12px 24px 12px 16px ; */
  margin: 1rem 0;

  >div{
    display:flex;
    flex-direction:column;
  }
  >div:nth-child(1){
    flex-grow: 1;
    >div{
      display: flex;
      justify-content: space-between;
    }
  }
  >div:nth-child(2){
    align-items: center;
  }
`;

export const InputWrapper: any = styled.fieldset`
  ${inputWrapper}
`;

export const inputField:any=css`
  -moz-appearance: textfield;
  width: 100%;
  height: 50px;
  align-self: flex-start;
  border: none;
  color: white;
  font-size: 1.4rem;
  font-weight: 700;
  background-color: transparent;
  :focus-visible{
    border: none;
  }
  :focus{
    outline: none;
  }
  ::placeholder{

  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus{
    -webkit-text-fill-color: white;
    background-color: transparent ;
    transition: background-color 100000000000s ease-in-out 0s;
  }
`;

export const Input: any = styled.input`
  ${inputField}
`;

export const Label: any = styled.label`
  font-size: 1rem;
  font-weight: 800;
  color: ${({ theme }) => theme.white};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  height: 5px;
  padding-top: 5px;
`;

export const RequiredText: any = styled.p`
  font-size: 1rem;
  color: ${({theme}) => theme.red_500};
`;
