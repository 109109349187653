import React, {useState} from 'react';
import {IslandMarker, GraphMarkersDiv} from '../styles';
import '../../../assets/styles/index.css'

export default function GraphMarkers() {

	const [markers, setMarkers] = useState([
		{
			title: "Cities",
			color: '#8D6CEC'
		},
		{
			title: "Countries",
			color: '#FF5656'
		},
		{
			title: "Ethnicities",
			color: '#D5AF33'
		},
		{
			title: "Brands",
			color: '#1C9882'
		},
		{
			title: "Locales",
			color: '#DA7298'
		},
		{
			title: "Gender/Age",
			color: '#F2A541'
		},
		{
			title: "Interests",
			color: '#F08A4B'
		}
	])

	return (
		<GraphMarkersDiv>
			{markers.map(({color, title}:any) => {
				return (
					<div className="flex mr" key={title}>
						<IslandMarker color={color} className="mr-half" />
						{title}
					</div>
				)
			})}
		</GraphMarkersDiv>
	)

}