import React, { ChangeEvent, useState } from "react";
import { TextBox } from "./TextBox";

export const TextBoxLoad = ({
  maxLength = 190,
  onChange,
  ...props
}: any): JSX.Element => {
  const [currentLength, setCurrentLength] = useState(0);
  const onChangeTextBox = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    let length: number = event.target.value.length;
    setCurrentLength(length);
    onChange(event);
  };

  return (
    <TextBox
      {...props}
      maxLength={maxLength}
      currentLength={currentLength}
      onChange={onChangeTextBox}
    />
  );
};
