import React, { FC } from "react";

import { FormField, FormHook } from "hooks/useForm/interfaces";
import { useForm } from "hooks/useForm/useForm";
import { FamilyForm } from "./FamilyForm";
import { query } from "services/queries/queries";
import { QueryResult, useQuery } from "@apollo/client";
import { UpdateTalentData } from "interfaces";

const booleanByString: any = { yes: true, no: false, default: null };
const getBoolean = (label: string): boolean | null => {
  return booleanByString[label || "default"];
};

export const FamilyFormLoad: FC<any> = ({
  relationship,
  kids,
  pets,
  handleUpdate,
}) => {
  const { data: relationShipData }: QueryResult = useQuery(
    query("relationshipStatusFindMany")
  );
  const formFields: FormField[] = [
    {
      name: "relationshipStatus",
      required: false,
      default: relationship || "",
    },
    { name: "kids", required: false, default: kids ? "yes" : "no" },
    { name: "pets", required: false, default: pets ? "yes" : "no" },
  ];

  const handleSubmit = async (formData: any): Promise<void> => {
    let updateData: UpdateTalentData = {
      relationshipStatus: formData.relationshipStatus,
    };
    if (formData.kids) {
      updateData.hasChildren = getBoolean(formData.kids) as any;
    }
    if (formData.pets) {
      updateData.hasPets = getBoolean(formData.pets) as any;
    }
    handleUpdate(updateData);
  };

  const { errors, loading, ...rest }: FormHook = useForm(
    formFields,
    handleSubmit
  );
  let relationShipStatus: any[] =
    relationShipData?.relationshipStatusFindMany.map((i: any): any => ({
      ...i,
      id: i._id,
    })) || [];

  return (
    <FamilyForm
      relationshipOptions={relationShipStatus}
      submitLoading={loading}
      errors={errors}
      registerInput={rest.registerInput}
      registerSelect={rest.registerSelect}
      registerList={rest.registerList}
      onSubmit={rest.handleSubmitForm}
    />
  );
};
