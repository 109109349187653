const breakpoints:any={
	mobile:'640px',
	tablet:'1024px',
	laptop:'1600px'
};

export type MediaQuery='mobile'|'tablet'|'laptop';

export const queryByMedia:any={
	mobile:`@media (max-width: ${breakpoints.mobile})`,
	tablet:`@media (max-width: ${breakpoints.tablet})`,
	laptop:`@media (max-width: ${breakpoints.laptop})`
};

export const mediaQuery =(media:MediaQuery):string=>queryByMedia[media];
