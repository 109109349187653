// Importa gql de @apollo/client
import { gql } from "@apollo/client";

// Define las mutaciones como constantes
export const LINK_TENSOR_SOCIAL = gql`
  mutation LinkTensorSocial(
    $username: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $tenant: String!
    $industry: [String]
    $industrySegment: [String]
  ) {
    linkTensorSocial(
      username: $username
      firstName: $firstName
      lastName: $lastName
      email: $email
      tenant: $tenant
      industry: $industry
      industrySegment: $industrySegment
    )
  }
`;

export const RESYNC_USER = gql`
  mutation ResyncTalent($id: String!) {
    resyncTalent(_id: $id)
  }
`;

export const REFRESH_TENSO_SOCIAL = gql`
  mutation RefreshTensorSocial($id: String!) {
    refreshTensorSocial(_id: $id)
  }
`;

export const UPDATE_USER_TALENT = gql`
  mutation UserUpdateOneAdmin(
    $firstName: String
    $lastName: String
    $email: String
    $id: String
    $password: String
  ) {
    userUpdateOneAdmin(
      firstName: $firstName
      lastName: $lastName
      email: $email
      _id: $id
      password: $password
    )
  }
`;

export const UPDATE_USER_ONE_TALENT = gql`
  mutation TalentUpdateOneAdmin(
    $user: String!
    $interest: [String]
    $country: String
    $description: String
    $phone: String
    $languages: [String]
    $website: String
    $gender: String
    $ethnicity: [String]
    $musicGenres: [String]
    $brands: String
    $hasChildren: Boolean
    $hasPets: Boolean
    $spirits: [String]
    $shoeSizes: String
    $clothingSizes: [String]
    $address: IAddressInput
    $relationshipStatus: String
    $birthday: String
    $tenant: [String]
    $industry: [String]
    $industrySegment: [String]
  ) {
    talentUpdateOneAdmin(
      user: $user
      interest: $interest
      country: $country
      description: $description
      phone: $phone
      languages: $languages
      website: $website
      gender: $gender
      ethnicity: $ethnicity
      musicGenres: $musicGenres
      brands: $brands
      hasChildren: $hasChildren
      hasPets: $hasPets
      spirits: $spirits
      shoeSizes: $shoeSizes
      clothingSizes: $clothingSizes
      address: $address
      relationshipStatus: $relationshipStatus
      birthday: $birthday
      tenant: $tenant
      industry: $industry
      industrySegment: $industrySegment
    )
  }
`;

export const ACTIVE_USER = gql`
  mutation UserUpdateOneAdmin($verified: Boolean, $id: String) {
    userUpdateOneAdmin(verified: $verified, _id: $id)
  }
`;

export const CREATE_TENANT = gql`
  mutation TenantCreateOne($record: CreateOnetenantInput!) {
    tenantCreateOne(record: $record) {
      recordId
      record {
        _id
        label
        identifier
        createdAt
        updatedAt
      }
      error {
        message
      }
    }
  }
`;

export const EDIT_TENANT = gql`
  mutation TenantUpdateMany(
    $record: UpdateOnetenantInput!
    $filter: FilterUpdateOnetenantInput
  ) {
    tenantUpdateMany(record: $record, filter: $filter) {
      record {
        _id
        label
        identifier
        createdAt
        updatedAt
      }
    }
  }
`;

export const TENANT_ADD_USER_MANY = gql`
  mutation TenantAddUserMany(
    $tenantAddUserManyTenants2: [String]
    $tenantAddUserManyUser2: String
  ) {
    tenantAddUserMany(
      tenants: $tenantAddUserManyTenants2
      user: $tenantAddUserManyUser2
    )
  }
`;

export const TENANT_REMOVE_USER_MANY = gql`
  mutation TenantRemoveUserMany(
    $tenantAddUserManyTenants2: [String]
    $tenantAddUserManyUser2: String
  ) {
    tenantRemoveUserMany(
      tenants: $tenantAddUserManyTenants2
      user: $tenantAddUserManyUser2
    )
  }
`;

export const CREATE_INDUSTRY_ONE = gql`
  mutation IndustryCreateOne($label: String!) {
    industryCreateOne(label: $label) {
      _id
      label
      identifier
      updatedAt
      createdAt
    }
  }
`;

export const UPDATE_INDUSTRY_ONE = gql`
  mutation IndustryUpdateOne($id: String!, $label: String!) {
    industryUpdateOne(_id: $id, label: $label)
  }
`;

export const CREATE_INDUSTRY_SEGMENT_ONE = gql`
  mutation IndustrySegmentCreateOne($record: CreateOneindustrySegmentInput!) {
    industrySegmentCreateOne(record: $record) {
      recordId
      record {
        _id
        label
        identifier
        parent
        createdAt
        updatedAt
      }
      error {
        message
      }
    }
  }
`;

export const UPDATE_INDUSTRY_SEGMENT_ONE = gql`
  mutation IndustrySegmentUpdateOne(
    $record: UpdateOneindustrySegmentInput!
    $filter: FilterUpdateOneindustrySegmentInput
  ) {
    industrySegmentUpdateOne(record: $record, filter: $filter) {
      recordId
      record {
        _id
        label
        identifier
        parent
        createdAt
        updatedAt
      }
      error {
        message
      }
    }
  }
`;

export const DELETE_INDUSTRY_SEGMENT_ONE = gql`
  mutation IndustrySegmentDeleteOne($id: String!) {
    industrySegmentDeleteOne(_id: $id)
  }
`;

export const DELETE_INDUSTRY_ONE = gql`
  mutation IndustryDeleteOne($id: String!) {
    industryDeleteOne(_id: $id)
  }
`;

export const INTEREST_UPDATE_ONE = gql`
  mutation InterestUpdateOne(
    $record: UpdateOneinterestInput!
    $filter: FilterUpdateOneinterestInput
  ) {
    interestUpdateOne(record: $record, filter: $filter) {
      recordId
      record {
        _id
        label
        title
        identifier
        hidden
        order
        father
        tensorInterests
        picture {
          url
          type
        }
        updatedAt
        createdAt
      }
      error {
        message
      }
    }
  }
`;

export const interestUpdateOne = gql`
	mutation InterestUpdateOne(
		$record: UpdateOneinterestInput!
		$filter: FilterUpdateOneinterestInput
	) {
		interestUpdateOne(record: $record, filter: $filter) {
			recordId
			record {
				_id
				label
				title
				identifier
				hidden
				order
				father
				tensorInterests
				picture {
					url
					type
				}
				updatedAt
				createdAt
			}
			error {
				message
			}
		}
	}
`;

export const tacticUpdateOne = gql`
	mutation tacticUpdateOne(
		$record: UpdateOnetacticInput!
		$filter: FilterUpdateOnetacticInput
	) {
		tacticUpdateOne(record: $record, filter: $filter) {
			recordId
			record {
				_id
				slug
				active
				title
				description
			}
		}
	}
`;

export const tacticCreateOne = gql`
	mutation tacticCreateOne(
		$record: CreateOnetacticInput!
	) {
		tacticCreateOne(record: $record) {
			recordId
			record {
				_id
				slug
				active
				title
				description
			}
		}
	}
`;

export const culturalAlertDeleteOne = gql`
    mutation culturalAlertDeleteOne($_id: String!) {
        culturalAlertDeleteOne(_id: $_id)
    }
`;

export const tacticDeleteOne = gql`
    mutation tacticDeleteOne($_id: String!) {
        tacticDeleteOne(_id: $_id) {
            recordId
            record {
                _id
                active
                slug
                title
            }
		}
        
    }
`;

export const subtacticDeleteOne = gql`
    mutation subtacticDeleteOne($_id: String!) {
        subtacticDeleteOne(_id: $_id) {
            recordId
            record {
                _id
                active
                slug
                title
            }
        }

    }
`;

export const subtacticUpdateOne = gql`
	mutation subtacticUpdateOne(
		$record: UpdateOnesubtacticInput!
		$filter: FilterUpdateOnesubtacticInput
	) {
		subtacticUpdateOne(record: $record, filter: $filter) {
			recordId
			record {
				_id
				slug
				active
				title
				description
			}
		}
	}
`;

export const subtacticCreateOne = gql`
	mutation subtacticCreateOne(
		$record: CreateOnesubtacticInput!
	) {
		subtacticCreateOne(record: $record) {
			recordId
			record {
				_id
				slug
				active
				title
				description
			}
		}
	}
`;


export const culturalAlertUpdateOne = gql`
	mutation culturalAlertUpdateOne(
		$record: UpdateByIdculturalAlertInput!
		$id:String!
	) {
		culturalAlertUpdateOne(record: $record, _id: $id) {
			recordId
			record {
				_id
				user
				culturalType
				title
				videoSongTitle
				videoSongUrl
				videoDirector
				videoShootDate
				videoShootTime
				eventPaid
				eventCategory
				eventFrom
				eventTo
				eventPartnership
				endDate
				description
				interest
				additionalInfo
				eventLocation
				city
				state
				website
				artist
				slug
				tenant
				tags
				subtactic
				tactic
				earnedMedia
				active
				deliverables
				createdAt
				updatedAt
				photos {
					url
					type
				}
				feeRange {
					min
					max
					createdAt
					updatedAt
				}
				deck {
					url
					type
				}
                availableSlots
                additionalFees
                expectedAttendees
                participationRequirements
                product
                productDescription
                productIntegrationGuidelines
                paymentDetails
			}
		}
	}
`;

export const culturalAlertCreateOne = gql`
    mutation culturalAlertCreateOne($culturalType:String!,$title:String!,$active:Boolean) {
        culturalAlertCreateOne(
            culturalType: $culturalType
            title: $title
			active: $active
        ) {
            _id
            culturalType
            title
			active
            createdAt
            updatedAt
        }
    }
`;

export const resyncTalent = gql`
    mutation resyncTalent($id: String!) {
        resyncTalent(_id: $id)
    }
`;

export const tenantAddUsersOne = gql`
    mutation tenantAddUsersOne($tenant: String, $users: [String]) {
        tenantAddUsersOne(tenant: $tenant, users:$users)
    }
`;

export const userUpdateOneAdmin = gql`
    mutation userUpdateOneAdmin(
        $firstName: String
        $lastName: String
        $email: String
        $id: String
        $password: String
		$verified: Boolean
    ) {
        userUpdateOneAdmin(
            firstName: $firstName
            lastName: $lastName
            email: $email
            _id: $id
            password: $password
			verified: $verified
        )
    }
`;


export const talentMediaCreateOne = gql`
    mutation talentMediaCreateOne(
        $record: CreateOnetalentMediaInput!
    ) {
        talentMediaCreateOne(record: $record) {
            recordId
            record {
                _id
                user
                title
                link
            }
        }
    }
`;

export const talentMediaUpdateOne = gql`
    mutation talentMediaUpdateOne(
		$id: String!,
        $record: UpdateByIdtalentMediaInput!
    ) {
        talentMediaUpdateOne(record: $record, _id: $id) {
            recordId
            record {
                _id
                user
                title
                link
            }
        }
    }
`;

export const talentMediaDeleteOne = gql`
    mutation talentMediaDeleteOne($_id: String!) {
        talentMediaDeleteOne(_id: $_id) {
            recordId
            record {
                _id
                user
                title
                link
            }
        }

    }
`;


export const linkTensorSocial = gql`
    mutation linkTensorSocial(
        $username: String!
        $firstName: String!
        $lastName: String!
        $email: String!
        $tenant: String!
        $industry: [String]
        $industrySegment: [String]
    ) {
        linkTensorSocial(
            username: $username
            firstName: $firstName
            lastName: $lastName
            email: $email
            tenant: $tenant
            industry: $industry
            industrySegment: $industrySegment
        )
    }
`;


export const tenantRemoveUserMany = gql`
    mutation tenantRemoveUserMany(
        $tenants: [String]
        $user: String
    ) {
        tenantRemoveUserMany(
            tenants: $tenants
            user: $user
        )
    }
`;


export const tenantCreateOne = gql`
    mutation tenantCreateOne($record: CreateOnetenantInput!) {
        tenantCreateOne(record: $record) {
            recordId
            record {
                _id
                label
                identifier
                createdAt
                updatedAt
            }
            error {
                message
            }
        }
    }
`;

export const refreshTensorSocial = gql`
    mutation refreshTensorSocial($id: String!) {
        refreshTensorSocial(_id: $id)
    }
`;

export const userAddPost = gql`
    mutation userAddPost($user: String!) {
        userAddPost(user: $user)
    }
`;

export const upsertSocialNetwork = gql`
    mutation upsertSocialNetwork(
        $identifier: String!,
        $username: String!
        $user: String
    ) {
        upsertSocialNetwork(identifier: $identifier, username: $username, user: $user) {
            _id
            url
            identifier
            connected
            fail
            id
            refreshAt
            expiredAt
            user
            reportId
            reportExpiredAt
            updatedAt
            createdAt
        }
    }
`;


export const talentUpdateOneAdmin = gql`
    mutation talentUpdateOneAdmin(
        $user: String!
        $interest: [String]
        $country: String
        $description: String
        $phone: String
        $languages: [String]
        $website: String
        $gender: String
        $ethnicity: [String]
        $musicGenres: [String]
        $brands: String
        $hasChildren: Boolean
        $hasPets: Boolean
        $spirits: [String]
        $shoeSizes: String
        $clothingSizes: [String]
        $address: IAddressInput
        $relationshipStatus: String
        $birthday: String
        $tenant: [String]
        $industry: [String]
        $industrySegment: [String]
    ) {
        talentUpdateOneAdmin(
            user: $user
            interest: $interest
            country: $country
            description: $description
            phone: $phone
            languages: $languages
            website: $website
            gender: $gender
            ethnicity: $ethnicity
            musicGenres: $musicGenres
            brands: $brands
            hasChildren: $hasChildren
            hasPets: $hasPets
            spirits: $spirits
            shoeSizes: $shoeSizes
            clothingSizes: $clothingSizes
            address: $address
            relationshipStatus: $relationshipStatus
            birthday: $birthday
            tenant: $tenant
            industry: $industry
            industrySegment: $industrySegment
        )
    }
`;