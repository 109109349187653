import React from 'react';
import {SocialCardI} from '../../../interfaces/general';
import SocialForm from './Social.form';

interface SocialRenderI {
	socialCard?: SocialCardI
	ig?:string
	setLoading:(l:boolean)=>void
}

export default function SocialRender({socialCard, ig, setLoading}:SocialRenderI){
	return (
		<div>
			<div className="grid3 mt-3 neutral-800 br">
				<div className="p-2 b-right b-neutral-600">
					<h6>Instagram</h6>
					<p className="mver-half">{ig}</p>
					<h3>{socialCard?.instagram?.followers?.toLocaleString()}  </h3>
					<span className="f-12px">Followers</span>
				</div>
				<div className="p-2 b-right b-neutral-600">
					{socialCard?.tiktok?.followers ?
						<div>
							<h6>Tiktok</h6>
							<p className="mver-half">{socialCard?.tiktok?.username}</p>
							<h3>{socialCard?.tiktok?.followers?.toLocaleString()}</h3>
							<span className="f-12px">Followers</span>
						</div>
						:
						<SocialForm network={"tiktok"} setLoading={setLoading} />
					}


				</div>
				<div className="p-2">
					{socialCard?.youtube?.followers ?
						<div>
							<h6>Youtube</h6>
							<p className="mver-half">{socialCard?.youtube?.username}</p>
							<h3>{socialCard?.youtube?.followers?.toLocaleString()}</h3>
							<span className="f-12px">Subscribers</span>
						</div>
					:
						<SocialForm network={"youtube"} setLoading={setLoading} />
					}

				</div>
			</div>
		</div>
	)
}