import axios from "axios";

import { getItem } from "../services/local/localStorage.service";

const defaultHeaders = {
  "Content-Type": "application/json",
};

export const addHeaders = (value: any): any =>
  localStorage.getItem("token-opus")
    ? { ...value, "token": localStorage.getItem("token-opus") }
    : value;

export const get = async ({ url }: any) => {
  const response = await axios.get(url, {
    headers: {
      ...addHeaders({"Content-Type": "application/json"})
    },
  });
  return response.data;
};

export const post = async ({ data, url, headers = {} }: any) => {
  const response = await axios.post(url, data, {
    headers: {
      ...defaultHeaders,
      ...headers,
    },
  });
  return response.data;
};

export const put = async ({ data, url, headers = {} }: any) => {
  const accessToken = localStorage.getItem("token-opus");
  const response = await axios.put(url, data, {
    headers: {
      // ...defaultHeaders,
      ...headers,
      "access-token": accessToken,
    },
  });
  return response.data;
};

export const patch = async ({ data, url, headers = {} }: any) => {
  const accessToken = getItem("accessToken");
  const response = await axios.patch(url, data, {
    headers: {
      ...defaultHeaders,
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    },
  });
  return response.data;
};

export const remove = async ({ url, headers = {} }: any) => {
  const accessToken = getItem("accessToken");
  const response = await axios.delete(url, {
    headers: {
      ...defaultHeaders,
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    },
  });
  return response.data;
};
