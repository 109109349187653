import React, {useEffect, useState} from 'react';
import {getEntries} from '../../../services/hotlists/hotlists.service';
import ArticlesRender from './Articles.render';
import {HotlistFilterI} from '../../../interfaces/general';


export default function ArticlesLoad (){

	const [loading, setLoading] = useState<boolean>(true);
	const [hotlists, setHotlists] = useState([]);
	const [filter, setFilter] = useState<HotlistFilterI>();

	useEffect(():void => {
		if(loading) {
			let p:any = {}
			const d = new Date().toISOString();

			if(filter?.query){
				p["filters[title][$containsi]"] = filter.query;
			}
			if(filter?.status){
				switch (filter.status){
					case "Published":
						p["filters[published]"] = true;
						p["filters[publishDate][$lt]"] =  d;
						break;
					case "Future Publishing":
						p["filters[published]"] = true;
						p["filters[publishDate][$gt]"] =  d;
						break;
					case "Draft":
						p["filters[$or][0][published][$ne]"] = true;
						p["filters[$or][1][published][$null]"] = true;
						break;
					case "Archived":
						p["filters[archive]"] = true;
				}
			}
			if(filter?.tags) {
				p["filters[tags]"] = filter.tags;
			}
			loadData(p);
		}
	}, [loading]);

	useEffect(():void => {
		setLoading(true);
	}, [filter]);


	const loadData = async (p?:any) => {
		const h = await getEntries({
			endpoint: "hotlists",
			params: {
				"sort[0]":"createdAt:desc",
				"populate[0]" : "cover",
				"populate[1]" : "tags",
				"pagination[pageSize]" : 100,
				"filters[archive]": false,
				...p
			}
		});
		setHotlists(h.data);
		setLoading(false);
	}

	return (
		<div>
			<ArticlesRender hotlists={hotlists} filter={filter} setFilter={setFilter} setLoading={setLoading} />
		</div>
	);
}
