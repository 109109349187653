export const buttonStyle:string = "blue t-white b-0 phor pver-half br-quarter f-bold f-12px cursor-pointer";

export const tagStyle:string = "inline-block mr-half blue t-white br-half pver-half phor f-small f-urbanist f-600 cursor-pointer";

export const tagSoftStyle:string = "p-half br-half neutral-800 mr-quarter t-neutral-200 mb-quarter";

export const tagErrorStyle:string = "f-bold f-urbanist p-half br-half orange t-dark-orange mr-quarter mb-quarter inline-flex";

export const tagSuccessStyle:string = "f-bold f-urbanist p-half br-half green t-dark-green mr-quarter mb-quarter inline-flex";

export const circleIconStyle:string = "w-2rem aspect-1-1 br-50 neutral-1000 b b-neutral-700 flex align-center justify-center border-box";

export const circleIconStyleSmall:string = "w-1halfrem aspect-1-1 br-50 neutral-1000 b-neutral-700 flex align-center justify-center border-box";

export const inputFileContainerStyle:string = "input-file pver-half phor inline-flex f-12px align-center neutral-800 b-neutral-600 br-half b-neutral-600 b";
