import React, {useState} from 'react';
import {buttonStyle} from '../../../styles/classes';
import {useMutation} from '@apollo/client';
import {upsertSocialNetwork} from '../../../services/queries/mutations';
import {useParams} from 'react-router-dom';

interface SFI {
	network: "tiktok" | "youtube"
	setLoading:(l:boolean)=>void
}

export default function SocialForm({network, setLoading}:SFI) {

	const {id} = useParams();
	const [username, setUsername] = useState<string>("");

	const [upsertSocial, { data:createData, loading:createLoading, error:createError }] = useMutation(upsertSocialNetwork);

	const save = () => {
		upsertSocial({
			variables : {
				identifier: network,
				username,
				user: id
			},
			onCompleted: (data:any):void => finishSave()
		});
	}

	const finishSave = () => {
		setLoading(true);
	}

	return (
		<div>
			<h6 className="pb">Add {network} account</h6>
			<p className="mb">
				<label>
					Username
				</label>
				<input
					value={username || ""}
					onChange={(e)=>setUsername( e.target.value)}
					type="text"
					name="username"
					placeholder="Username"
				/>
			</p>
			<p><button className={buttonStyle} onClick={save}>Save</button> </p>
		</div>
	)
}