// context/useFilterContext/useFilterContext.tsx
import { createContext, useContext, useState } from "react";

interface FilterContext {
  filters: Record<string, any>;
  setFilters: (filters: Record<string, any>) => void;
}

const FilterContext = createContext<FilterContext>({
  filters: {},
  setFilters: () => {},
});

export const FilterProvider: React.FC<any> = ({ children }) => {
  const [filters, setFilters] = useState<Record<string, any>>({});

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilters = () => useContext(FilterContext);
