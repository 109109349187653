import React, {useEffect, useState} from 'react';
import {AlertsI, FileI, TagI} from '../../../../interfaces/general';
import {useLazyQuery, useMutation} from '@apollo/client';
import {query} from '../../../../services/queries/queries';
import {circleIconStyleSmall} from '../../../../styles/classes';
import CloseIcon from '../../../../assets/Icons/Interface/CloseIcon';
import {culturalAlertUpdateOne} from '../../../../services/queries/mutations';

interface AlertPicturesI {
	id?: string
	loadPictures: boolean
	setLoadPictures: (l:boolean)=>void
}

export default function AlertPictures({id, loadPictures, setLoadPictures}:AlertPicturesI) {

	const [alert, setAlert] = useState<AlertsI>();
	const [queryAlerts, { data }] = useLazyQuery(query('culturalAlertFindOne'));
	const [alertUpdate, { data:updateData, loading, error }] = useMutation(culturalAlertUpdateOne);


	useEffect(()=>{
		if(loadPictures && id){
			queryAlerts({
				variables: {
					filter: {
						_id: id
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					console.log("Pictures loaded");
					setLoadPictures(false);
					setAlert({...data?.culturalAlertFindOne});
				}
			});
		}
		if(!id){
			setLoadPictures(false);
		}
	},[loadPictures]);

	const remove = (index:number) => {
		console.log('Removing');
		if(alert && alert?.photos && alert?.photos?.length > 0){
			const p:FileI[] = alert?.photos.map(({url, type}:FileI):FileI=>{
				return({url, type})
			});
			p.splice(index, 1);
			setAlert({...alert, photos: p});

			if(id){
				alertUpdate({
					variables : {
						record: {
							photos: p
						},
						id
					}
				});
			}

		}
	}

	return (
		<div className="flex col-gap wrap">
			{alert && alert?.photos && alert?.photos?.map(({url, type}:FileI, index:number)=>{
				return (
					<div key={`${url}+${index}`} className="w-300px br neutral-600 aspect-16-9 mb relative">
						<img src={url} className="w-300px aspect-16-9 br obj-cover" />
						<div className={`${circleIconStyleSmall} absolute top-half right-half cursor-pointer`} onClick={()=>remove(index)}>
							<CloseIcon size={10} />
						</div>
					</div>
				)
			})}
		</div>
	)
}