import React, { FC, useState } from 'react'

import { Input } from "components/Atoms/Input/Input";
import { Button } from "components/Atoms/Button/Button";
import { Modal } from "components/Organisms/Modal/Modal";
import { Message } from "components/Atoms/Message/Message";

import { ContainerItemps, Item, ItemClose, Title } from '../styles'
import { SelectMulti } from 'components/Atoms/Select/SelectMulti';

export const FormNewRegister: FC<any> = ({
  onChangeMultiSelect,
  showModal,
  setShowModal,
  registerInput,
  onSubmit,
  submitLoading,
  errors,
  industryData,
  industrySegmentData
}) => {

  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [selectedOptionsSegment, setSelectedOptionsSegment] = useState<any>([]);

  const handleRemoveSelected = (optionToRemove: any) => {
    console.log("Intentando eliminar:", optionToRemove);
    setSelectedOptions((prev: any) => prev.filter((option: any) => option.value !== optionToRemove.value));
  };

  const handleRemoveSelectedSegment = (optionToRemove: any) => {
    console.log("Intentando eliminar:", optionToRemove);
    setSelectedOptionsSegment((prev: any) => prev.filter((option: any) => option.value !== optionToRemove.value));
  }

  const handleChange = (selectedValue: string | number) => {
    const optionObject = industryData.find((data: any) => data._id === selectedValue);
    if (optionObject && !selectedOptions.some((opt: any) => opt.value === optionObject._id)) {
      setSelectedOptions([...selectedOptions, { value: optionObject._id, label: optionObject.label }]);
      onChangeMultiSelect(optionObject._id, "industry");
    }
  };

  const hanfleChangeSegmentIndustry = (selectedValue: string | number) => {
    const optionObject = industrySegmentData.find((data: any) => data._id === selectedValue);
    if (optionObject && !selectedOptionsSegment.some((opt: any) => opt.value === optionObject._id)) {
      setSelectedOptionsSegment([...selectedOptionsSegment, { value: optionObject._id, label: optionObject.label }]);
      onChangeMultiSelect(optionObject._id, "industrySegment");
    }
  };


  console.log('selectedOptions', selectedOptions)

  return (
    <Modal isOpen={showModal} setShowModal={setShowModal}>
      <div style={{ width: "70%", height: "100%", margin: "90px auto" }}>
        <Title>Add New User</Title>
        <form
          onSubmit={onSubmit}
          autoComplete="off"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Input
            label={"INSTAGRAM HANDLE"}
            placeholder={"Istagram"}
            required
            {...registerInput("username")}
          />
          <Input
            label={"FIRST NAME"}
            placeholder={"Name"}
            {...registerInput("name")}
          />
          <Input
            label={"LAST NAME"}
            placeholder={"LastName"}
            {...registerInput("lastName")}
          />
          <Input
            label={"EMAIL"}
            placeholder={"Email"}
            required
            {...registerInput("email")}
          />
          <Input
            label={"TENANT"}
            placeholder={"Tenant"}
            {...registerInput("tenant")}
          />
          <SelectMulti
            label={"INDUSTRY"}
            options={industryData.map((data: any) => ({ value: data._id, label: data.label }))}
            onChange={(option: any) => handleChange(option)}
          />
          <ContainerItemps>
            {selectedOptions.map((option: any) => (
              <div key={option.value}>
                <Item>
                  {option.label} <ItemClose onClick={() => handleRemoveSelected(option)}>x</ItemClose>
                </Item>
              </div>
            ))}
          </ContainerItemps>
          <SelectMulti
            label={"INDUSTRY_SEGMENT"}
            options={industrySegmentData.map((data: any) => ({ value: data._id, label: data.label }))}
            onChange={(option: any) => hanfleChangeSegmentIndustry(option)}
          />
          <ContainerItemps>
            {selectedOptionsSegment.map((option: any) => (
              <div key={option.value}>
                <Item>
                  {option.label} <ItemClose onClick={() => handleRemoveSelectedSegment(option)}>x</ItemClose>
                </Item>
              </div>
            ))}
          </ContainerItemps>
          {!submitLoading ? (
            <Button label="Register" />
          ) : (
            <div>
              <p>Loading</p>
            </div>
          )}
          {errors ? (
            <div className={"mt center"}>
              {errors.get("submitError") && (
                <Message
                  message={errors.get("submitError")}
                  color={"#red"}
                />
              )}
              {errors.get("email")?.pattern && (
                <Message
                  message={"Email must be a valid email"}
                  color={"red"}
                />
              )}
            </div>
          ) : null}
        </form>
      </div>
    </Modal>
  )
}
