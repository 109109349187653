import { ProfileEdit } from "./ProfileEdit";
import { useLocation, useParams } from "react-router-dom";
import { QueryResult, useMutation, useQuery } from "@apollo/client";
import { query } from "services/queries/queries";
import {
  UPDATE_USER_ONE_TALENT,
  UPDATE_USER_TALENT,
} from "services/queries/mutations";
import { useToast } from "context/toastContext/toastContext";

export const ProfileEditLoad = () => {
  const {id} = useParams();
  const { error, success } = useToast();
  const [userUpdateOneAdmin] = useMutation(UPDATE_USER_TALENT);
  const [talentUpdateOneAdmin] = useMutation(UPDATE_USER_ONE_TALENT);

  const handleUpdateUser = async (data: any) => {
    try {
      await userUpdateOneAdmin({
        variables: {
          id: id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: "",
          type: "",
        },
      });
      success("Resync user successfully!");
    } catch (err) {
      console.log(err);
      console.log(err);
      error("An error occurred with resync!");
    }
  };

  const handleUpdate = async (data: any) => {
    try {
      await talentUpdateOneAdmin({
        variables: {
          user: id,
          ...data,
        },
      });
      success("Resync user successfully!");
    } catch (err) {
      console.log(err);
      console.log(err);
      error("An error occurred with resync!");
    }
  };

  const { pathname }: any = useLocation();
  const {
    data: userTypesData,
    loading: userTypeLoading,
    error: userTypeError,
  }: QueryResult<any, { filter: any }> = useQuery(query("talentFindOneAdmin"), {
    variables: {
      filter: {
        user: id,
      },
    },
  });

  if (userTypeLoading) {
    return <div>Loading...</div>;
  }

  if (userTypeError) {
    return <div>Error!</div>;
  }

  return (
    <ProfileEdit
      path={pathname}
      data={userTypesData.talentFindOneAdmin}
      handleUpdateUser={handleUpdateUser}
      handleUpdate={handleUpdate}
    />
  );
};
