import React from "react";
import { FormProps, Item } from "interfaces";
import { Select } from "components/Atoms/Select/Select";
import { DropDownLoad } from "components/Molecules/DropDown/DropDown.load";
import { FormButton } from "components/Atoms/FormButton";

export interface EthnicityProps extends FormProps {
  languages: Item[];
  ethnicities: Item[];
  countries: Item[];
}

export const Ethnicity = ({
  languages,
  submitLoading,
  ethnicities,
  countries,
  registerSelect,
  registerList,
  onSubmit,
}: EthnicityProps): JSX.Element => {
  return (
    <div style={{ width: "100%", maxWidth: "700px" }}>
      <form
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: "3rem",
        }}
        onSubmit={onSubmit}
      >
        <DropDownLoad
          label={"Add Languages"}
          placeholder={"select to add language"}
          options={languages}
          //@ts-ignore
          {...registerList("languages")}
        />

        <Select
          label={"Add Ethnicity"}
          options={ethnicities}
          {...registerSelect?.("ethnicity")}
        />
        <Select
          label={"Country of Origin"}
          options={countries}
          {...registerSelect?.("country")}
        />

<div className="center"></div>
          <FormButton loading={submitLoading} type="submit" label="Save" />
        
      </form>
    </div>
  );
};
