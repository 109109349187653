import { FC } from "react";

import { Container, Spinner } from "./styled";

interface ButtonProps {
  label: string;
  onClick?: () => void;
  width?: string;
  height?: string;
  disabled?: boolean;
  border?: string;
  background?: string;
  borderRadius?: string;
  isLoading?: boolean;
  props?: any;
}

export const Button: FC<ButtonProps> = ({
  label,
  onClick,
  width,
  height,
  border,
  disabled,
  background,
  isLoading,
  borderRadius,
  props,
}) => {
  return (
    <Container
    disabled={disabled}
      width={width}
      height={height}
      border={border}
      background={background}
      borderRadius={borderRadius}
      onClick={onClick}
      {...props}
    >
      {isLoading ? <Spinner isLoading={isLoading} />
      : label}
    </Container>
  );
};
