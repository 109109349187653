import React from 'react';

export const ArrowDownIcon=():JSX.Element=>
	<svg
		width={11}
		height={6}
		viewBox="0 0 11 6"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.686 5.98a.712.712 0 00.533-.234l4.459-4.564a.648.648 0 00.199-.475.677.677 0 00-.094-.352.633.633 0 00-.24-.246.646.646 0 00-.346-.093.675.675 0 00-.492.21l-4.3 4.413h.568L1.666.227a.675.675 0 00-.492-.211.646.646 0 00-.346.093.676.676 0 00-.246.246.712.712 0 00-.088.352c0 .094.016.182.047.264a.66.66 0 00.152.21l4.46 4.565c.16.156.337.234.533.234z"
			fill="#fff"
		/>
	</svg>
;
