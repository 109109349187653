import React, {useEffect, useState} from 'react';
import TenantsRender from './Tenants.render';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../services/queries/queries';
import {TenantI} from '../../interfaces/general';
import TalentsForm from '../Talents/Talents.form';
import TenantsForm from './Tenants.form';
import {buttonStyle} from '../../styles/classes';

export default function TenantsLoad(){

	const [tenants, setTenants] = useState<TenantI[]>();
	const [loading, setLoading] = useState<boolean>();
	const [form, setForm] = useState<boolean>();
	const [doQuery, { data }] = useLazyQuery(query("tenantFindMany"));

	useEffect(()=>{
		getTenants();
	},[loading]);

	const getTenants = () => {
		doQuery({
			variables: {

			},
			fetchPolicy: 'no-cache',
			onCompleted: (data:any):void => {
				setTenants(data.tenantFindMany);
				setLoading(false);
			}
		})
	}

	return (
		<div>
			<div className="flex">

				<div className="flex align-center grows">
					<h5 className="f-urbanist f-700 mr">Tenants</h5>
					<button
						className={buttonStyle}
						onClick={()=>setForm(true)}
					>
						+ Add Tenant
					</button>
				</div>
			</div>
			<TenantsRender tenants={tenants} />

			{form &&
				<div className="fixed right-0 top-2 neutral-800 b-neutral-700-t-l br-tl p-2 bottom-0 flex form-enter overflow-y-scroll">
					<div className="grows">
						<TenantsForm setForm={setForm} setLoading={setLoading} />
					</div>
				</div>
			}

		</div>
	)
}