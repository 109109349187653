import styled from "styled-components";

interface IislandMarker {
	color?: String
}

export const GraphContainer = styled.div`
  flex-grow: 1;
  border: 1px solid #222;
`;

export const IslandMarker = styled.div<IislandMarker>`
	width: 10px;
  	height: 10px;
  	border-radius: 50%;
  	background: ${props => props.color || "red"};
`

export const GraphMarkersDiv = styled.div`
	display: flex;
  	margin-top: 1rem;
  	position: absolute;
  	bottom: 2rem;
  	left: 2rem;
`

export const UserTitle = styled.div`
  	position: absolute;
  	top: 3rem;
  	left: 3rem;
  	right: 3rem;
  	display: flex;
  	align-items: center;
	> h1 {
	  	font-size: 24px;
	  	margin-left: 1rem;
	}
  
  	> img{
	  	width: 50px;
	  	height: 50px;
	  	border-radius: 50%;
	  	object-fit: cover;
	  	flex-shrink: 0;
	}
`

export const ExpandButton = styled.a`
  	position: absolute;
  	top: 3rem;
  	right: 3rem;
  	cursor: pointer;
`

interface TabProps {
	selected?:boolean
}

export const Tab = styled.a<TabProps>`
	display: block;
  	padding: 10px 20px;
  	margin-bottom: 1rem;
  	font-size: 12px;
  	border-radius: 20px;
  	background-color: ${(p:TabProps) => p.selected ? '#FF5656' : ''};
  	cursor:pointer;
  	color:#fff;
  	text-decoration: none;
`

export const Table = styled.table`
  	width: 100%;
	td{
	  border-bottom: 1px solid #333;
	  padding: 10px 0;
	}	
  	
  td:last-child{
	text-align: right;
  }
`