import { Account } from "./Account";
import { useForm } from "hooks/useForm/useForm";
import { FormHook } from "hooks/useForm/interfaces";
import { FC } from "react";

interface AccountLoadProps {
  dataUser: {
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
  };
  handleUpdateUser: (data: any) => void;
}

export const AccountLoad: FC<AccountLoadProps> = ({ dataUser, handleUpdateUser }) => {
  const formFields: any[] = [
    { name: "firstName", required: false, default: dataUser?.firstName || "" },
    { name: "lastName", required: false, default: dataUser?.lastName || "" },
    { name: "email", required: false, default: dataUser?.email || "" },
    { name: "password", required: false, default: "" },
  ];

  const handleSubmit = async (formData: any): Promise<void> => {
    handleUpdateUser(formData);
  };

  const { data, errors, loading, ...rest }: FormHook = useForm(
    formFields,
    handleSubmit
  );
  return (
    <Account
      submitLoading={loading}
      errors={errors}
      registerInput={rest.registerInput}
      onSubmit={rest.handleSubmitForm}
    />
  );
};
