import React, {useEffect, useState} from 'react';
import {saveEntry, updateEntry} from "../../../services/hotlists/hotlists.service";
import Editor from "../../../components/Form/Editor";
import TagField from './fields/TagField';
import FileUpload from './fields/FileUpload';
import {buttonStyle} from '../../../styles/classes';
import TalentSelector from '../../../components/Form/TalentSelector';
import {useNavigate} from 'react-router-dom';
import Boolean from '../../../components/Form/Boolean';
import {ArticleI, MediaI, TagI} from '../../../interfaces/general';
import MediaUpload from './fields/MediaUpload';
import TacticsSelector from '../../../components/Form/TacticsSelector';

interface ArticleFormI {
	setArticle:(a:ArticleI)=>void
	article?:ArticleI
	id? : number | string
}

export default function ArticleForm({setArticle, article, id:hotlistId}:ArticleFormI){

	const navigate = useNavigate();

	const [body, setBody] = useState<string>(article?.body || "");
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(()=>{
		if(loading){
			console.log("Still loading", article);
			setBody(article?.body || "");
			setLoading(false);
		}
	},[article]);

	useEffect(()=>{
		if(!loading){
			setArticle({...article, body});
		}
	},[body]);

	const onSubmit = (e:any) => {
		e.preventDefault();

		const d = {
			title: article?.title,
			subtitle: article?.subtitle,
			author: article?.author,
			embed: article?.embed,
			publishDate: article?.publishDate,
			tags: article?.tags?.map((e:TagI)=>e.id),
			media: article?.media?.map((e:MediaI)=>e.id),
			body: article?.body,
			cover: article?.coverId,
			featuredTalent: article?.featuredTalent,
			relatedTalent: article?.relatedTalent,
			tactics: article?.tactics,
			prod: article?.prod,
			staging: article?.staging,
			published: article?.published,
			archive: hotlistId ? article?.archive : false
		}
		console.log(d);

		save(d);
	}

	const textUpdate = (e:any) => {
		setArticle({...article, [e.target.name] : e.target.value});
	}

	const save = async (data:any) => {
		if(hotlistId){
			await updateEntry({
				endpoint : "hotlists",
				data,
				id: hotlistId
			});
		}
		else {
			await saveEntry({
				endpoint : "hotlists",
				data
			});
		}
		navigate("/hotlists/articles");

	}

	return (
		<div className="w-100">
			<div className="flex align-start">
				<h4 className="mb-2 f-urbanist grows">
					{hotlistId ? "Edit" : "Create"} Article
				</h4>
			</div>

			<form
				onSubmit={onSubmit}
				id="article-form"
				onKeyPress={(e) => {
					if (e.key === "Enter" && e.target.type === "text") {
						e.preventDefault();
					}
				}}
			>
				<p className="mb">
					<label>
						Title
					</label>
					<input type="text" name="title" placeholder="Title" value={article?.title || ""} onChange={textUpdate} />
				</p>
				<p className="mb">
					<label>
						Subtitle
					</label>
					<input type="text" name="subtitle" placeholder="Intro" value={article?.subtitle || ""} onChange={textUpdate} />
				</p>
				<p className="mb">
					<label>
						Author
					</label>
					<input type="text" name="author" placeholder="Author" value={article?.author || ""} onChange={textUpdate} />
				</p>
				<div className="mb">
					<label>
						Cover
					</label>
					<FileUpload
						fileUrl={article?.cover || ""}
						setFile={(url:string, id:number|string)=>setArticle({...article, coverId:id, cover: url})}
						//setFileUrl={(url:string)=>setArticle({...article, cover:url})}
					/>
				</div>

				<div className="mb">
					<label>
						Media
					</label>
					<p className="f-small t-neutral-300 mb">
						Upload additional images
					</p>
					<MediaUpload
						files={article?.media || []}
						setFiles={(f:MediaI[])=>setArticle({...article, media:[...article?.media || [], ...f]})}
						removeFiles={(f:MediaI[])=>setArticle({...article, media: f})}
					/>
				</div>

				<div className="p mb b-neutral-600 b br-half">
					<label>
						Status
					</label>
					<p className="f-small t-neutral-300 mb">
						The article is published to the website if the status is "Published" and the Date current data is greater than the publish date.
					</p>
					<div className="flex">
						<div className="grows mr-2">
							<label>
								Publish Date
							</label>
							<input type="date" name="publishDate" placeholder="Publish Date" value={article?.publishDate || ""} onChange={textUpdate} />
						</div>
						<div>
							<label>
								{article?.published ? "Published" : "Draft"}
							</label>
							<Boolean value={article?.published ? article.published : false} setValue={()=> setArticle({...article, published: !article?.published})} />
						</div>
					</div>

				</div>
				<div className="mb">
					<label>
						Tags
					</label>
					<TagField tags={article?.tags || []} setTags={(t:TagI[])=>setArticle({...article, tags: t})} />
				</div>


				<div className="mb" style={{color: "black"}}>
					<label>
						Body
					</label>
					<Editor data={body} setDescription={(d:string)=>setBody(d)} />
				</div>

				<p className="mb">
					<label>
						Embed Content
					</label>
					<textarea name="embed" placeholder="Embed Code" value={article?.embed || ""} onChange={textUpdate} />
				</p>

				<div className="mb">
					<label>
						Featured Talent
					</label>
					<TalentSelector selected={article?.featuredTalent || []} setSelected={(t)=>setArticle({...article, featuredTalent: t})} />
				</div>

				<div className="mb">
					<label>
						Related Talent
					</label>
					<TalentSelector selected={article?.relatedTalent || []} setSelected={(t)=>setArticle({...article, relatedTalent: t})} />
				</div>
				<div className="mb">
					<label>
						Tactics
					</label>
					<TacticsSelector selected={article?.tactics || []} setSelected={(t)=>setArticle({...article, tactics: t})} />
				</div>

				{/*<div className="flex mb-2">*/}
				{/*	<div className="mr-2">*/}
				{/*		<label>*/}
				{/*			Active in staging*/}
				{/*		</label>*/}
				{/*		<Boolean  value={article?.staging ? article.staging : false} setValue={()=> setArticle({...article, staging: !article?.staging})} />*/}
				{/*	</div>*/}

				{/*	<div>*/}
				{/*		<label>*/}
				{/*			Active in production*/}
				{/*		</label>*/}
				{/*		<Boolean  value={article?.prod ? article.prod : false} setValue={()=> setArticle({...article, prod: !article?.prod})} />*/}
				{/*	</div>*/}
				{/*</div>*/}

				<div className="pb-2">
					<button className={buttonStyle}>
						Save
					</button>
				</div>

			</form>
		</div>
	)
}
