import { FormButton } from "components/Atoms/FormButton";
import { Input } from "components/Atoms/Input/Input";

export const ContactForm = ({ registerInput, onSubmit }: any): JSX.Element => {
  return (
    <div style={{ width: "100%", maxWidth: "700px" }}>
      <form
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: "3rem",
        }}
        onSubmit={onSubmit}
      >
        <Input label={"Phone"} {...registerInput("phone")} />
        <Input label={"Website"} {...registerInput("website")} />

        <Input label={"Address1"} {...registerInput("address1")} />
        <Input label={"Address2"} {...registerInput("address2")} />

        <Input label={"City"} {...registerInput("city")} />
        <Input label={"State"} {...registerInput("state")} />
        <Input label={"Zip Code"} {...registerInput("zip")} />

        <div className="center"></div>
        <FormButton type="submit" label="Save" />
      </form>
    </div>
  );
};
