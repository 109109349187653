import React, {useEffect, useState} from 'react';
import CloseIcon from '../../../assets/Icons/Interface/CloseIcon';
import {TacticI} from '../../../interfaces/general';
import Boolean from '../../../components/Form/Boolean';
import Editor from '../../../components/Form/Editor';
import {buttonStyle} from '../../../styles/classes';
import {useLazyQuery, useMutation} from '@apollo/client';
import {query} from '../../../services/queries/queries';
import {tacticCreateOne, tacticUpdateOne} from '../../../services/queries/mutations';
import {slugify} from '../../../utils/misc';
import FileUpload from '../../../components/Form/FileUpload';

interface TacticFormI {
	tId?: string
	setTId?: (t:string) => void
	setForm?: (f:boolean) => void
	setLoading?: (f:boolean) => void
}

export default function TacticForm({tId, setForm, setLoading, setTId}:TacticFormI) {

	const [t, setT] = useState<TacticI>();
	const [body, setBody] = useState<string>(t?.description || "");

	const [queryTactics, { data }] = useLazyQuery(query("tacticsFindMany"));
	const [tacticUpdate, { data:updateData, loading, error }] = useMutation(tacticUpdateOne);
	const [tacticCreate, { data:createData, loading:createLoading, error:createError }] = useMutation(tacticCreateOne);

	useEffect(()=>{
		if(tId){
			queryTactics({
				variables: {filter: {_id: tId}},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => buildEntry(data.tacticFindMany[0])
			});
		}
	},[tId]);

	const buildEntry = (data:TacticI) => {
		setT({
			active: data?.active,
			title: data?.title,
			cover: data?.cover
		});
		setBody(data?.description || "");
	}

	const onSubmit = (e:any) => {
		e.preventDefault();
		if(tId){
			tacticUpdate({
				variables : {
					record: {
						title: t?.title,
						slug: slugify(t?.title || ""),
						description: body,
						active: t?.active || false
					},
					filter: { _id: tId }
				},
				onCompleted: (data:any):void => finishSave()
			});
		}
		else {
			tacticCreate({
				variables : {
					record: {
						title: t?.title,
						slug: slugify(t?.title || ""),
						description: body,
						active: t?.active || false
					}
				},
				onCompleted: (data:any):void => finishSave()
			});
		}
	}

	const finishSave = () => {
		if(setLoading){
			setLoading(true);
		}
		if(setForm){
			setForm(false);
		}
		if(setTId){
			setTId("");
		}
	}

	return (
		<div className="max-w-500px width-500px">
			<div className="flex align-start">
				<h4 className="mb-2 f-urbanist grows">
					{tId ? "Edit" : "Create"} Tactic
				</h4>
				<a onClick={()=>setForm ? setForm(false) : null}>
					<CloseIcon />
				</a>
			</div>

			<form
				onSubmit={onSubmit}
				onKeyPress={(e) => {
					if (e.key === "Enter" && e.target.type === "text") {
						e.preventDefault();
					}
				}}
			>
				<p className="mb">
					<label>Title</label>
					<input
						type="text"
						name="title"
						placeholder="Title"
						value={t?.title || ""}
						onChange={(e)=>setT({...t, title:e.target.value})}
					/>
				</p>

				<div className="mb" style={{color: "black"}}>
					<label>
						Description
					</label>
					<Editor data={body} setDescription={(d:string)=>setBody(d)} />
				</div>

				{tId ?
					<div className="mb">
						<label>
							Cover
						</label>
						<FileUpload
							fileUrl={t?.cover && t?.cover.length > 0 && t?.cover[t?.cover?.length - 1 || 0]?.url || ""}
							endpoint={"admin/upload-tactic-cover"}
							id={tId}
						/>
					</div>
					:
					<p className="f-12px t-300">Please save the tactic before uploading images </p>
				}

				<div className="mb">
					<label>
						{t?.active ? "Published" : "Draft"}
					</label>
					<Boolean value={t?.active ? t.active : false} setValue={()=> setT({...t, active: !t?.active})} />
				</div>

				<p className="pb-2">
					<button className={buttonStyle}>
						Save
					</button>
				</p>

			</form>
		</div>
	)
}
