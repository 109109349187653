import React, {useEffect, useState} from 'react';
import {getEntry} from '../../../services/hotlists/hotlists.service';
import {ArticleI, MediaI, TagI} from '../../../interfaces/general';
import ArticlePreview from './Article.preview';

interface ArticlePreviewLoadI{
	id: string | number
	setPop?: (p:boolean)=>void
}

export default function ArticlePreviewLoad({id, setPop}:ArticlePreviewLoadI){
	const [loading, setLoading] = useState<boolean>(true);
	const [hotlist, setHotlist] = useState<ArticleI>({});

	useEffect(()=>{
		if(loading && id) {
			loadArticle();
		}
	},[loading]);

	const loadArticle = async () => {
		const entry = await getEntry({endpoint: "hotlists", id, params: {"populate[0]": "cover", "populate[1]": "tags", "populate[2]": "media"}});
		const h:ArticleI = {
			title: entry?.data?.attributes?.title,
			subtitle: entry?.data?.attributes?.subtitle,
			publishDate: entry?.data?.attributes?.publishDate,
			author: entry?.data?.attributes?.author,
			body: entry?.data?.attributes?.body,
			embed: entry?.data?.attributes?.embed,
			featuredTalent: entry?.data?.attributes?.featuredTalent,
			relatedTalent: entry?.data?.attributes?.featuredTalent,
			tactics: entry?.data?.attributes?.tactics,
			cover: entry?.data?.attributes?.cover?.data?.attributes?.url,
			coverId: entry?.data?.attributes?.cover?.data?.id,
			published: entry?.data?.attributes?.published,
			prod: entry?.data?.attributes?.prod,
			staging: entry?.data?.attributes?.staging,
			archive: entry?.data?.attributes?.archive || false
		}

		h.tags = entry?.data?.attributes?.tags?.data?.map(({attributes:{title},id}:any):TagI=>{
			return {title, id}
		});
		h.media = entry?.data?.attributes?.media.data?.map(({attributes:{url},id}:any):MediaI=>{
			return {url, id}
		});
		setHotlist(h);
		setLoading(false);
	}

	return (
		<div>
			{loading ? "Loading..." : <ArticlePreview article={hotlist} setPop={setPop} />}
		</div>

	)
}
