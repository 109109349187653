import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../services/queries/queries';
import {TacticI} from '../../interfaces/general';

interface SelectorI {
	selected: string[]
	setSelected : (s:string[]) => void
}

export default function SubtacticSelector({selected, setSelected}:SelectorI) {

	const [loading, setLoading] = useState<boolean>(true);

	const [queryTactics, { data }] = useLazyQuery(query("subtacticFindMany"));

	useEffect(()=>{
		if(loading){
			queryTactics({
				variables: {
					filter: {
						active: true
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					console.log("Subtaxctic selextor", data);
					setLoading(false)
				}
			});
		}
	},[loading]);

	return (
		<div>
			<select value={selected && selected?.length > 0 ? selected[0] : ''} onChange={(e)=>setSelected([e.target.value])}>
				<option value={''}>Select one...</option>
				{data && data?.subtacticFindMany?.length > 0 && data?.subtacticFindMany?.map(({_id, title}:TacticI)=>{
					return (
						<option key={_id} value={_id}>
							{title}
						</option>
					)
				})}
			</select>

		</div>
	)
}
