import React, {useEffect, useState} from 'react'
import {buttonStyle, circleIconStyle} from '../../../styles/classes';
import EyeIcon from '../../../assets/Icons/Interface/EyeIcon';
import PencilIcon from '../../../assets/Icons/Interface/PencilIcon';
import {Link} from 'react-router-dom';
import {HotlistFilterI, TagI} from '../../../interfaces/general';
import SearchIcon from '../../../assets/Icons/Interface/SearchIcon';
import TagField from '../forms/fields/TagField';
import EllipsisIcon from '../../../assets/Icons/Interface/EllipsisIcon';
import {updateEntry} from '../../../services/hotlists/hotlists.service';
import ArticlePreviewLoad from './Article.preview.load';

interface HotlistsRenderI {
	hotlists?: any
	filter?: HotlistFilterI
	setFilter? : (f:HotlistFilterI) => void
	setLoading: (l:boolean) => void
}

export default function ArticlesRender({hotlists, filter={}, setFilter, setLoading}:HotlistsRenderI) {

	const [pop, setPop] = useState<boolean>(false);
	const [current, setCurrent] = useState<number>();
	const [tags, setTags] = useState<TagI[]>();

	const changeFilterText = (e:any) => {
		if(setFilter && e.target.value.length > 3) {
			setFilter({...filter, [e.target.name] : e.target.value})
		}
		else if (setFilter && e.target.value.length === 0){
			setFilter({...filter, [e.target.name] : e.target.value})
		}
	}

	useEffect(()=> {
		if(tags){
			if(setFilter) {
				setFilter({...filter, tags: tags?.map((e:TagI)=>e.id)})
			}
		}
	},[tags]);

	const resolvePublish = (date:string, publish:boolean, archive?:boolean) => {
		const d = new Date();
		const ad = new Date(date);

		if(archive){
			return "Archived"
		}
		if (!publish || !date){
			return "Draft"
		}
		if (d > ad){
			return `Published`
		}
		else {
			return "Future"
		}
	}

	const saveArchive = async (id:string|number, archive:boolean) => {
		const e = await updateEntry({
			endpoint : "hotlists",
			data: {
				archive
			},
			id
		});
		console.log(e);
		setLoading(true);
	}

	return (
		<div>
			<div className="flex align-start mt-2">
				<div className='pver-half flex'>
					<h5 className="f-urbanist f-700 mr">Articles</h5>
					<Link to="/hotlists/form" className={buttonStyle}> + New Article</Link>
				</div>
				<div className="grows" />
				<div className="flex align-start">
					<div className="relative mr-2">
						<input type="text" placeholder="Search articles by title" style={{paddingRight: '3rem'}} name="query" onChange={changeFilterText} />
						<div className="absolute h-3rem flex align-center top-0 right-1 op-60">
							<SearchIcon />
						</div>
					</div>

					<div className="mr flex align-center">
						<TagField placeholder="Tags" tags={tags || []} setTags={(t:TagI[])=>setTags(t)} />
					</div>

					<div className="flex align-center">
						Status
						<select className="ml-half" name="status" onChange={changeFilterText}>
							<option>All Articles</option>
							<option>Published</option>
							<option>Future Publishing</option>
							<option>Draft</option>
							<option>Archived</option>
						</select>
					</div>


				</div>
			</div>

			<table className="mt-2">
				<thead>

				</thead>
				<tbody>
				{hotlists && hotlists.length > 0 && hotlists.map(({attributes: {title, cover, author, subtitle, tags, published, publishDate, archive}, id}:any)=>{
					return (
						<tr key={`${title}-${id}`} className="b-neutral-700-b">
							<td>
								<div className="flex align-center pver">
									{cover?.data?.attributes?.url ?
										<img
											src={`${cover?.data?.attributes?.url}`}
											className="w-80px br aspect-1-1 obj-cover mr"
										/>
										:
										<div className="w-80px br aspect-1-1 obj-cover mr neutral-800"/>
									}
									<div>
										<h6 className="f-1rem mb-quarter">
											{title}
										</h6>
										{author &&
											<p className="f-12px">By {author}</p>
										}
									</div>


								</div>
							</td>
							<td className="t-neutral-300 f-small">
								<div className="max-w-300px lh-130">
									{subtitle}
								</div>

							</td>
							<td className="f-small pr-2">
								<div>
									<div className="f-uppercase f-bold p-half b-neutral-300 inline-flex br-quarter mb-half">
										{resolvePublish(publishDate, published, archive)}
									</div>
								</div>
								{published && !archive && publishDate}
							</td>
							<td>
								<div className="flex wrap">
									{tags && tags?.data?.length > 0 && tags?.data?.map(({attributes:{title}, id:tagId}:any) => {
										return (
											<a key={`${title}-${id}-${tagId}`} className="inline-block mr-half blue t-white br-half pver-half phor mb-half f-small f-urbanist f-600">
												#{title}
											</a>
										)
									})}
								</div>
							</td>
							<td className="pl-2">
								<div className="flex align-center">
									<a
										className={circleIconStyle}
										onClick={()=>{
											setCurrent(id);
											setPop(true);
										}}
									>
										<EyeIcon className="neutral-200" />
									</a>

									<Link
										to={`/hotlists/form/${id}`}
										className={`ml-half ${circleIconStyle}`}
									>
										<PencilIcon className="neutral-200" />
									</Link>
									<div className="ml submenu-hover relative">
										<EllipsisIcon />
										<div className="f-12px t-neutral-200 neutral-800 pver phor-half br-half submenu">
											<a onClick={()=>saveArchive(id, !archive)}>{archive ? "Unarchive" : "Archive"}</a>
										</div>
									</div>
								</div>

							</td>


						</tr>
					)
				})}
				</tbody>
			</table>

			{pop &&
				<div className="fixed edges z-1000 overflow-y-scroll">
					<div className="fixed edges black80">

					</div>
					<div className="p max-w-800px relative mhor-auto">
						<div className="neutral-900 br p-2">
							{current &&
								<ArticlePreviewLoad id={current} setPop={setPop}/>
							}
						</div>
					</div>
				</div>
			}

		</div>
	);
}
