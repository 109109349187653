import React from 'react'
import FileUpload from './fields/FileUpload';
import {HomeDataI, TagI, TagRowI} from '../../../interfaces/general';
import {buttonStyle} from '../../../styles/classes';
import CloseIcon from '../../../assets/Icons/Interface/CloseIcon';
import TagField from './fields/TagField';
import {saveSingleEntry} from '../../../services/hotlists/hotlists.service';

interface HomeFormI {
	homeData?:HomeDataI
	setHomeData?:(hd:HomeDataI)=>void
	setForm?:(f:boolean)=>void
}

export default function HomeForm({homeData, setHomeData, setForm}:HomeFormI) {


	const changeTagRowTitle = (e:any, i:number) => {
		let hd:HomeDataI = {...homeData};
		let tr:TagRowI[] = hd.tagRow?.map((e:TagRowI):TagRowI=>e) || [];
		tr[i].title = e.target.value;
		hd.tagRow = tr;
		if(setHomeData){
			setHomeData(hd);
		}
		//let tr:any = [...hd.tagRow];
	}

  const changeTagRowTitleColor = (e:any, i:number) => {
    let hd:HomeDataI = {...homeData};
    let tr:TagRowI[] = hd.tagRow?.map((e:TagRowI):TagRowI=>e) || [];
    tr[i].titleColor = e.target.value;
    hd.tagRow = tr;
    if(setHomeData){
      setHomeData(hd);
    }
    //let tr:any = [...hd.tagRow];
  }

	const changeTagRowCover = (url: string, id:string|number, i:number) => {
		let hd:HomeDataI = {...homeData};
		let tr:TagRowI[] = hd.tagRow?.map((e:TagRowI):TagRowI=>e) || [];
		tr[i].cover = url;
		tr[i].coverId = id;
		hd.tagRow = tr;
		if(setHomeData){
			setHomeData(hd);
		}
	}

	const changeTagRowTags = (tags:TagI[], i:number) => {
		let hd:HomeDataI = {...homeData};
		let tr:TagRowI[] = hd.tagRow?.map((e:TagRowI):TagRowI=>e) || [];
		tr[i].tags = tags;
		hd.tagRow = tr;
		if(setHomeData){
			setHomeData(hd);
		}
	}

	const onSubmit = (e:any) => {
		e.preventDefault();
		const d = {
			tagRow : homeData?.tagRow?.map(({title, titleColor, tags, coverId}:TagRowI)=>{
				return (
					{
						title,
						titleColor,
						tags : tags?.map(({id}:TagI)=>id),
						cover: coverId
					}
				)
			})
		};

		save(d);

	}

	const save = async (data:any) => {
    console.log("Home data", data);
    await saveSingleEntry({
      endpoint : "hotlist-home",
      data
    });
    if(setForm){
      setForm(false);
    }
	}

	return (
		<div className="w-100">
			<div className="flex align-start">
				<h4 className="mb-2 f-urbanist grows">
					Edit Box
				</h4>
				<a onClick={()=>setForm ? setForm(false) : null}>
					<CloseIcon />
				</a>
			</div>
			<p className="f-small t-neutral-200 mb">Changes will only be reflected in the frontend after save.</p>

			<form
				onSubmit={onSubmit} id="article-form"
				onKeyPress={(e) => {
					if (e.key === "Enter") {
						e.preventDefault();
					}
				}}
			>
				{homeData?.tagRow && homeData?.tagRow?.map(({title, cover, tags, titleColor},index)=>{
					return (
						<div className="p br-half mb b-neutral-300" key={`tag-row-${index}`}>
							<p className="mb">
								<label>
									Title
								</label>
								<input type="text" name="title" placeholder="Title" value={title || ""} onChange={(e)=>changeTagRowTitle(e, index)}  />
							</p>

              <p className="mb">
                <label>Title Color</label>
                <select
                  name="titleColor"
                  value={titleColor || ""}
                  onChange={(e)=>changeTagRowTitleColor(e, index)}
                >
                  <option value="white">
                    White
                  </option>
                  <option value="black">
                    Black
                  </option>
                </select>
              </p>

							<div className="mb">
								<label>
									Cover
								</label>
								<FileUpload
									fileUrl={cover || ""}
									setFile={(url:string, id:number|string)=>changeTagRowCover(url, id, index)}
									//setFileUrl={(url:string)=>setArticle({...article, cover:url})}
								/>
							</div>

							<div className="mb">
								<label>
									Tags
								</label>
								<TagField tags={tags || []} setTags={(t:TagI[])=>changeTagRowTags(t, index)} />
							</div>
						</div>
					)
				})}

				<p className="pb-2">
					<button className={buttonStyle}>
						Save
					</button>
				</p>

			</form>
		</div>
	)
}
