import React from 'react';
import {ButtonProps} from 'interfaces';
import {StyledButton} from 'styles/ui';
import Spinner from '../Spinner/Spinner';

export interface FormButtonProps extends ButtonProps{
	loading?:boolean
}

export default function FormButtonRender({label,variant,size,children,loading=false,...props}:FormButtonProps):JSX.Element{
	return !loading?
		<StyledButton
			variant={variant}
			size={size}
			{...props}
		>{label}{children}</StyledButton>
		:
		<div className='w-100 flex justify-center'>
			<Spinner/>
		</div>
	;
}
