import React, {useEffect, useState} from 'react';
import {UserEditI} from '../../../interfaces/general';
import Boolean from '../../../components/Form/Boolean';
import {useMutation} from '@apollo/client';
import {userUpdateOneAdmin} from '../../../services/queries/mutations';
import {useParams} from 'react-router-dom';
import {buttonStyle} from '../../../styles/classes';
import FileUpload from '../../../components/Form/FileUpload';

interface ProfilerenderI {
	user?: UserEditI
}

interface UserI{
	firstName?:string
	lastName?:string
	email?:string
	password?:string
	verified?:boolean
}


export default function AccountRender({user}:ProfilerenderI){

	const {id} = useParams();
	const [success, setSuccess] = useState<string>("");
	const [userForm, setUserForm] = useState<UserI>();
	const [updateUser, { data, loading, error }] = useMutation(userUpdateOneAdmin);

	useEffect(()=>{
		if(user?.userObj){
			setUserForm({
				firstName: user?.userObj?.firstName || "",
				lastName: user?.userObj?.lastName || "",
				email: user?.userObj?.email || "",
				password: "",
				verified: user?.userObj?.verified || false
			});
		}
	},[user]);

	useEffect(()=>{
		const delayDebounceFn = setTimeout(() => {
			setSuccess("")
			// Send Axios request here
		}, 2000)

		return () => clearTimeout(delayDebounceFn)
	},[success]);

	const saveProfile = () => {

		let record:UserI = {
			firstName: userForm?.firstName,
			lastName: userForm?.lastName,
			email: userForm?.email,
			verified: userForm?.verified || false
		}

		if(userForm?.password){
			record.password = userForm?.password
		}

		updateUser({
			variables : {
				...record,
				id
			},
			onCompleted: (data:any):void => setSuccess("Account updated")
		});
	}

	return (
		<div className="flex w-100">
			<div className="mb">
				<label>
					File
				</label>
				<FileUpload
					fileUrl={user?.userObj?.profilePicture?.url || ""}
					endpoint={"admin/update-profile-picture"}
					user={id}
					format="none"
				/>
			</div>

			<div className="ml-2 grows">
				<p className="mb">
					<label>
						Name
					</label>
					<input value={userForm?.firstName || ""} onChange={(e)=>setUserForm({...userForm, firstName: e.target.value})} type="text" name="firstName" placeholder="Name" />
				</p>
				<p className="mb">
					<label>
						Last Name
					</label>
					<input value={userForm?.lastName || ""} onChange={(e)=>setUserForm({...userForm, lastName: e.target.value})} type="text" name="lastName" placeholder="Last Name" />
				</p>
				<p className="mb">
					<label>
						Email
					</label>
					<input value={userForm?.email || ""} onChange={(e)=>setUserForm({...userForm, email: e.target.value})} type="text" name="email" placeholder="Email" />
				</p>
				<p className="mb">
					<label>
						Password
					</label>
					<input value={userForm?.password || ""} onChange={(e)=>setUserForm({...userForm, password: e.target.value})} type="text" name="password" placeholder="Password" />
				</p>
				<div>
					<label>
						{userForm?.verified ? "Verified" : "Unverified"}
					</label>
					<Boolean value={userForm?.verified || false} setValue={()=> setUserForm({...userForm, verified: !userForm?.verified})} />
				</div>
				<p className="pb-2 mt-2">
					<button className={buttonStyle} onClick={()=>saveProfile()}>
						Save
					</button>
					{error && <p className="t-orange f-12px mt">{error?.message}</p>}
					{success && <p className="t-green f-12px mt">{success}</p>}
				</p>
			</div>
		</div>
	)
}