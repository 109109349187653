import { Link } from "react-router-dom";
import styled from "styled-components";

export const TableRowContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.gray_200};
  justify-content: space-around;
`;

export const TableCell = styled.div<{ isLast?: boolean; width?: string }>`
  display: flex;
  padding: 20px 15px;
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => props.width || "225px"};
  font-size: 1.2rem;
  color: ${(props: any) => props.color};
  border-right: ${(props) =>
    !props.isLast && `1px solid ${props.theme.gray_200}`};
`;

export const TableCellComponent = styled.div`
  display: flex;
  padding: 20px 15px;
  justify-content: space-between;
  align-items: center;
  width: 225px;
  font-size: 1.2rem;
  color: ${(props: any) => props.color};
`;

export const Photo = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
`;
export const TextTitle = styled.div`
  margin-top: 100px;
  margin-bottom: 20px;
  font-size: 2.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.white};
  margin-right: 10px;
`;

export const Text = styled.div`
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 400;
  color: ${({ theme }) => theme.gray_100};
  margin-right: 10px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  height: 80%;
  padding-top: 15px;
  padding-bottom: 10px;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(5,5,5,0.2);
  }
`;

export const DivLink = styled.div`
  display: flex;
  flex-direction: column;
  height: 80%;
  // padding: 20px;
  cursor: pointer;
  &:hover {
    background-color: rgba(5,5,5,0.2);
  }
`;
