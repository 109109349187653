import React, { FC, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import "filepond/dist/filepond.min.css";
import "./formUploadFileRegister.css"

import { sendFile } from "services/files/files.service";
import { Modal } from "components/Organisms/Modal/Modal";

import { Title } from "../styles";
import { Button } from "components/Atoms/Button/Button";
import { useToast } from "context/toastContext/toastContext";

registerPlugin(FilePondPluginFileValidateType);

export const FormUploadFileRegister: FC<any> = ({
  showModalCSV,
  setShowModalCSV,
  addToast,
}) => {
  const [file, setFile] = useState<any>(null);
  const { error : err, success } =  useToast()

  const handleFileChange = (fileItems: any[]) => {
    setFile(fileItems.length > 0 ? fileItems[0].file : null);
  };

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await sendFile(formData);
        console.log("RESP", response);
        success("The CSV Import is in progress. We will send an email once is completed")
        setShowModalCSV(false);
      } catch (error) {
        console.error("Error uploading file:", error);
        err("Error uploading file")
      }
    } else {
      console.error("No file selected");
      err("No file selected")
    }
  };

  return (
    <Modal isOpen={showModalCSV} setShowModal={setShowModalCSV}>
      <div style={{ width: "70%", height: "auto", margin: "90px auto" }}>
        <Title>Import File CSV</Title>
        <FilePond
          files={file ? [file] : []}
          onupdatefiles={handleFileChange}
          allowMultiple={false}
          acceptedFileTypes={["application/csv", "text/csv"]}
          labelIdle='Drag and drop your file here or <span class="filepond--label-action">browse</span>'
        />
        <Button
        disabled={!file} 
            label="Upload"
            width="100%"
            height="40px"
            borderRadius="7px"
            background="#FF5656"
            onClick={handleUpload}
          />
      </div>
    </Modal>
  );
};
