import {useContext } from "react";
import {useNavigate, NavLink} from "react-router-dom";

import {
  LogoOpus,
  TalentIcon,
  TenantIcon,
  CompaniesIcon,
  OportunityIcon,
  InterestsIconSvg,
  IndustrySegmentSvg,
} from "assets";

import {
  Logo,
  LogoImg,
} from "./styles";
import { UserContext } from "context/userContext/userContext";
import IconHotlist from '../../../assets/Icons/Nav/Hotlist';
import LogoutIcon from '../../../assets/Icons/Nav/LogoutIcon';


export default function Sidebar () {
  const navigate = useNavigate();
  const { logout }: any = useContext(UserContext);

  const navLinks = [
    {
      label: "Talents",
      Icon: TalentIcon,
      to: "/talent"
    },
    {
      label: "Accounts",
      Icon: TalentIcon,
      to: "/accounts"
    },
    {
      label: "Tenants",
      Icon: TenantIcon,
      to: "/tenants"
    },
    // {
    //   label: "Brands",
    //   Icon: BrandsSvg,
    //   to: "/brands/1",
    //   match: "/brands",
    // },
    // {
    //   label: "Companies",
    //   Icon: CompaniesIcon,
    //   to: "/companies",
    //   match: "/companies",
    // },
    {
      label: "Opportunities",
      Icon: OportunityIcon,
      to: "/opportunities/alerts"
    },
    // {
    //   label: "Products",
    //   Icon: ProductsIcon,
    //   to: "/products/1",
    //   match: "/products",
    // },
    {
      label: "Interests",
      Icon: InterestsIconSvg,
      to: "/interests"
    },
    {
      label: "Industries",
      Icon: CompaniesIcon,
      to: "/industries"
    },
    {
      label: "Industry Segments",
      Icon: IndustrySegmentSvg,
      to: "/industry-segment"
    },
    {
      label: "Hotlists",
      Icon: IconHotlist,
      to: "/hotlists/articles"
    },
  ];

  const logoutAction = (): void => {
    logout();
    navigate("/login");
  };

  return (
    <aside className="p-1half border-box h-100vh w-250px flex fixed top-0">
      <div className="br neutral-800 flex-column w-100 p f-lato f-400 f-12px">
        <Logo>
          <LogoImg src={LogoOpus} />
        </Logo>
        <div>
        {navLinks.map(({ Icon, label, to}, index) => {
          return (
              <NavLink  key={label} className={({isActive}) => isActive ? "p-half t-white mb flex align-center active" : "p-half t-neutral-300 mb flex align-center"} to={to}>
                <span className="blue-circle" />
                <span className="mr-half w-1rem icon"><Icon/></span>
                {label}
              </NavLink>
          );
        })}
        </div>
        <div className="grows flex-column justify-end p">
          <a className="flex align-ceter t-neutral-300" onClick={logoutAction}> <LogoutIcon /> <span className="ml">Logout</span></a>
        </div>

      </div>

    </aside>
  );
};

