import React from "react";

function CompaniesIcon(props: any) {
    const {fill, width, height} = props;
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width ||  20}
        height={height || 20}
        fill="none"
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            fill={fill || "#374957"}
            d="M5.833 11.667A.833.833 0 0 1 5 12.5h-.833a.833.833 0 1 1 0-1.667H5a.833.833 0 0 1 .833.834Zm3.334-.834h-.834a.833.833 0 1 0 0 1.667h.834a.833.833 0 1 0 0-1.667ZM5 14.167h-.833a.834.834 0 0 0 0 1.666H5a.834.834 0 0 0 0-1.666Zm4.167 0h-.834a.834.834 0 0 0 0 1.666h.834a.834.834 0 0 0 0-1.666ZM5 4.167h-.833a.833.833 0 1 0 0 1.666H5a.833.833 0 1 0 0-1.666Zm4.167 0h-.834a.833.833 0 1 0 0 1.666h.834a.833.833 0 1 0 0-1.666ZM5 7.5h-.833a.833.833 0 1 0 0 1.667H5A.833.833 0 0 0 5 7.5Zm4.167 0h-.834a.833.833 0 0 0 0 1.667h.834a.833.833 0 0 0 0-1.667ZM20 8.333v7.5A4.172 4.172 0 0 1 15.833 20H4.167A4.172 4.172 0 0 1 0 15.833V4.167A4.172 4.172 0 0 1 4.167 0h5a4.172 4.172 0 0 1 4.166 4.167h2.5A4.172 4.172 0 0 1 20 8.333Zm-15.833 10h7.5V4.167a2.5 2.5 0 0 0-2.5-2.5h-5a2.5 2.5 0 0 0-2.5 2.5v11.666a2.5 2.5 0 0 0 2.5 2.5Zm14.166-10a2.5 2.5 0 0 0-2.5-2.5h-2.5v12.5h2.5a2.5 2.5 0 0 0 2.5-2.5v-7.5Zm-2.5 2.5a.833.833 0 1 0 0 1.666.833.833 0 0 0 0-1.666Zm0 3.334a.833.833 0 1 0 0 1.665.833.833 0 0 0 0-1.665Zm0-6.667a.833.833 0 1 0 0 1.666.833.833 0 0 0 0-1.666Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h20v20H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  
  const MemoSvgCompaniesIcon = React.memo(CompaniesIcon);
  export default MemoSvgCompaniesIcon;
  