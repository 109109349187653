import React from "react";

function OportunityIcon(props: any) {
  const {fill, width, height} = props;
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 20}
    height={height || 20}
    fill="none"
    {...props}
  >
    <g fill={fill || "#374957"} clipPath="url(#a)">
      <path d="M19.29 7.893 18.632 7a4.905 4.905 0 0 0-3.94-2H5.32a4.91 4.91 0 0 0-3.885 1.924L.75 7.82a3.682 3.682 0 0 0 .457 4.948l6.658 6.422a3.184 3.184 0 0 0 2.125.808 3.25 3.25 0 0 0 2.167-.834l6.613-6.36a3.658 3.658 0 0 0 .52-4.91Zm-2 .089.655.895c.066.091.124.188.173.29h-3.992A5.52 5.52 0 0 0 13.816 8l-.534-1.333h1.411a3.231 3.231 0 0 1 2.596 1.315ZM9.991 16.74l-2.308-5.225a3.869 3.869 0 0 1-.188-.682h4.96a3.63 3.63 0 0 1-.147.54L9.992 16.74ZM7.583 9.167c.035-.15.079-.297.131-.441l.82-2.06h2.953l.781 1.953c.069.178.124.361.167.548H7.583ZM2.76 7.934a3.237 3.237 0 0 1 2.56-1.267h1.417l-.57 1.44c-.137.342-.24.697-.305 1.06H1.87c.057-.118.126-.23.206-.334l.682-.899Zm-.417 3.611a1.978 1.978 0 0 1-.5-.712H5.82c.055.45.164.89.323 1.314l2.355 5.333-6.155-5.935Zm9.134 5.967L13.854 12a5.48 5.48 0 0 0 .287-1.167h4.026a2.084 2.084 0 0 1-.528.75l-6.163 5.929ZM10 3.333a.833.833 0 0 0 .833-.833V.833a.833.833 0 1 0-1.666 0V2.5a.833.833 0 0 0 .833.833ZM13.794 3.25a.833.833 0 0 0 1.123-.378l.833-1.666A.834.834 0 1 0 14.258.46l-.833 1.666a.833.833 0 0 0 .37 1.123ZM5.087 2.872a.835.835 0 1 0 1.496-.745L5.75.461a.834.834 0 0 0-1.492.745l.83 1.666Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
  );
}

const MemoSvgOportunityIcon = React.memo(OportunityIcon);
  export default MemoSvgOportunityIcon;
