import React, {useState} from 'react';
import {TalentMediaI} from '../../../interfaces/general';
import Masonry from 'react-responsive-masonry';
import {buttonStyle, circleIconStyle} from '../../../styles/classes';
import PencilIcon from '../../../assets/Icons/Interface/PencilIcon';
import TrashIcon from '../../../assets/Icons/Interface/TrashIcon';
import PopMessage from '../../../components/Form/PopMessage';
import {useMutation} from '@apollo/client';
import {talentMediaDeleteOne} from '../../../services/queries/mutations';

interface TalentMediaRenderI {
	media?:  TalentMediaI[]
	setTId?: (t:string) => void
	setForm?: (f:boolean) => void
	setLoading?: (f:boolean) => void
}

interface SelectedI{
	title?: string
	_id?: string
}

export default function MediaRender({media, setTId, setForm, setLoading}:TalentMediaRenderI){

	const [pop,setPop] = useState<boolean>(false);
	const [selected, setSelected] = useState<SelectedI>();
	const [entryDelete, { data, loading, error }] = useMutation(talentMediaDeleteOne);

	const startDelete = () => {
		entryDelete({
			variables : {
				_id: selected?._id
			},
			onCompleted: (data:any):void => finishSave()
		});
	}

	const finishSave = () => {
		setPop(false);
		setSelected({title: "", _id: ""});
		if(setLoading){
			setLoading(true);
		}
	}

	return (
		<div className="pt">
			<Masonry columnsCount={3} gutter={'1rem'}>
				{media && media?.length > 0 && media.map(({title, link, files, _id}:TalentMediaI)=>{
					return (
						<a key={_id} className="relative flex align-end justify-center w-100">
							{files && files.length > 0 ?
								<img src={files[files?.length - 1 || 0]?.url} className="w-100 obj-cover br obj-top" />
								:
								<div className="h-300px neutral-800 w-100 br">

								</div>
							}
							<div className="absolute bottom-0 w-100 flex justify-center">
								<div className="neutral-800 br-half phor pver-half flex align-center t-white relative mb f-urbanist f-12px f-700">
									<div className="flex align-center">
										{title}
									</div>
								</div>
							</div>
							<div className="flex top-1 right-1 absolute">
								<button
									className={`${circleIconStyle} mr-half`}
									onClick={()=>{
										if(setTId && _id){
											setTId(_id);
										}
										if(setForm) {
											setForm(true);
										}
									}}
								>
									<PencilIcon className="neutral-200" />
								</button>
								<button
									className={`${circleIconStyle}`}
									onClick={()=>{
										setPop(true);
										setSelected({title, _id})
									}}
								>
									<TrashIcon className="neutral-300" />
								</button>
							</div>
						</a>
					);
				})}
				<button className="w-100 aspect-16-9 neutral-900 br flex align-center b b-neutral-700 t-white justify-center cursor-pointer" onClick={()=>setForm ? setForm(true) : null}>
					+ Add Media
				</button>
			</Masonry>

			{pop &&
				<PopMessage
					title={`Do you want to delete "${selected?.title}"`}
					setPop={setPop}
				>
					<div>
						<p className="t-neutral-300 mt center lh-130">
							This action cannot be undone.
						</p>
						<div className="center flex justify-center mver">
							<a className={buttonStyle} onClick={startDelete}>
								Yes, Delete
							</a>
						</div>
						<div className="center f-14px">
							<a onClick={()=>setPop(false)}>
								Cancel
							</a>
						</div>

					</div>
				</PopMessage>
			}
		</div>
	)
}