import React, {useState} from 'react';
import {buttonStyle, circleIconStyle} from '../../../styles/classes';
import {AlertsI} from '../../../interfaces/general';
import {Link} from 'react-router-dom'
import PencilIcon from '../../../assets/Icons/Interface/PencilIcon';
import PopMessage from '../../../components/Form/PopMessage';
import AlertCreate from '../forms/AlertCreate';
import EllipsisIcon from '../../../assets/Icons/Interface/EllipsisIcon';
import {useMutation} from '@apollo/client';
import {culturalAlertDeleteOne, tacticDeleteOne} from '../../../services/queries/mutations';
import Boolean from '../../../components/Form/Boolean';

interface AlertsRenderI {
	alerts?: AlertsI[]
	setLoading: (l:boolean) => void
	setActive: (a:boolean) => void
	active: boolean
}

interface SelectedI{
	title?: string
	_id?: string
}

export default function AlertsRender({alerts, setLoading, setActive, active}:AlertsRenderI){

	const [pop, setPop] = useState(false);
	const [selected, setSelected] = useState<SelectedI>();
	const [popDelete, setPopDelete] = useState(false);

	const [alertDelete, { data, loading, error }] = useMutation(culturalAlertDeleteOne);

	const startDelete = () => {
		alertDelete({
			variables : {
				_id: selected?._id
			},
			onCompleted: (data:any):void => finishSave()
		});
	}

	const finishSave = () => {
		setPopDelete(false);
		setSelected({title: "", _id: ""});
		setLoading(true);
	}

	return (
		<div className="mt-2">
			<div className="flex align-center">
				<h5 className="f-urbanist f-700 mr">Opportunity Alerts</h5>
				<button
					onClick={()=>setPop(true)}
					className={buttonStyle}
				>
					+ New Alert
				</button>
				<div className="grows" />
				<div className="mb flex align-center">
					<label className="mr-half">
						{active ? "Published Opportunities" : "Draft Opportunities"}
					</label>
					<Boolean
						value={active ? active : false}
						setValue={()=> {
							setActive(!active);
							setLoading(true)
						}} />
				</div>

			</div>
			{pop &&
				<PopMessage
					title={`Create a new Opportunity`}
					setPop={setPop}
				>
					<div>
						<AlertCreate />
					</div>
				</PopMessage>
			}
			<div>
				<table className="list-table">
					<thead>
						<tr>
							<td>
								Title
							</td>
							<td>
								Respond By
							</td>
							<td>
								Created At
							</td>
							<td>
								Status
							</td>
							<td>
								Actions
							</td>
						</tr>
					</thead>
					<tbody>
					{alerts && alerts?.length > 0 && alerts.map(({title, _id, photos, active, endDate, createdAt}:AlertsI):JSX.Element => {
						return (
							<tr key={_id}>
								<td>
									<div className="flex align-center">
										{photos && photos.length > 0 ?
											<img src={photos[0].url} alt={title} className="w-100px aspect-1-1 obj-cover br" />
											:
											<div className="w-100px aspect-1-1 neutral-800 br" />
										}
										<span className="ml">{title}</span>
									</div>
								</td>

								<td>
									<div className="f-12px t-neutral-200">
										{endDate &&
											new Date(endDate).toLocaleString('en-US', {
												month: 'short',
												day: 'numeric',
												year: 'numeric'
											})
										}
									</div>
								</td>

								<td>
									<div className="f-12px t-neutral-200">
										{createdAt &&
											new Date(createdAt).toLocaleString('en-US', {
												month: 'short',
												day: 'numeric',
												year: 'numeric'
											})
										}
									</div>
								</td>

								<td>
									<div className="f-uppercase f-bold p-half f-12px b-neutral-300 inline-flex br-quarter mb-half">
										{active? "Published" : "Draft"}
									</div>
								</td>
								<td>
									<div className="justify-end flex align-center">
										<Link
											to={`/opportunities/form/${_id}`}
											className={`ml-half ${circleIconStyle}`}
										>
											<PencilIcon className="neutral-200" />
										</Link>
										<div className="ml submenu-hover relative">
											<EllipsisIcon />
											<div className="f-12px t-neutral-200 neutral-800 pver phor-half br-half submenu">
												<a onClick={()=>{
													setPopDelete(true);
													setSelected({title, _id})
												}}
												>
													Delete
												</a>
											</div>
										</div>

									</div>
								</td>
							</tr>
						)
					})}
					</tbody>
				</table>
			</div>

			{popDelete &&
				<PopMessage
					title={`Do you want to delete "${selected?.title}"`}
					setPop={setPopDelete}
				>
					<div>
						<p className="t-neutral-300 mt center lh-130">
							This action cannot be undone.
						</p>
						<div className="center flex justify-center mver">
							<a className={buttonStyle} onClick={startDelete}>
								Yes, Delete
							</a>
						</div>
						<div className="center f-14px">
							<a onClick={()=>setPopDelete(false)}>
								Cancel
							</a>
						</div>

					</div>
				</PopMessage>
			}

		</div>
	)
}
