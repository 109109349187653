import React, {useEffect, useState} from 'react';
import SocialRender from './Social.render';
import {useParams} from 'react-router-dom';
import {SocialCardI, UserEditI} from '../../../interfaces/general';
import {QueryTuple, useLazyQuery} from '@apollo/client';
import {query} from '../../../services/queries/queries';

export default function SocialLoad(){

	const {id} = useParams();
	const [loading, setLoading] = useState<boolean>(true);
	const [socialCard, setSocialCard] = useState<SocialCardI>();

	const [findSocialCard, { data }] = useLazyQuery(query("socialCardFindOne"));
	const [findUser, {data:user,loading:l,error:e}]:QueryTuple<any, any> = useLazyQuery(query('usersFindByIdAdmin'));

	useEffect(()=>{
		if(loading){
			findSocialCard({
				variables: {
					filter:{
						user: id
					}
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => {
					setSocialCard(data?.socialCardFindOne);
					setLoading(false);
				}
			});

			findUser({
				variables: {
					id
				},
				onCompleted: (data) => {
					console.log("USER FIND", data);
				},
				fetchPolicy:'network-only'
			});
		}
	},[loading]);

	return (
		<SocialRender
			socialCard={socialCard}
			ig={user?.usersFindByIdAdmin?.socialNetwork?.url}
			setLoading={setLoading}
		/>
	)
}