import {color} from 'styles/colors';

const variants:any={
	primary:`
		background-color: ${color.get('primary')};
		border: none;
	`,
	secondary:`
		background-color: rgba(0,0,0,0);
		border: 1px solid ${color.get('primary')};
	`
};

const sizes:any={
	large:`
		max-width:355px;
		height:50px;
		font-size: 0.875rem;
  		line-height: 1.313rem;
	`,
	small:`
		max-width:200px;
		height:30px;
		font-size: 0.75rem;
  		line-height: 1.125rem;
	`
};


export const getVariant=(v?:string):string=>variants[v || 'primary'];
export const getSize=(s?:string):string=>sizes[s || 'large'];
