import styled, { keyframes } from "styled-components";

export const Container = styled.button<{
  width: any;
  height: any;
  borderRadius: any;
  border: any;
  background: any;
  disabled: any;
}>`
position: relative;

  display: flex;
  align-items: center;
  font-size: 1.2rem;
  justify-content: center;
  padding: 5px 17px;
  gap: 10px;
  border-radius: ${({ borderRadius }): any =>
    borderRadius ? borderRadius : "40px"};
  border: ${({ border }): any => (border ? border : "none")};
  width: ${({ width }): any => (width ? width : "100%")};
  height: ${({ height }): any => (height ? height : "50px")};
  color: ${({ theme }) => theme.white};
  background-color: ${({ background, theme, disabled }): any => {
    if (disabled) {
      return theme.gray_200;
    }
    return background ? background : theme.red_500;
  }};
  cursor: pointer;
`;

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<{ isLoading?: any }>`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ${spinAnimation} 1s infinite linear;
  opacity: ${(props) => (props.isLoading ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  width: 20px;
  height: 20px;
  border: 2px solid ${({ theme }) => theme.primary};
  border-top-color: transparent;
  border-radius: 50%;
`;
