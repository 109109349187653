import React, {useEffect, useState} from 'react';
import ArticleForm from '../forms/ArticleForm';
import {Link} from 'react-router-dom';
import ArrowLeft from '../../../assets/Icons/Arrows/ArrowLeft';
import {ArticleI} from '../../../interfaces/general';
import ArticlePreview from './Article.preview';

interface IArticlesWork {
	id? : number | string
	hotlist? : ArticleI
}

export default function ArticlesWork({id, hotlist}:IArticlesWork){
	const [article, setArticle] = useState<ArticleI>({});
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(()=>{
		if(hotlist && id){
			setArticle({...hotlist});
			setLoading(false);
		}
		else{
			setLoading(false);
		}
	},[hotlist]);

	return (
		<div className="flex">
			<div className="grows">
				<Link to="/hotlists/articles" className="t-white flex align-center mb-2">
					<span className="mr"><ArrowLeft /></span> Back to Articles
				</Link>
				<ArticlePreview article={article} />
			</div>

			<div className="neutral-800 b-neutral-700-t-l br-tl p-2 bottom-0 flex form-enter overflow-y-scroll relative min-w-550px max-w-600px">
				<div className="grows">
					{!loading &&
						<ArticleForm setArticle={setArticle} article={article} id={id} />
					}
				</div>
			</div>
		</div>

	)
}
