import React, {useEffect, useState} from 'react';
import {GenderI, IndustryI, UserEditI} from '../../../interfaces/general';
import {buttonStyle, tagStyle} from '../../../styles/classes';
import {useParams} from 'react-router-dom';
import FileUpload from '../../../components/Form/FileUpload';
import {useMutation} from '@apollo/client';
import {talentUpdateOneAdmin, upsertSocialNetwork} from '../../../services/queries/mutations';
import IndustriesSelector from '../../../components/Form/IndustriesSelector';
import SegmentsSelector from '../../../components/Form/SegmentsSelector';

interface ProfilerenderI {
	user?: UserEditI
	genders?: GenderI[]
	industries?: IndustryI[]
	segments?: IndustryI[]
}

interface UserI{
	description?:string
	gender?:string
	industries?: string[]
	segments?: string[]
}

export default function ProfileRender({user, industries, segments, genders}:ProfilerenderI){

	const {id} = useParams();
	const [success, setSuccess] = useState<string>("");
	const [userForm, setUserForm] = useState<UserI>();

	const [updateTalent, { data, loading, error }] = useMutation(talentUpdateOneAdmin);

	useEffect(()=>{
		if(user?.userObj){
			setUserForm({
				description: user?.description,
				gender: user?.gender,
				industries: user?.industry || [],
				segments: user?.industrySegment || []
			});
		}
	},[user]);

	useEffect(()=>{
		const delayDebounceFn = setTimeout(() => {
			setSuccess("")
			// Send Axios request here
		}, 2000)

		return () => clearTimeout(delayDebounceFn)
	},[success]);

	const saveProfile = () => {
		updateTalent({
			variables: {
				user: id,
				description: userForm?.description,
				gender: userForm?.gender,
				industry: userForm?.industries || [],
				industrySegment: userForm?.segments || []
			},
			onCompleted: (data): void => {
				setSuccess("Account updated")
			}
		});
	}

	return (
		<div className="flex w-100">
			<div className="mb">
				<label>
					File
				</label>
				<FileUpload
					fileUrl={user?.userObj?.profilePicture?.url || ""}
					endpoint={"admin/update-profile-picture"}
					user={id}
					format="none"
				/>
			</div>

			<div className="ml-2 grows">
				<p className="mb">
					<label>
						Gender
					</label>
					<select value={userForm?.gender} onChange={(e)=>setUserForm({...userForm, gender:e.target.value})}>
						<option value="">Select One...</option>
						{genders && genders?.length > 0 && genders?.map(({_id, label, identifier}:GenderI)=>{
							return (
								<option key={_id} value={label}>
									{label}
								</option>
							)
						})}
					</select>
				</p>
				<div className="grid2 col-gap-2">
					<div className="mb">
						<label>
							Industry
						</label>
						<IndustriesSelector selected={userForm?.industries || []} setSelected={(i)=> setUserForm({...userForm, industries: i})} />
					</div>
					<div className="mb">
						<label>
							Segments
						</label>
						<SegmentsSelector selected={userForm?.segments || []} setSelected={(s) => setUserForm({...userForm, segments: s})}/>
					</div>
				</div>
				<p className="mb">
					<label>
						Bio
					</label>
					<textarea value={userForm?.description} name="description" placeholder="Bio" onChange={(e)=>setUserForm({...userForm, description:e.target.value})} />
				</p>

				<div className="pb-2 mt-2">
					{loading ?
						<p className="f-12px">
							"Saving Talent Profile..."
						</p>
						:
						<button className={buttonStyle} onClick={()=>saveProfile()}>
							Save
						</button>
					}

					{error && <p className="t-orange f-12px mt">{error?.message}</p>}
					{success && <p className="t-green f-12px mt">{success}</p>}
				</div>
			</div>
		</div>
	)
}