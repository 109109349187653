import { FC } from "react";

import { CloseSvg } from "assets";

import { ModalContainer, ModalContent } from "./styles";

export const Modal: FC<any> = ({ children, isOpen, setShowModal }) => {

  const handleClose = () => {
    setShowModal(!isOpen);
  };

  return (
    <ModalContainer>
      <ModalContent>
        <div style={{ position: "absolute", top: "40px", left: "10px" }} onClick={handleClose}>
          <CloseSvg stroke="#fff"  />
        </div>
        {children}
      </ModalContent>
    </ModalContainer>
  );
};
