import styled from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const StyledHeadCell = styled.th`
  padding: 20px;
  text-align: left;
  font-size: 14px;
  color: #636363;
  font-weight: bold
`;

export const StyledCell = styled.td`
  padding: 20px;
  position: relative;
  text-align: left;
  font-size: 12px;
  border-top: 2px solid #191919;
  border-bottom: 2px solid #191919;


  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 1px solid #191919;
  }

  &:first-child::before {
    border-left: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-right: 1px solid #191919;
  }

  &:last-child::after {
    border-right: none;
  }
`;
