export const theme = {
    black: '#000000',
    white: '#FFFFFF',
    gray_100: '#B8B8B8',
    gray_200: '#636363',
    gray_500: '#191919',
    red_500: '#FF5656',

    //new Opus theme
    color : {
        black: '#000000',
        white: '#FFFFFF',
        red: '#FF5656',
        grey_1000: '#0E0F14',
        grey_900: '#13141A',
        grey_800: '#1E2028',
        grey_600: '#262932',
        grey_300: '#5B5E7A'
    }
};
  