import axios from 'axios';

interface RequestI {
	endpoint: string,
	params?: any
	id?:number | string
}

interface SaveI {
	endpoint: string,
	data?: any,
	id?:number | string
}

export const getEntries = async ({endpoint, params}:RequestI) => {
	try {
		const entries = await axios.get(
			`${process.env.REACT_APP_CONTENT_API}${endpoint}`,
			{
				headers : {
					'Content-Type': 'application/json',
					Authorization : `Bearer ${process.env.REACT_APP_CONTENT_API_TOKEN}`
				},
				params : {
					...params
				}
			}
		);
		console.log(entries);
		return(entries.data);
	} catch (error) {
		console.log(error);
	}
}

export const getEntry = async ({endpoint, params, id}:RequestI) => {
	try {
		const entries = await axios.get(
			`${process.env.REACT_APP_CONTENT_API}${endpoint}/${id}`,
			{
				headers : {
					'Content-Type': 'application/json',
					Authorization : `Bearer ${process.env.REACT_APP_CONTENT_API_TOKEN}`
				},
				params : {
					...params
				}
			}
		);
		return(entries.data);
	} catch (error) {
		console.log(error);
	}
}

export const saveEntry = async ({endpoint, data}:SaveI) => {
	try {
		const save = await axios.post(
			`${process.env.REACT_APP_CONTENT_API}${endpoint}`,
			{
				data
			},
			{
				headers : {
					'Content-Type': 'application/json',
					'Authorization' : `Bearer ${process.env.REACT_APP_CONTENT_API_TOKEN}`
				}
			}
		);
		console.log(save);
	} catch (error) {
		console.log(error);
	}
}

export const saveSingleEntry = async ({endpoint, data}:SaveI) => {
  try {
    const save = await axios.put(
      `${process.env.REACT_APP_CONTENT_API}${endpoint}`,
      {
        data
      },
      {
        headers : {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${process.env.REACT_APP_CONTENT_API_TOKEN}`
        }
      }
    );
    console.log(save);
  } catch (error) {
    console.log(error);
  }
}

export const updateEntry = async ({endpoint, data, id}:SaveI) => {
	try {
		const save = await axios.put(
			`${process.env.REACT_APP_CONTENT_API}${endpoint}/${id}`,
			{
				data
			},
			{
				headers : {
					'Content-Type': 'application/json',
					'Authorization' : `Bearer ${process.env.REACT_APP_CONTENT_API_TOKEN}`
				}
			}
		);
		return save;
	} catch (error) {
		console.log(error);
	}
}

export const deleteEntry = async ({endpoint, id}:SaveI) => {
	try {
		await axios.delete(
			`${process.env.REACT_APP_CONTENT_API}${endpoint}/${id}`,
			{
				headers : {
					'Content-Type': 'application/json',
					'Authorization' : `Bearer ${process.env.REACT_APP_CONTENT_API_TOKEN}`
				}
			}
		);
	} catch (error) {
		console.log(error);
	}
}

export const uploadPhoto = async (data:any) => {
	try {
		const save = await axios.post(
			`${process.env.REACT_APP_CONTENT_API}upload`,
			data,
			{
				headers : {
					'Content-Type': 'multipart/form-data',
					'Authorization' : `Bearer ${process.env.REACT_APP_CONTENT_API_TOKEN}`
				}
			}
		);
		console.log(save);
		return save;
	} catch (error) {
		console.log(error);
	}
}
