import React, {useEffect, useState} from 'react';
import MediaRender from './Media.render';
import {useParams} from 'react-router-dom';
import {useLazyQuery} from '@apollo/client';
import {query} from '../../../services/queries/queries';
import {buttonStyle} from '../../../styles/classes';
import MediaForm from './Media.form';

export default function MediaLoad(){
	const {id}:any = useParams();

	const [loading, setLoading] = useState<boolean>(true);
	const [form, setForm] = useState(false);
	const [tId, setTId] = useState<string>("");

	const [getData, { data }] = useLazyQuery(query('talentMediaFindMany'));

	useEffect(():void=>{
		if(loading){
			getData({
				variables: {
					filter: {
						user: id
					},
					limit: 15
				},
				fetchPolicy: 'no-cache',
				onCompleted: (data:any):void => setLoading(false)
			});
		}
	},[loading]);

	return (
		<div>
			<div className="flex align-center pver-2">
				<h5 className="f-urbanist f-700 mr">Talent Media</h5>
				<button className={`${buttonStyle} flex align-center`} onClick={()=>setForm(true)}>
					+ Add Media
				</button>
			</div>

			<MediaRender media={data?.talentMediaFindMany} setTId={setTId} setLoading={setLoading} setForm={setForm} />
			{form &&
				<div className="fixed right-0 top-2 neutral-800 b-neutral-700-t-l br-tl p-2 bottom-0 flex form-enter overflow-y-scroll">
					<div className="grows">
						<MediaForm setForm={setForm} setLoading={setLoading} tId={tId} setTId={setTId} />
					</div>
				</div>
			}
		</div>

	)
}