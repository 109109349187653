import React from 'react';

interface IconI {
	size?: number
	className?: string
}

export default function ReportIcon({size=16, className='white'}:IconI) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M5 0C4.20435 0 3.44129 0.31607 2.87868 0.87868C2.31607 1.44129 2 2.20435 2 3V7C2 7.55228 2.44772 8 3 8C3.55228 8 4 7.55228 4 7V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H12V5C12 5.79565 12.3161 6.55871 12.8787 7.12132C13.4413 7.68393 14.2044 8 15 8H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.44772 20 2 20.4477 2 21C2 21.5523 2.44772 22 3 22H17C17.7957 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7957 20 19V7V6C20 5.73478 19.8946 5.48043 19.7071 5.29289L14.7071 0.292893C14.5196 0.105357 14.2652 0 14 0H13H5ZM14 2.41421V5C14 5.26522 14.1054 5.51957 14.2929 5.70711C14.4804 5.89464 14.7348 6 15 6H17.5858L14 2.41421ZM0 14C0 13.4477 0.447715 13 1 13H9.58579L8.29289 11.7071C7.90237 11.3166 7.90237 10.6834 8.29289 10.2929C8.68342 9.90237 9.31658 9.90237 9.70711 10.2929L12.7071 13.2929C13.0976 13.6834 13.0976 14.3166 12.7071 14.7071L9.70711 17.7071C9.31658 18.0976 8.68342 18.0976 8.29289 17.7071C7.90237 17.3166 7.90237 16.6834 8.29289 16.2929L9.58579 15H1C0.447715 15 0 14.5523 0 14Z" className={className}/>
		</svg>
	)
}