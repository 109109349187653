import React, {useEffect, useState} from 'react';
import {deleteEntry, getEntries} from '../../../services/hotlists/hotlists.service';
import TagsRender from './Tags.render';
import TagForm from '../forms/TagForm';

export default function TagsLoad (){

	const [loading, setLoading] = useState<boolean>(true);
	const [tags, setTags] = useState([]);
	const [form, setForm] = useState(false);
	const [tagId, setTagId] = useState<string | number>();

	useEffect(():void => {
		if(loading) {
			loadData();
			setLoading(false);
		}
	}, [loading])

	const loadData = async () => {
		const h = await getEntries({
			endpoint: "tags",
			params: {
				"populate[0]": "cover",
				"sort": "title"
			}
		});
		setTags(h.data);
	}

	const deleteTag = async (id:string|number) => {
		await deleteEntry({endpoint: 'tags', id});
		setLoading(true);
		return true
	}

	return (
		<div>
			<TagsRender tags={tags} setForm={setForm} setTagId={setTagId} deleteTag={deleteTag} />

			{form &&
				<div className="fixed right-0 top-2 neutral-800 b-neutral-700-t-l br-tl p-2 bottom-0 flex form-enter overflow-y-scroll">
					<div className="grows">
						<TagForm setForm={setForm} setLoading={setLoading} tagId={tagId} setTagId={setTagId} />
					</div>
				</div>
			}
		</div>
	);
}
