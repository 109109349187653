import React, {useEffect, useState} from 'react'
import {getEntries} from '../../../services/hotlists/hotlists.service';
import HotlistsHomeRender from './Home.render';
import HomeBoxForm from '../forms/HomeForm';
import {buttonStyle} from '../../../styles/classes';
import {TagRowI, HomeDataI, TagI} from '../../../interfaces/general';

export default function HotlistsHomeLoad(){

	const [loading, setLoading] = useState<boolean>(true);
	const [form, setForm] = useState(false);

	const [home, setHome] = useState<any>();
	const [homeData, setHomeData] = useState<HomeDataI>();

	useEffect(()=>{
		if(loading){
			getHome();
		}
	},[loading]);

	useEffect(()=>{
		let hd:HomeDataI = {
			tagRow : home?.tagRow.map(({title, tags, cover, titleColor}:any):TagRowI=>{
				return {
					title,
          titleColor,
					cover: cover?.data?.attributes?.url || "",
					coverId: cover?.data?.id,
					tags: tags?.data?.map(({attributes, id}:any):TagI=>{
						return {
							id,
							title: attributes?.title || ""
						}
					})
				}
			})
		};
		console.log("HD", hd);
		setHomeData(hd);
	},[home]);

	const getHome = async () => {
		const home = await getEntries({
			endpoint: "hotlist-home",
			params: {
				"populate[0]" : "tagRow",
				"populate[1]" : "Feed",
				"populate[2]" : "tagRow.cover",
				"populate[3]" : "tagRow.tags",
				"populate[4]" : "Feed.tags",
			}
		});
		setLoading(false);
		setHome(home.data.attributes);
	}

	return (
		<div>
			<div className="flex align-center">
				<h5 className="f-urbanist f-700 mr">Hotlists Home</h5>
				<button className={buttonStyle} onClick={()=>setForm(true)}> Edit View</button>
			</div>

			<HotlistsHomeRender homeData={homeData} />

			{form &&
				<div className="fixed right-0 top-2 neutral-800 b-neutral-700-t-l br-tl p-2 bottom-0 flex form-enter overflow-y-scroll">
					<div className="grows">
						<HomeBoxForm homeData={homeData} setHomeData={setHomeData} setForm={setForm} />
					</div>
				</div>
			}
		</div>
	)
}
