import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 7vh;
    position: sticky;
    top:0;
    display: flex;
    flex-direction: column;
  background: ${({ theme }) => theme.black};
  @media (min-width: 768px) {
    border-right: 1px solid ${({ theme }) => theme.gray_200};
    width: 300px;
    height: 100vh;
  }
`;

export const Header = styled.header`
  width: 100vw;
  overflow: hidden;
  height: 7vh;
  background: ${({ theme }) => theme.white};
  position: absolute;
  top: 0;
  right: 0;
  -webkit-box-shadow: 0px 3px 15px 0px ${({ theme }) => theme.shadow};
  -moz-box-shadow: 0px 3px 15px 0px ${({ theme }) => theme.shadow};
  box-shadow: 0px 3px 15px 0px ${({ theme }) => theme.shadow};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerPhoto = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderText = styled.h3`
  display: flex;
  align-items: center;
  margin-left: 15px;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.violet_200};
  @media (min-width: 768px) {
    font-size: 1.6rem;
    margin-left: 350px;
  }
`;

export const ContainerProfile = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 10px;
  align-items: center;
`;

export const Profile = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const TitleLogo = styled.h1`
  color: ${({ theme }) => theme.violet_400};
  margin-left: 23%;
  margin-top: 24px;
`;

export const LogoImg = styled.img`
    width: 100px;
    height: 15px;
    object-fit: contain;
`;

export const Logo = styled.div`
  display: none;
    margin-bottom: 30px;
  @media (min-width: 768px) {
    display: flex;
    width: 100%;
    height: 40px;
      padding: 20px 0;
  }
`;
export const Divider = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
    height: 1px;
    width: 100%;
    background: ${({ theme }) => theme.gray_100};
    margin: 10px 0;
  }
`;

export const SidebarNav = styled.nav`
    padding: 10px;
    flex-grow: 1;
`;

export const ContainerList = styled.div<{ $isActive?: any }>`
  background: ${({ theme }) => theme.white};
  margin: 8px 0;
  border-top: 2px solid
    ${({ $isActive, theme }) =>
      $isActive ? "theme.primary_200" : "transparent"};
  @media (min-width: 768px) {
    border-top: none;
    background: ${({ $isActive, theme }) =>
      $isActive ? theme.gray_500 : "transparent"};
  }
`;

export const ContainerListSecondary = styled.div<any>`
  display: none;
  @media (min-width: 768px) {
    display: block;
    border-radius: 6px;
    margin: 8px 0;
  }
`;

export const List = styled(Link)<{ $mobile?: any }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  color: ${({ theme }) => theme.gray_400};
  font-size: 16px;
  @media (min-width: 768px) {
    display: ${({ $mobile }) => (!$mobile ? "flex" : "none")};
    flex-direction: row;
    padding: calc(8px - 2px) 0;
  }
`;
export const ListModal = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: ${({ theme }) => theme.gray_400};
  font-size: 16px;
`;

export const IconContainer = styled.div`
  padding: 2px 0;
  @media (min-width: 768px) {
    padding: 8px 16px;
  }
`;

export const Label = styled.span<{
  theme?: any;
  badge?: any;
  $isActive?: boolean;
}>`
  flex: 1;
  color: ${({ badge, theme, $isActive }) =>
    $isActive ? theme.white : theme.gray_100};
  font-size: 1.4rem;
  @media (min-width: 768px) {
    font-size: 1.8rem;
    display: block;
  }
`;

export const Badge = styled.div`
  font-size: 14px;
  padding: calc(8px / 2) 8px;
  border-radius: calc(6px / 2);
  background: ${({ theme }) => theme.violet_100};
  color: ${({ theme }) => theme.violet_300};
  font-weight: bold;
  margin-right: 16px;
  margin-left: 10px;
  @media (min-width: 768px) {
    display: block;
  }
`;

export const SThemeLabel = styled.span<{ theme: any }>`
  display: block;
  flex: 1;
  padding: 10px;
  color: ${({ theme }) => (theme === "dark" ? "#fff" : "#000")};
`;

export const SToggleThumb = styled.div<{ isActive: any }>`
  height: 18px;
  width: 18px;
  position: absolute;
  top: 1px;
  bottom: 1px;
  transition: 0.2s ease right;
  right: calc(100% - 18px - 1px);
  border-radius: 50%;
  background: ${({ theme, isActive }) =>
    !isActive ? theme.yellow_300 : theme.yellow_100};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.gray_300};
  font-size: 1.6rem;
  margin-left: 5px;
`;

export const Photo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const ContainerSingUp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WrapperSingUp = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const TextSingUp = styled.p`
  font-size: 1.2rem;
  margin-left: 10px;
`;

export const WrapperContainSingup = styled.div`
  padding: 20px;
`;
