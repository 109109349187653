import React from 'react';
import {Item,SelectProps} from 'interfaces';
import {SelectOption,StyledSelect} from './styles';
import {InputWrapper,Label,RequiredText} from 'styles/ui';

export default function SelectLoad({
	label,
	required,
	className,
	unselected,
	options,
	defaultValue,
	placeholder='Select an option',
	...props
}:SelectProps):JSX.Element{

	return (
		<InputWrapper className={className}>
			<div>
				<div>
					<Label>{label}</Label>
					{required && <RequiredText>Required</RequiredText>}
				</div>
				<StyledSelect
					defaultValue={defaultValue}
					unselected={unselected}
					{...props}
					required={required}
				>
					{(placeholder && !defaultValue) && <SelectOption value='' disabled>{placeholder}</SelectOption>}
					{options?.map((opt:Item):JSX.Element=>
						<SelectOption
							key={opt.id}
							value={opt.id}
						>
							{opt.label}
						</SelectOption>
					)}

				</StyledSelect>

			</div>
		</InputWrapper>
	);
}
