import { FC } from "react";
import { FilterTagWrapper, FilterName, RemoveFilterButton } from "./styles";

interface FilterTagProps {
  name: string;
  onRemove: () => void;
}

export const FilterTag: FC<FilterTagProps> = ({ name, onRemove }) => {
  return (
    <FilterTagWrapper>
      <FilterName>{name}</FilterName>
      <RemoveFilterButton onClick={onRemove}>×</RemoveFilterButton>
    </FilterTagWrapper>
  );
};
