import React, {useEffect} from 'react'
import {tagStyle} from '../../../styles/classes';
import CloseIcon from '../../../assets/Icons/Interface/CloseIcon';
import CalendarIcon from '../../../assets/Icons/Interface/CalendarIcon';
import {ArticleI} from '../../../interfaces/general';
import EmbedPreview from '../components/EmbedPreview';

interface PreviewI {
	article?: ArticleI
	setPop?: (p:boolean)=>void
}

export default function ArticlePreview({article, setPop}:PreviewI) {

	return (
		<div className="relative">
			{setPop &&
				<div className="flex justify-end">
					<a className="w-2rem aspect-1-1 br-50 neutral-1000 b-neutral-700 flex align-center justify-center" onClick={()=>setPop? setPop(false) : null}>
						<CloseIcon />
					</a>
				</div>
			}

			<div className="phor">
				<div className="max-w-500px mhor-auto">
					<h2 className="center f-urbanist f-700 mb half f-2-5-rem lh-120">
						{article?.title}
					</h2>
					<h4 className="center f-urbanist f-400 f-1rem t-neutral-200 lh-120">
						{article?.subtitle}
					</h4>
				</div>

				{article?.cover &&
					<img src={`${article?.cover}`} className="w-100 br obj-cover mver-2 aspect-16-9" />
				}

				{article?.publishDate &&
					<div className="flex align-center f-urbanist f-12px mb-2">
						<CalendarIcon />
						<span className="ml-half">
								{article?.publishDate &&
									new Date(article.publishDate.replace(/-/g, '/')).toLocaleString('en-US', {
										month: 'short',
										day: 'numeric',
										year: 'numeric'
									})
								}
							</span>

					</div>
				}

				<div className="flex mb-1half">
					{article?.tags && article?.tags?.length > 0 && article?.tags?.map(({id, title}:any)=>{
						return (
							<div key={`tag-preview-${id}`} className={tagStyle}>
								#{title}
							</div>
						)
					})}
				</div>

				{article?.body &&
					<div className="f-urbanist lh-220 f-1rem body" dangerouslySetInnerHTML={{__html: article?.body || ""}}/>
				}
				{article?.embed &&
					<EmbedPreview embed={article.embed} />
				}
			</div>

		</div>
	)
}
