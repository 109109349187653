import React, { useState } from 'react'
import { Industries } from './Industries'
import { QueryResult, useMutation, useQuery } from '@apollo/client';
import { query } from 'services/queries/queries';
import { EllipsisSvg } from 'assets';
import { Popup } from 'components/Atoms/Popup/Popup';
import { DivLink } from 'components/Atoms/TableRow/styles';
import Divider from 'components/Atoms/Divider/Divider';
import { useForm } from 'hooks/useForm/useForm';
import { FormHook, FormField } from 'hooks/useForm/interfaces';
import { useToast } from 'context/toastContext/toastContext';
import { CREATE_INDUSTRY_ONE, DELETE_INDUSTRY_ONE, UPDATE_INDUSTRY_ONE } from 'services/queries/mutations';


export const IndustriesLoad = () => {

    const [industryCreateOne] = useMutation(CREATE_INDUSTRY_ONE);
    const [industryUpdateOne] = useMutation(UPDATE_INDUSTRY_ONE);
    const [industryDeleteOne] = useMutation(DELETE_INDUSTRY_ONE);

    const [showPopupIndex, setShowPopupIndex] = useState(-1);
    const [nameIndustry, setNameIndustry] = useState<any>(null);
    const [showModalCreate, setShowModalCreate] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [editingIndustry, setEditingIndustry] = useState<any>(null);

    const { error, success } = useToast();

    const handleInfoUpdate = (data: any) => {
        setEditingIndustry(data);
        setShowModalCreate(true);
    };

    const columns = [
        { header: "Label", accessor: "label" },
        { header: "Identifier", accessor: "identifier" },
        {
            header: "Action",
            accessor: "action",
            Cell: (row: any) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}
                >
                    <div style={{ position: "relative" }}>
                        <EllipsisSvg
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setShowPopupIndex(row.id);
                            }}
                        />
                        {showPopupIndex === row.id && (
                            <Popup onClose={() => setShowPopupIndex(-1)}>
                                <DivLink onClick={() => handleInfoUpdate(row)}>
                                    <p>Edit Industry</p>
                                </DivLink>
                                <Divider />
                                <DivLink onClick={() => {
                                    setNameIndustry(row.label)
                                    setShowConfirmModal(true)
                                }}>
                                    <p>Deleted Industry</p>
                                </DivLink>
                            </Popup>
                        )}
                    </div>
                </div>
            ),
        },
    ];

    const {
        data,
        loading: loadingQuery,
        error: errorQuery,
        refetch,
    }: QueryResult<
        any,
        { skip: number; limit: number; search?: any; filter: any }
    > = useQuery(query("industryFindMany"));

    const formFields: FormField[] = [
        {
            name: "label",
            required: true,
            default: editingIndustry ? editingIndustry?.label : "",
        },
        {
            name: "identifier",
            default: editingIndustry ? editingIndustry?.identifier : "",
        },
    ];

    const handleDelete = () => {
        try {
            industryDeleteOne({
                variables: {
                    id: showPopupIndex,
                },
            });
            success("Create Industry Segment successfully!");
            refetch();
            setShowConfirmModal(false);
            setNameIndustry(null);
        } catch (err) {
            console.log(err);
            error("An error occurred with create!");
        }
    }

    const actionForm = async (data: any): Promise<void> => {
        if (showPopupIndex !== -1) {
            await editIndustryAction(data);
        } else {
            await registerIndustryAction(data);
        }

        resetForm();
        setEditingIndustry(null);
    };

    const editIndustryAction = async (data: any): Promise<void> => {
        console.log('data', showPopupIndex)
        try {
            await industryUpdateOne({
                variables: {
                    id: showPopupIndex,
                    label: data.label,
                },
            });
            success("Create Tenant successfully!");
            refetch();
            setShowModalCreate(false);
        } catch (err) {
            console.log(err);
            error("An error occurred with create!");
        }
    };

    const registerIndustryAction = async (data: any): Promise<void> => {

        try {
            await industryCreateOne({
                variables: {
                    label: data.label,
                },
            });
            success("Create Industry successfully!");
            refetch();
            setShowModalCreate(false);
            refetch();
        } catch (err) {
            console.log(err);
            error("An error occurred with create!");
        }
    };



    const {
        data: formData,
        errors,
        loading,
        resetForm,
        ...rest
    }: FormHook = useForm(formFields, actionForm);

    if (loadingQuery) return <p>Loading...</p>;
    if (errorQuery) return <p>Error</p>;


    const dataIndustry = data.industryFindMany.map((item: any) => ({
        ...item,
        id: item._id,
    }));
    return (
        <Industries
            data={dataIndustry}
            columns={columns}
            setShowModalCreate={setShowModalCreate}
            showModalCreate={showModalCreate}
            setEditingIndustry={setEditingIndustry}
            editingIndustry={editingIndustry}
            onSubmit={rest.handleSubmitForm}
            submitLoading={loading}
            registerInput={rest.registerInput}
            errors={errors}
            handleDelete={handleDelete}
            showConfirmModal={showConfirmModal}
            setShowConfirmModal={setShowConfirmModal}
            nameIndustry={nameIndustry}
        />
    )
}
