import React from 'react';

interface IconI {
	size?: number
	className?: string
}

export default function ResyncIcon({size=16, className='white'}:IconI) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M10 0L9.99624 1.41263e-05C7.22213 0.01045 4.55946 1.0929 2.56496 3.02104L2.55289 3.0329L0.292893 5.2929C-0.0976311 5.68342 -0.0976311 6.31659 0.292893 6.70711C0.683418 7.09764 1.31658 7.09764 1.70711 6.70711L3.96097 4.45325C5.58329 2.88786 7.7473 2.00897 10.0019 2.00001C12.1229 2.0005 14.157 2.8433 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 10.5523 18.4477 11 19 11C19.5523 11 20 10.5523 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92894C15.1957 1.05358 12.6522 7.03335e-06 10 0ZM4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 9.44771 1.55228 9 1 9C0.447715 9 1.49012e-08 9.44771 1.49012e-08 10C1.49012e-08 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20L10.0038 20C12.7779 19.9896 15.4405 18.9071 17.435 16.979L17.4471 16.9671L19.7071 14.7071C20.0976 14.3166 20.0976 13.6834 19.7071 13.2929C19.3166 12.9024 18.6834 12.9024 18.2929 13.2929L16.039 15.5467C14.4167 17.1122 12.2526 17.9911 9.99803 18C7.87701 17.9995 5.84297 17.1567 4.34315 15.6569Z" className={className}/>
			<path fillRule="evenodd" clipRule="evenodd" d="M2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1V6C0 6.55228 0.447715 7 1 7H6C6.55228 7 7 6.55228 7 6C7 5.44772 6.55228 5 6 5H2V1ZM14 13C13.4477 13 13 13.4477 13 14C13 14.5523 13.4477 15 14 15H18V19C18 19.5523 18.4477 20 19 20C19.5523 20 20 19.5523 20 19V14C20 13.4477 19.5523 13 19 13H14Z" className={className}/>
		</svg>
	)
}