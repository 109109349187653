import React, { FC, useContext } from "react";
import { FormField, FormHook } from "hooks/useForm/interfaces";
import { useForm } from "hooks/useForm/useForm";

import { User, UpdateTalentData } from "interfaces";

import { UserContext } from "context/userContext/userContext";
import { ContactForm } from "./ContactForm";
import { query } from "services/queries/queries";
import { QueryResult, useQuery } from "@apollo/client";

interface ContactFormLoadProps {
  phone: string;
  address: {  
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
  website: string;
  handleUpdate: (data: UpdateTalentData) => void;
}

export const ContactFormLoad:FC<ContactFormLoadProps> = ({phone, address, website, handleUpdate}) => {

  const formFields: FormField[] = [
    { name: "phone", required: false, default:phone ||  "" },
    { name: "website", required: false, default: website || "" },
    { name: "address1", required: false, default: address?.address1 ||  "" },
    { name: "address2", required: false, default: address?.address2 || "" },
    { name: "city", required: false, default: address?.city || "" },
    { name: "state", required: false, default: address?.state || "" },
    { name: "zip", required: false, default: address?.zip || "" },
  ];

  const handleSubmit = async (formData: any): Promise<void> => {
    handleUpdate({
      address: {
        address1: formData.address1,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
      },
      ...formData
    });
  };

  const { data, errors, loading, ...rest }: FormHook = useForm(
    formFields,
    handleSubmit
  );

  return (
    <ContactForm
      submitLoading={loading}
      errors={errors}
      registerInput={rest.registerInput}
      onSubmit={rest.handleSubmitForm}
    />
  );
};
