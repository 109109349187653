import styled,{css} from 'styled-components';
import {getSize,getVariant} from '../themes/button';
import {color} from '../colors';

interface ButtonStyleProps{
	variant:string,
	size:string
}

export const buttonStyles:any=css`
  color: white;
  width: 100%;
  text-transform: uppercase;
  padding: 0 2rem;
  font-weight: 600;
  ${({variant}:ButtonStyleProps):string=>getVariant(variant)}
  ${({size}:ButtonStyleProps):string=>getSize(size)}
  
  margin: 0.5rem 0;
  border-radius: 40px;
  font-family: Inter, sans-serif;

  cursor:pointer;
  :focus {
    outline: 2px solid ${color.get('neutral-400')};
  }
  
  :disabled{
	background-color: ${color.get('neutral-600')};
	color: ${color.get('neutral-400')};
  }
`;
export const StyledButton:any=styled.button<ButtonStyleProps>`
	${buttonStyles}
`;