import { FC, useContext } from 'react'
import { Navigate, useLocation, Outlet } from "react-router-dom";


import Sidebar from '../SideBar/Sidebar'

import { Container, LayoutStyle } from './style'
import { UserContext } from 'context/userContext/userContext';

export const Layout: FC<any> = ({children}) => {
  // const { user } = useContext(UserContext);
  // const location = useLocation();


  // if (!user) {
  //   return <Navigate to="/login" state={{ from: location }} />;
  // }

  return (
      <div className="flex w-100">
          <div className="relative w-250px shrinks-0" style={{overflow: "visible"}}>
              <Sidebar />
          </div>
          <Container id="container">
              <Outlet />
          </Container>
      </div>
  )
}
