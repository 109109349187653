import React from "react";

function Error(props: any) {
    const {stroke, width, height} = props;
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 14}
        height={height || 14}
        fill="none"
        {...props}
      >
        <circle cx={7} cy={7} r={7} fill="#FD5464" />
        <path stroke="#fff" d="M4 10.59 10.588 3M4 3l6.177 7.59" />
      </svg>
    );
  }
  const MemoSvgError = React.memo(Error);
  export default MemoSvgError;
  