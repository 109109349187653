import React, { FC, useState } from "react";

import { Content, TabsWrapper, Title, LinkTab, Image, Label, LinkNavigation } from "./style";
import { ProfileFormLoad } from "./Components/ProfileForm/ProfileForm.load";
import { ContactFormLoad } from "./Components/ContactForm/ContactForm.load";
import { EthnicityLoad } from "./Components/Ethnicity/Ethnicity.load";
import { FamilyFormLoad } from "./Components/FamilyForm/FamilyForm.load";
import { AccountLoad } from "./Components/Account/Account.load";
import { BoxSvg } from "assets";
import { CameraIcon } from "assets/Icons/Interface/CameraIcon";
import { Link } from "react-router-dom";
import { updatePhoto } from "services/files/files.service";

export interface ProfileEditRenderProps {
  path: string;
  data: {
    user: string;
    userObj: {
      firstName: string;
      lastName: string;
      email: string;
      password?: string;
      profilePicture?: {
        url: string;
      };
    };
    birthday: string;
    gender: string;
    description: string;
    ethnicity: string[];
    phone: string;
    email: string;
    industry: string[];
    industryObj:string[];
    industrySegmentObj:string[];
    industrySegment: string[];
    address: {
      address1: string;
      address2: string;
      city: string;
      state: string;
      zip: string;
    };
    country: string;
    website: string;
    address2: string;
    languages: string[];
    relationshipStatus: string;
    hasChildren: boolean;
    hasPets: boolean;
  };
  handleUpdateUser: (data: any) => void;
  handleUpdate: (data: any) => void;
}

export const ProfileEdit: FC<ProfileEditRenderProps> = ({
  path,
  data,
  handleUpdate,
  handleUpdateUser,
}) => {
  const [activeTab, setActiveTab] = useState("/talent/edit/account");
  const [image, setImage] = useState(data?.userObj?.profilePicture?.url);

  const handleTabClick = (tabPath: string) => {
    setActiveTab(tabPath);
  };
  let tabContent: JSX.Element | null = null;
  switch (activeTab) {
    case "/talent/edit/account":
      tabContent = (
        <AccountLoad
          dataUser={data?.userObj}
          handleUpdateUser={handleUpdateUser}
        />
      );
      break;
    case "/talent/edit/profile":
      tabContent = (
        <ProfileFormLoad
          handleUpdate={handleUpdate}
          birth={data?.birthday}
          gender={data?.gender}
          bio={data?.description}
          industry={data?.industryObj}
          industrySegment={data?.industrySegmentObj}
        />
      );
      break;
    case "/talent/edit/contact":
      tabContent = (
        <ContactFormLoad
          handleUpdate={handleUpdate}
          phone={data?.phone}
          address={data?.address}
          website={data?.website}
        />
      );
      break;
    case "/talent/edit/ethnicity":
      tabContent = (
        <EthnicityLoad
          languages={data?.languages || []}
          country={data?.country}
          ethnicity={data?.ethnicity}
          handleUpdate={handleUpdate}
        />
      );
      break;
    case "/talent/edit/family":
      tabContent = (
        <FamilyFormLoad
          relationship={data?.relationshipStatus}
          kids={data?.hasChildren}
          pets={data?.hasPets}
          handleUpdate={handleUpdate}
        />
      );
      break;
    default:
      tabContent = null;
  }

  const handleImageChange = async(event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      await updatePhoto(
        file,
        data?.user
      );
      reader.onloadend = () => {
        setImage(reader.result as string);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Content>
        <LinkNavigation to="/talent">Back</LinkNavigation>
        <div style={{ position: "relative" }}>
          <Image src={image} alt="profile" onClick={handleImageChange} />
          <input
            type="file"
            onChange={handleImageChange}
            style={{ display: "none" }}
            id="image-upload"
          />
          <Label htmlFor="image-upload">
            <CameraIcon />
          </Label>
        </div>
        <TabsWrapper>
          <LinkTab
            $active={activeTab === "/talent/edit/account"}
            onClick={() => handleTabClick("/talent/edit/account")}
          >
            Account
          </LinkTab>
          <LinkTab
            $active={activeTab === "/talent/edit/profile"}
            onClick={() => handleTabClick("/talent/edit/profile")}
          >
            profile
          </LinkTab>
          <LinkTab
            $active={activeTab === "/talent/edit/contact"}
            onClick={() => handleTabClick("/talent/edit/contact")}
          >
            contact
          </LinkTab>
          <LinkTab
            $active={activeTab === "/talent/edit/ethnicity"}
            onClick={() => handleTabClick("/talent/edit/ethnicity")}
          >
            ethnicity
          </LinkTab>
          <LinkTab
            $active={activeTab === "/talent/edit/family"}
            onClick={() => handleTabClick("/talent/edit/family")}
          >
            family
          </LinkTab>
        </TabsWrapper>

        {tabContent}
      </Content>
    </>
  );
};
