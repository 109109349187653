import React, {useEffect, useRef, useState} from 'react';
import html2canvas from 'html2canvas';
import * as d3 from 'd3';
import GraphMarkers from "./components/GraphMarkers";
import {UserTitle, ExpandButton} from "./styles";
import ExpandIcon from "../../assets/Icons/Interface/ExpandIcon";
import {Button} from "../../components/Atoms/Button/Button";


function Graph({k,x,y, nodes, relations, innerHeight, innerWidth}) {

	useEffect(()=>{
		console.log(innerWidth, innerHeight);
		renderGraph();
	},[innerWidth, innerHeight]);

	let colorScale = d3.scaleOrdinal() //=d3.scaleOrdinal(d3.schemeSet2)
		.domain([
			"user",
			"citiesIsland",
			"countriesIsland",
			"ethnicitiesIsland",
			"brandIsland",
			"localesIsland",
			"genderAgeIsland",
			"interestsIsland"
		])
		.range([
			'#FF5656',//Talent
			'#8D6CEC',//Social
			'#FF5656',//Audience
			'#D5AF33',//countriews
			'#1C9882',
			'#DA7298',//states
			'#F2A541',
			'#F08A4B',//cities
			'#F08A4B',
			'#D78A76',//brands
			'#D78A76',
			'#FF5656',//interests
			'#577590',//ethnicity
			'#577590',//age
			'#577590',//gender
			'#FF5656',//metadata
			'#FF5656',//account
			'#FF5656',//twittermeta
		])

	useEffect(() => {
		//const graph = d3.select('.graphContainer');
		renderGraph();

	}, []);

	const renderGraph = () => {
		let width = window.innerWidth - innerHeight;
		let height = window.innerHeight - innerWidth;

		const simulation = d3.forceSimulation(nodes)
			.force("link", d3.forceLink(relations).id((d, index) => d.id))
			//spread the node aprt from eachother so they can be read
			.force("charge", d3.forceManyBody().strength(-200))
			.force("collide",d3.forceCollide().radius((d) => {
				return 30
			}))
			//put the center node in the center
			.force("center", d3.forceCenter((width / 2), height / 2));

		simulation.force("link").distance(linkDistance);

		function linkDistance(d) {
			const w = d?.weight ? d.weight * 50 : 0;
			return 100 //(w * 2) + 80 //d.value * 8
		}

		const svg = d3.select('.graphContainer')
			.attr('id', 'kgraph');

		svg.selectAll("*").remove();


		//add the relations between each node
		const link = svg.append("g")
			//make the relations gray and semi-opaque
			.attr("stroke", "#999")
			.attr("stroke-opacity", 0.7)
			.selectAll("line")
			.data(relations)
			.join("line")
			//set the link length
			.attr("stroke-width", d => 1)
			.attr('marker-end', d => {
				return 'url(#arrowhead)'
			});

		const node = svg.append("g")
			.attr("stroke", "#fff")
			.attr("stroke-width", 1)
			.selectAll("circle")
			.data(nodes)
			.enter()
			.append("g");

		node.append("title")
			.text(d => {
				return `${d.title}-${d.id}`;
			});

		const circle = node
			.append("circle")
			.attr("r", d => {
				let w = 0;
				if (d?.weight) {
					w = 20 * d.weight * 2
				}
				return 20 + w;
			})
			.style("fill", d => colorScale(d.group))//d => colorScale(d.labels[0]))
			.style("opacity", 1)
			.attr("stroke", d => d.group === "user" ? "#333" : "none" )
			.attr("stroke-width", d => d.group === "user" ? "10" : "0" )
		/*.on("mouseover", function (d) {
			d3.select(this)
				.transition()
				.duration(500)
				.ease(d3.easeCubicInOut)
				.attr("r", d => d.weight + 30)
			//.style("fill", "blue");
		}).on("mouseout", function (d) {
			d3.select(this.parentNode)
				.select("circle")
				.transition()
				.duration(500)
				.attr("r", d => d.weight + 20)
			//.style("fill", d => colorScale(d.labels[0]))
		});*/

		const text = node
			.append("foreignObject")
			.attr('font-size', '6px')
			.attr('fill', 'white')
			//.text('Camera & Photography')
			.attr("width", d => {
				let w = 0;
				if (d?.weight) {
					w = 40 * d.weight * 2
				}
				return 40 + w;
			})
			.attr("height", d => {
				let w = 0;
				if (d?.weight) {
					w = 40 * d.weight * 2
				}
				return 40 + w;
			})
			.style('text-align', 'center')
			.html(function(d, i) {
				let w = 0;
				if (d?.weight) {
					w = 40 * d.weight * 2
				}
				//const style =

				return (
					`<div style='display: flex; align-items: center; height: ${40 + w}px; padding: 0 10px 0; justify-content: center'>
							<div>
							${d.weight && d.group !== "user" ? `
								<h2 style='font-size: 10px; margin-bottom: 1px; opacity: ${d.id === "user" ? "0" : "100"}'>${d.weight ? (Number(d.weight) * 100).toFixed(2) : 0}%</h2>
								`
						:
						""
					}
							${d.group === "user" ? `
								<h2 style='font-size: 12px; margin-bottom: 0px; margin-top:0'>
									${d.title}
								</h2>
								`
						:
						`${d.title}`
					}
							</div>
					</div>`
				);
			});

		// const edgepaths = svg.selectAll(".edgepath") //make path go along with the link provide position for link labels
		// 	.data(relations)
		// 	.enter()
		// 	.append('path')
		// 	.attr('class', 'edgepath')
		// 	.attr('fill-opacity', 0)
		// 	.attr('stroke-opacity', 0)
		// 	.attr('id', function (d, i) {return 'edgepath' + i})
		// 	.style("pointer-events", "none");


		simulation.on("tick", () => {
			link
				.attr("x1", d => d.source.x)
				.attr("y1", d => d.source.y)
				.attr("x2", d => d.target.x)
				.attr("y2", d => d.target.y);

			node
				.attr("cx", d => d.x)
				.attr("cy", d => d.y);

			circle
				.attr("cx", d => d.x)
				.attr("cy", d => d.y);

			text
				.attr("x", d => {
					let w = 20;

					if (d?.weight) {
						w = (20 * d.weight * 2) + 20
					}
					return d.x - w;
				})
				.attr("y", d => {
					let w = 20;
					if (d?.weight) {
						w = (20 * d.weight * 2) + 20
					}
					return d.y - w;
				})

			//edgepaths.attr('d', d => 'M ' + d.source.x + ' ' + d.source.y + ' L ' + d.target.x + ' ' + d.target.y);
		});

		const drag = d3
			.drag()
			//.on("start", dragstart)
			.on("drag", dragged)
			.on("end", dragended);

		node.call(drag).on("click", () => null);

		//function click (){}

		function dragged(event, d) {
			d.fx = event.x;
			d.fy = event.y;
			simulation.alpha(1).restart();
		}

		function dragended(event, d) {
			if (!event.active) simulation.alphaTarget(0);
			d.fx = null;
			d.fy = null;
		}
	}

	return (
		<g className="graphContainer" transform={`translate(${x},${y})scale(${k})`}>

		</g>
	)

}

export default function TalentGraphRender({nodes, relations, user}) {

	const [fullScreen, setFullScreen] = useState(false);

	const [k, setK] = useState(1);
	const [x, setX] = useState(0);
	const [y, setY] = useState(0);

	const divRef = useRef(null);

	useEffect(() => {
		draw();
	}, []);

	useEffect(() => {
		draw();
	}, [fullScreen]);

	const draw = () => {
		console.log("Draw", fullScreen);
		let w = window.innerWidth;
		let h = window.innerHeight;
		const size = fullScreen ? h : h-180;
		const width = fullScreen ? w : w-301;
		const d3Graph = d3.select(divRef.current)

		d3Graph.select('svg')
			.attr('width', width)
			.attr('height', size)


		const zoom = d3.zoom()
			.scaleExtent([0.2, 3])
			.on("zoom", (event) => {
				const { x, y, k } = event.transform;
				setK(k);
				setX(x);
				setY(y);
			});
		d3.select(divRef.current).call(zoom);

		if(fullScreen){
			d3.select(divRef.current).call(zoom.scaleBy, 1.2);
		} else { //nodes.length > 20
			d3.select(divRef.current).call(zoom.scaleBy, 0.4);
		}

	}

	const downloadLight = () => {
		var svg = document.getElementById("svg-graph");

//get svg source.
		var serializer = new XMLSerializer();
		var source = serializer.serializeToString(svg);

//add name spaces.
		if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
			source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
		}
		if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
			source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
		}

//add xml declaration
		source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

//convert svg source to URI data scheme.
		var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);

		const element = document.createElement("a");
		element.download = "graph.svg";
		element.href = url;
		element.click();
		element.remove();
	}


	function takeSnapShot() {
		// @ts-ignore
		html2canvas(document.querySelector("#capture"), {scale: 10, useCORS: true}).then(function(canvas9) {
			var theimage9 = canvas9.toDataURL("image/png");
			// @ts-ignore
			const element = document.createElement("a");
			element.download = "graph.png";
			element.href = theimage9;
			element.click();
			element.remove();

		});
	}


	const downloadSVG = () => {
		const svg = document.querySelector('#svg-graph');
		const base64doc = btoa(unescape(encodeURIComponent(svg.outerHTML)));
		const a = document.createElement('a');
		const e = new MouseEvent('click');
		a.download = 'download.svg';
		a.href = 'data:image/svg+xml;base64,' + base64doc;
		a.dispatchEvent(e);
	}

	const downloadSVGAsPNG = (e) => {
		const canvas = document.createElement("canvas");
		const svg = document.querySelector('#svg-graph');
		const base64doc = btoa(unescape(encodeURIComponent(svg.outerHTML)));
		const w = parseInt(svg.getAttribute('width'));
		const h = parseInt(svg.getAttribute('height'));
		const img_to_download = document.createElement('img');

		console.log("WH", w, h);
		console.log("B64", base64doc);
		img_to_download.onload = function () {
			console.log('img loaded');
			canvas.setAttribute('width', w);
			canvas.setAttribute('height', h);
			const context = canvas.getContext("2d");
			//context.clearRect(0, 0, w, h);
			context.drawImage(img_to_download,0,0,w,h);
			const dataURL = canvas.toDataURL('image/png');
			if (window.navigator.msSaveBlob) {
				window.navigator.msSaveBlob(canvas.msToBlob(), "download.png");
				e.preventDefault();
			} else {
				const a = document.createElement('a');
				const my_evt = new MouseEvent('click');
				a.download = 'download.png';
				a.href = dataURL;
				a.dispatchEvent(my_evt);
			}
			//canvas.parentNode.removeChild(canvas);
		}
		img_to_download.src = 'data:image/svg+xml;base64,' + base64doc;
		//var src = document.getElementById("cont");
		//src.appendChild(img_to_download);

		console.log("Loaded end");
	}

	return (
		<div
			className="black"
			style={{
				position: fullScreen ? 'fixed' : 'relative',
				top: fullScreen ? '0px' : 'auto',
				left: fullScreen ? '0px' : 'auto',
				bottom: fullScreen ? '0px' : 'auto',
				right: fullScreen ? '0px' : 'auto',
				zIndex: fullScreen ? '10000' : 'auto',
				background:"black"
			}}
			id="cont"
		>
			{user &&
				<UserTitle>
					<img src={user.profilePicture?.url} />
					<h1>{user.firstName} {user.lastName}</h1>
				</UserTitle>
			}
			<div id="capture" className="d3Data" ref={divRef} style={{backgroundColor:'#000'}}>
				<svg id="svg-graph" style={{border : "1px solid #444", borderRadius: "5px"}}>
					<Graph x={x} y={y} k={k} nodes={nodes} relations={relations} innerHeight={fullScreen ? 0 : 80} innerWidth={fullScreen ? 0 : 301} />
				</svg>
			</div>
			<GraphMarkers />
			<ExpandButton onClick={()=>setFullScreen(!fullScreen)}>
				<ExpandIcon width={20} height={20} />
			</ExpandButton>
			{/*<button onClick={downloadSVG}>Download</button>*/}
			{/*<button onClick={downloadSVGAsPNG}>Download as PNG</button>*/}
			<div style={{
				position:'absolute',
				bottom: '20px',
				right: '10px',
				display: 'flex',

			}}>
				<div style={{marginRight: "8px"}}>
					<Button onClick={takeSnapShot} width='auto' label='Download as PNG' className="mr" />
				</div>
				<Button onClick={downloadLight} background='teal' width='auto' label='Download as SVG' />
			</div>

		</div>
	);
}

