import { useForm } from "hooks/useForm/useForm";
import { Tenant } from "./Tenant";
import { LinkContainer } from "./styles";
import { QueryResult, useMutation, useQuery } from "@apollo/client";
import { query } from "services/queries/queries";
import { FormField, FormHook } from "hooks/useForm/interfaces";
import { CREATE_TENANT, EDIT_TENANT } from "services/queries/mutations";
import { useToast } from "context/toastContext/toastContext";
import { useState } from "react";
import { Popup } from "components/Atoms/Popup/Popup";
import { DivLink } from "components/Atoms/TableRow/styles";
import Divider from "components/Atoms/Divider/Divider";
import { EllipsisSvg } from "assets";

export const TenantLoad = () => {
  const { error, success } = useToast();
  const [tenantCreateOne] = useMutation(CREATE_TENANT);
  const [tenantUpdateMany] = useMutation(EDIT_TENANT);

  const [showPopupIndex, setShowPopupIndex] = useState(-1);
  const [editingTenant, setEditingTenant] = useState<any>(null);
  const [showModalCreate, setShowModalCreate] = useState(false);

  const handleInfoUpdate = (data: any) => {
    setEditingTenant(data);
    setShowModalCreate(true);
  };

  const columns = [
    { header: "Label", accessor: "label" },
    { header: "Identifier", accessor: "identifier" },
    {
      header: "Action",
      accessor: "action",
      Cell: (row: any) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <LinkContainer to={`/tenant/detail/${row.id}`}>Details</LinkContainer>
          <div style={{ position: "relative" }}>
            <EllipsisSvg
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowPopupIndex(row.id);
              }}
            />
            {showPopupIndex === row.id && (
              <Popup onClose={() => setShowPopupIndex(-1)}>
                <DivLink onClick={() => handleInfoUpdate(row)}>
                  <p>Edit Tenant</p>
                </DivLink>
                <Divider />
              </Popup>
            )}
          </div>
        </div>
      ),
    },
  ];

  const {
    data: tenantsData,
    loading: userTypeLoading,
    error: userTypeError,
    refetch,
  }: QueryResult<
    any,
    { skip: number; limit: number; search?: any; filter: any }
  > = useQuery(query("tenantFindMany"));

  const formFields: FormField[] = [
    {
      name: "label",
      required: true,
      default: editingTenant ? editingTenant?.label : "",
    },
    {
      name: "identifier",
      required: true,
      default: editingTenant ? editingTenant?.identifier : "",
    },
  ];

  const actionForm = async (data: any): Promise<void> => {
    if (showPopupIndex !== -1) {
      await editTenantAction(data);
    } else {
      await registerTenantAction(data);
    }

    resetForm();
    setEditingTenant(null);
  };

  const editTenantAction = async (data: any): Promise<void> => {
    try {
      await tenantUpdateMany({
        variables: {
          record: {
            identifier: data.identifier,
            label: data.label,
          },
          filter: {
            _id: showPopupIndex,
          },
        },
      });
      success("Create Tenant successfully!");
      refetch();
      setShowModalCreate(false);
    } catch (err) {
      console.log(err);
      error("An error occurred with create!");
    }
  };

  const registerTenantAction = async (data: any): Promise<void> => {
    try {
      await tenantCreateOne({
        variables: {
          record: {
            identifier: data.identifier,
            label: data.label,
          },
        },
      });
      success("Create Tenant successfully!");
      refetch();
      setShowModalCreate(false);
    } catch (err) {
      console.log(err);
      error("An error occurred with create!");
    }
  };

  const {
    data: formData,
    errors,
    loading,
    resetForm,
    ...rest
  }: FormHook = useForm(formFields, actionForm);

  if (userTypeLoading) {
    return <div>Loading...</div>;
  }

  if (userTypeError) {
    return <div>Error!</div>;
  }

  const data = tenantsData.tenantFindMany.map((item: any) => ({
    ...item,
    id: item._id,
  }));

  return (
    <Tenant
      data={data}
      columns={columns}
      formData={data}
      submitLoading={loading}
      errors={errors}
      registerInput={rest.registerInput}
      onSubmit={rest.handleSubmitForm}
      showModalCreate={showModalCreate}
      setShowModalCreate={setShowModalCreate}
      editingTenant={editingTenant}
      setEditingTenant={setEditingTenant}
    />
  );
};
